import styled from 'styled-components';
import { EntityTable } from 'components/organisms';
import { toast } from 'react-toastify';

import Section from './Section';

const TableEntry = styled.div`
  margin-top: 40px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

function RowHandler() {
  const cols = ['id', 'name', 'created'];
  const data = [
    { id: 1, name: 'one', created: '2021-01-01' },
    { id: 2, name: 'two', created: '2021-01-02' },
    { id: 3, name: 'three', created: '2021-01-03' },
    { id: 4, name: 'four', created: '2021-01-04' },
    { id: 5, name: 'five', created: '2021-01-05' },
  ];
  const rowClickHandler = (row) => {
    toast.success(`Edit ${JSON.stringify(row)}`);
  };
  return (
    <TableEntry>
      <h3>With Row Click</h3>
      <EntityTable columns={cols} data={data} rowClickHandler={rowClickHandler} />
    </TableEntry>
  );
}

function NewHandler() {
  const cols = ['id', 'name', 'created'];
  const data = [
    { id: 1, name: 'one', created: '2021-01-01' },
    { id: 2, name: 'two', created: '2021-01-02' },
    { id: 3, name: 'three', created: '2021-01-03' },
    { id: 4, name: 'four', created: '2021-01-04' },
    { id: 5, name: 'five', created: '2021-01-05' },
  ];
  const newHandler = () => {
    toast.success('New Clicked');
  };
  return (
    <TableEntry>
      <h3>With New</h3>
      <EntityTable columns={cols} data={data} newHandler={newHandler} />
    </TableEntry>
  );
}

function EditHandler() {
  const cols = ['id', 'name', 'created'];
  const data = [
    { id: 1, name: 'one', created: '2021-01-01' },
    { id: 2, name: 'two', created: '2021-01-02' },
    { id: 3, name: 'three', created: '2021-01-03' },
    { id: 4, name: 'four', created: '2021-01-04' },
    { id: 5, name: 'five', created: '2021-01-05' },
  ];
  const editHandler = (row) => {
    toast.success(`Edit ${JSON.stringify(row)}`);
  };
  return (
    <TableEntry>
      <h3>With Edit</h3>
      <EntityTable columns={cols} data={data} editHandler={editHandler} />
    </TableEntry>
  );
}

function ErrorState() {
  const cols = ['id', 'name', 'created'];
  const data = [
    { id: 1, name: 'one', created: '2021-01-01' },
    { id: 2, name: 'two', created: '2021-01-02' },
    { id: 3, name: 'three', created: '2021-01-03' },
    { id: 4, name: 'four', created: '2021-01-04' },
    { id: 5, name: 'five', created: '2021-01-05' },
  ];
  const error = 'Error message';
  return (
    <TableEntry>
      <h3>Error State</h3>
      <EntityTable columns={cols} data={data} error={error} />
    </TableEntry>
  );
}

function Loading() {
  const cols = ['id', 'name', 'created'];
  const data = [
    { id: 1, name: 'one', created: '2021-01-01' },
    { id: 2, name: 'two', created: '2021-01-02' },
    { id: 3, name: 'three', created: '2021-01-03' },
    { id: 4, name: 'four', created: '2021-01-04' },
    { id: 5, name: 'five', created: '2021-01-05' },
  ];
  const loading = true;
  return (
    <TableEntry>
      <h3>Loading</h3>
      <EntityTable columns={cols} data={data} loading={loading} />
    </TableEntry>
  );
}

function ColumnsAndData() {
  const cols = ['id', 'name', 'created'];
  const data = [
    { id: 1, name: 'one', created: '2021-01-01' },
    { id: 2, name: 'two', created: '2021-01-02' },
    { id: 3, name: 'three', created: '2021-01-03' },
    { id: 4, name: 'four', created: '2021-01-04' },
    { id: 5, name: 'five', created: '2021-01-05' },
  ];
  return (
    <TableEntry>
      <h3>Columns and Data</h3>
      <EntityTable columns={cols} data={data} />
    </TableEntry>
  );
}

function ColumnsOnly() {
  const cols = ['id', 'name', 'created'];
  const data = [];
  return (
    <TableEntry>
      <h3>Columns Only</h3>
      <EntityTable columns={cols} data={data} />
    </TableEntry>
  );
}

function NoProps() {
  return (
    <TableEntry>
      <h3>No Props</h3>
      <EntityTable />
    </TableEntry>
  );
}

export default function TableSection() {
  return (
    <Section title="Table">
      <div>
        <NewHandler />
        <EditHandler />
        <RowHandler />
        <ColumnsOnly />
        <ColumnsAndData />
        <NoProps />
        <Loading />
        <ErrorState />
      </div>
    </Section>
  );
}
