import { TextField } from 'components/atoms';
import Section from './Section';

export default function TextFieldSection() {
  return (
    <Section title="TextField">
      <div>
        <TextField />
        <TextField label="with label" />
        <TextField label="width width/2" width={window.innerWidth / 2} />
      </div>
    </Section>
  );
}
