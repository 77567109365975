import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { Root, Content } from 'components/templates';
import { getTeams } from 'api/team';
import useApi from 'api/useApi';
import { EntityTable } from 'components/organisms';

export default function TeamIndex() {
  const navigate = useNavigate();

  // Fetch Teams
  const {
    loading, error, data, request,
  } = useApi(getTeams);

  useEffect(() => {
    try {
      request();
    } catch (err) {
      toast.error(err);
    }
  }, [request]);

  const cols = ['name', 'created_at'];
  const newHandler = () => {
    navigate('/teams/new');
  };
  const editHandler = (d) => {
    navigate(`/teams/${d.team_id}`);
  };

  return (
    <Root>
      <Content>
        <h1>Team Index</h1>
        <EntityTable
          data={data}
          loading={loading}
          error={error}
          columns={cols}
          newHandler={newHandler}
          editHandler={editHandler}
        />
      </Content>
    </Root>
  );
}
