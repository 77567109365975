import { useSearchParams, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getInProgress } from 'api/build';
import { Root } from 'components/templates';

import useApi from 'api/useApi';

import MapScene from './MapScene';

export default function BuildMap() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const buildID = searchParams.get('bid');

  const {
    data, error, request, loading,
  } = useApi(getInProgress);

  useEffect(() => {
    if (!buildID) {
      toast.error('No build ID provided, sent home.');
      navigate('/play/build');
    }
    request();
  }, [buildID]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  if (!data) {
    return (
      <p>Loading...</p>
    );
  }

  if (loading) {
    return (
      <p>Loading...</p>
    );
  }

  return (
    <Root>
      <MapScene graph={data.graph} />
    </Root>
  );
}
