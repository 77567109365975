import { MageOption } from 'components/molecules';
import { Mage } from 'components/organisms';
import MageData from 'MageData';
import Section from './Section';

function BattlefieldMageSection({ mageData }) {
  return (
    <div>
      <h3>Mage</h3>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Mage mageData={mageData} right />
          <Mage mageData={mageData} size="sm" right />
        </div>
        <Mage mageData={mageData} size="sm" />
        <Mage mageData={mageData} />
      </div>
    </div>
  );
}

function MageOptionSection({ mageData }) {
  return (
    <div>
      <h3>Mage Option</h3>
      <div>
        <MageOption mageData={mageData} />
      </div>
    </div>
  );
}

export default function MageSection() {
  const rawData = {
    name: 'Mage',
    hp: 20,
    max_hp: 20,
    regen_hp: 0,
    mp: 10,
    max_mp: 10,
    deck: {
      deck: [],
      hand: [],
      discard_pile: [],
    },
    conditions: [{ tiny_id: '0', name: 'Thorns' }],
    owner: 'owner',
    color: 'RED',
    front_img: 'red1front',
    back_img: 'red1back',
  };
  const mageData = new MageData({ ...rawData });

  return (
    <Section title="Mage">
      <BattlefieldMageSection mageData={mageData} />
      <MageOptionSection mageData={mageData} />
    </Section>
  );
}
