import { toast } from 'react-toastify';

import { routeConf } from 'Config';

class Socket {
  constructor(messageCallback) {
    this.messageCallback = messageCallback;
    this.messageCallbacks = [];
    this.eventList = [];
  }

  connect(joinKey, isCPUGame, player, teamId, buildId) {
    let params = `cpu=${isCPUGame}`;
    if (teamId) {
      params += `&tid=${teamId}`;
    }
    if (buildId) {
      params += `&bid=${buildId}`;
    }

    // TODO: in order to prevent websocket hijacking we should have
    // a nonce past prior to websocket connect associated with the session
    // document.cookie = `MG-WS-Connect=${authToken}; path=/`;

    this.ws = new WebSocket(`${routeConf.wsPath}?${params}`);

    const onOpen = (event) => {
      console.debug('Open', event);

      // If we don't have a joinKey create a new game
      if (!joinKey) {
        this.sendMsg('new', 'Init');
        return;
      }

      if (player) {
        // If we've connected before reconnect
        const pkey = localStorage.getItem(`${joinKey}.${player}`);
        if (pkey) {
          this.sendObj({
            type: 'Reconnect',
            game_key: joinKey,
            player_key: pkey,
            player,
          });
          return;
        }
      }

      // If we have a joinKey try to connect
      this.sendMsg(joinKey, 'Join', 'key');
    };

    const onMessage = (event) => {
      console.debug(`Received ${event.data}`);
      this.eventList.push(event);
      this.messageCallback(event);
      this.messageCallbacks.forEach((cb) => cb(event));
    };

    this.ws.addEventListener('open', onOpen);
    this.ws.addEventListener('message', onMessage);
  }

  addMessageCallback(cb) {
    this.messageCallbacks.push(cb);
  }

  sendObj(obj) {
    if (this.ws.readyState !== 1) {
      toast.error('Cannot send on a closed connection');
      return;
    }
    const msg = JSON.stringify(obj);
    this.ws.send(msg);
    console.debug(`Sent ${msg}`);
  }

  sendMsg(msg, type = 'msg', key = 'msg') {
    if (this.ws.readyState !== 1) {
      toast.error('Cannot send on a closed connection');
      return;
    }
    const m = { type };
    m[key] = msg;
    const mesg = JSON.stringify(m);
    this.ws.send(mesg);
    console.debug(`Sent ${mesg}`);
  }
}

export default Socket;
