import { MageHeader } from 'components/molecules';
import styled from 'styled-components';
import Section from './Section';

const ExampleContainer = styled.div`
  max-width: 20em;
  padding: 1em;
`;

const Container = styled.div`
  display: flex;
`;

function Example({
  name, color, right, size,
}) {
  const r = right || '0';
  const s = size || '0';
  const context = `color: ${color}, right: ${r}, size: ${s}, len: ${name.length}`;
  return (
    <ExampleContainer>
      {context}
      <MageHeader name={name} color={color} right={right} />
    </ExampleContainer>
  );
}

export default function MageHeaderSection() {
  const name1 = 'Albus Dimbledrumb';
  const name20plus = 'Fizzlewinks Bimblebinks the Third';
  return (
    <Section title="MageHeader">
      <Container>
        <div>
          Base
          <Example name={name1} color="blue" right size="md" />
          <Example name={name1} color="blue" size="md" />
          <Example name={name1} color="blue" />
          <Example name={name1} color="blue" right />
        </div>
        <div>
          Long Name
          <Example name={name20plus} color="red" right />
          <Example name={name20plus} color="red" />
          <Example name={name20plus} color="red" size="md" />
        </div>
        <div>
          All Colors
          <Example name={name1} color="red" />
          <Example name={name1} color="orange" />
          <Example name={name1} color="yellow" />
          <Example name={name1} color="green" />
          <Example name={name1} color="blue" />
          <Example name={name1} color="violet" />
        </div>
      </Container>
    </Section>
  );
}
