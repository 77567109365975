const repulsionStrength = 0.30;
const attractionStrength = 0.20;
const iterationsTillSettle = 50;
const maxForce = 20;

function clampVector(x, y, z, maxMagnitude) {
  const magnitude = Math.sqrt(x * x + y * y + z * z);
  if (magnitude > maxMagnitude) {
    const scale = maxMagnitude / magnitude;
    return { x: x * scale, y: y * scale, z: z * scale };
  }
  return { x, y, z };
}

function repulseNodes(nodes) {
  nodes.forEach((node, i) => {
    node.force = { x: 0, y: 0, z: 0 }; // Initialize force accumulator

    nodes.forEach((otherNode, j) => {
      if (i !== j) {
        const dx = node.position.x - otherNode.position.x;
        const dz = node.position.z - otherNode.position.z;
        const distance = Math.sqrt(dx * dx + dz * dz) || 1;
        const forceMagnitude = repulsionStrength / (distance * distance);

        const force = clampVector((dx / distance) * forceMagnitude, 0, (dz / distance) * forceMagnitude, maxForce);

        node.force.x += force.x;
        node.force.z += force.z;
      }
    });
  });
}

function attractNodes(nodes, edges) {
  edges.forEach(({ sourceId, targetId }) => {
    const source = nodes.find((node) => node.nodeId === sourceId);
    const target = nodes.find((node) => node.nodeId === targetId);

    if (source && target) {
      const dx = target.position.x - source.position.x;
      const dz = target.position.z - source.position.z;
      const distance = Math.sqrt(dx * dx + dz * dz) || 1;
      const forceMagnitude = distance * attractionStrength;

      const force = clampVector((dx / distance) * forceMagnitude, 0, (dz / distance) * forceMagnitude, maxForce);

      source.force.x += force.x;
      source.force.z += force.z;

      target.force.x -= force.x;
      target.force.z -= force.z;
    }
  });
}

function updateNodePositions(nodes, edges) {
  nodes.forEach((node) => {
    // Skip "BOSS" and "HOME" nodes from being moved
    if (node.category !== 'BOSS' && node.category !== 'HOME') {
      repulseNodes(nodes);
      // edges are stored in {sourceId, targetId} format
      attractNodes(nodes, edges);

      // Apply forces to node positions
      node.position.x += node.force.x;
      node.position.z += node.force.z * 0.5;
    }
  });
}

export { updateNodePositions, iterationsTillSettle };
