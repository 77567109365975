import { Button, TextField } from 'components/atoms';
import { Bar } from 'components/molecules';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import Section from './Section';

function HPBarSection() {
  return (
    <div>
      <h3>HP Bar</h3>
      <div>
        <Bar initVal={100} max={100} regen={0} variant="hp" />
        <Bar initVal={50} max={100} regen={0} variant="hp" />
        <Bar initVal={50} max={100} regen={25} variant="hp" />
        <Bar initVal={50} max={100} regen={10} variant="hp" />
        <Bar initVal={120} max={100} regen={0} variant="hp" />
        <Bar initVal={200} max={100} regen={0} variant="hp" />
        <Bar initVal={200} max={100} regen={0} variant="hp" size="sm" />
      </div>
    </div>
  );
}

function MPBarSection() {
  return (
    <div>
      <h3>MP Bar</h3>
      <Bar initVal={100} max={100} regen={0} variant="mp" />
      <Bar initVal={50} max={100} regen={0} variant="mp" />
      <Bar initVal={50} max={100} regen={25} variant="mp" />
      <Bar initVal={120} max={100} regen={0} variant="mp" />
      <Bar initVal={200} max={100} regen={0} variant="mp" />
    </div>
  );
}

const DamageInput = styled.div`
  padding-top: 3em;
  width: 90%;
  display: flex;
`;

export default function BarSection() {
  const healthBarRef = useRef();
  const [dmg, setDmg] = useState(0);
  const [initHealth, setInitHealth] = useState(100);

  const simulateDamage = () => {
    const val = parseInt((Math.random() - 0.5) * 100, 10);
    const v = dmg || val;
    healthBarRef.current.addValue(v);
  };

  const handleDmgChange = (e) => {
    setDmg(Number(e.value));
  };

  const updateInitHealth = () => {
    const val = parseInt((Math.random() * 100) + 50, 10);
    setInitHealth(val);
  };

  return (
    <Section title="Bar">
      <div>
        <div style={{ width: '200px', marginLeft: '200px' }}>
          <Bar initVal={initHealth} max={initHealth} regen={0} ref={healthBarRef} splash="right" />
        </div>
        <DamageInput>
          <TextField label="Damage" type="number" onChange={handleDmgChange} />
          <Button onClick={simulateDamage} variant="dangerous">Take Damage</Button>
        </DamageInput>
        <div>
          <Button onClick={updateInitHealth}>Update Initial Health</Button>
        </div>
      </div>

      <div>
        <HPBarSection />
        <MPBarSection />
      </div>
    </Section>
  );
}
