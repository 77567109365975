import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Root, Content } from 'components/templates';
import { getTeam, updateTeam } from 'api/team';
import useApi from 'api/useApi';

import TeamEditor from './TeamEditor';

export default function TeamEdit() {
  const [team, setTeam] = useState(null);
  const { id } = useParams();

  const {
    data: teamData, request: getTeamRequest,
  } = useApi(getTeam);

  useEffect(() => {
    getTeamRequest(id);
  }, [id]);

  useEffect(() => {
    if (teamData) {
      setTeam(teamData);
    }
  }, [teamData]);

  const {
    loading: updateTeamLoading, data: updatedTeamData, request: updateTeamRequest, error: updateTeamError,
  } = useApi(updateTeam);

  useEffect(() => {
    if (updateTeamError) {
      toast.error(updateTeamError.message);
    }
  }, [updateTeamError]);

  useEffect(() => {
    if (updatedTeamData) {
      toast.success('Team updated!');
      setTeam(updatedTeamData);
    }
  }, [updatedTeamData]);

  const handleSaveTeam = (updatedTeam) => {
    // Make a PUT request to the server to update the team
    updateTeamRequest(updatedTeam);
  };

  return (
    <Root>
      <Content>
        { !team && <div>Loading team...</div> }
        { team
          && <TeamEditor team={team} onSave={handleSaveTeam} loading={updateTeamLoading} />}
      </Content>
    </Root>
  );
}
