import { Button } from 'components/atoms';
import useApi from 'api/useApi';
import { updateBuildGame } from 'api/build';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function AbandonButton({ graph }) {
  const navigate = useNavigate();

  const {
    data, request, error,
  } = useApi(updateBuildGame);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success('Build abandoned');
      navigate('/');
    }
  }, [data]);

  const clickAbandon = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const build_id = urlParams.get('bid');
    const user_internal_id = JSON.parse(localStorage.getItem('user')).internal_id;
    const buildReq = {
      build_id,
      user_internal_id,
      is_complete: true,
      graph,
    };
    request(buildReq);
  };

  return (
    <Button size="sm" square variant="dangerous" onClick={clickAbandon}>Abandon</Button>
  );
}
