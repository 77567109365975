import { useRef, useContext } from 'react';
import './ActionModal.css';
import { Button, Modal } from 'components/atoms';

import styled, { ThemeContext } from 'styled-components';

const StyledActionModalElement = styled.div`
  border-radius: 10px;
`;

function ActionModal({ modalIsOpen, actionCallback, validActions }) {
  const { backgroundColor } = useContext(ThemeContext);

  const modal = useRef(null);

  const clickPlay = () => {
    actionCallback('play');
  };

  const clickPass = () => {
    actionCallback('pass');
  };

  const clickChange = () => {
    actionCallback('change');
  };

  const clickConcede = () => {
    actionCallback('concede');
  };

  return (
    <div className="ActionModal__container">
      <Modal
        open={modalIsOpen}
        minimizable
      >
        <StyledActionModalElement ref={modal} style={{ backgroundColor }}>
          <Button onClick={clickPlay} disabled={!validActions.includes('play')}>Play</Button>
          <Button onClick={clickPass} disabled={!validActions.includes('pass')}>Taunt</Button>
          <Button onClick={clickChange} disabled={!validActions.includes('change')}>Change</Button>
          <Button onClick={clickConcede} variant="dangerous" disabled={!validActions.includes('concede')}>
            Concede
          </Button>
        </StyledActionModalElement>
      </Modal>
    </div>
  );
}

export default ActionModal;
