import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Button, TextField } from 'components/atoms';
import useApi from 'api/useApi';
import { postLogin, postGuestLogin } from 'api/user';

import './Login.css';

export default function Login() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {
    data: postLoginData, error: postLoginError, loading: postLoginLoading, request: postLoginRequest,
  } = useApi(postLogin);

  useEffect(() => {
    if (postLoginError) {
      toast.error(postLoginError.message);
    }
  }, [postLoginError]);

  useEffect(() => {
    if (postLoginData) {
      localStorage.setItem('user', JSON.stringify(postLoginData));
      navigate('/menu');
    }
  }, [postLoginData]);

  const handleLogin = (event, e, p) => {
    event.preventDefault();
    if (postLoginLoading) {
      toast.error('Please wait while server loads');
    } else {
      postLoginRequest({ email: e, password: p });
    }
  };

  const {
    data: postGuestLoginData,
    error: postGuestLoginError,
    loading: postGuestLoginLoading,
    request: postGuestLoginRequest,
  } = useApi(postGuestLogin);

  useEffect(() => {
    if (postGuestLoginError) {
      toast.error(postGuestLoginError);
    }
  }, [postGuestLoginError]);

  useEffect(() => {
    if (postGuestLoginData) {
      localStorage.setItem('user', JSON.stringify(postGuestLoginData));
      navigate('/menu');
    }
  }, [postGuestLoginData]);

  const guestLogin = (event) => {
    event.preventDefault();
    if (postGuestLoginLoading) {
      toast.error('Please wait until guest login loading completes');
    } else {
      postGuestLoginRequest();
    }
  };

  return (
    <div className="Login">
      <form className="Login__form" onSubmit={(e) => { handleLogin(e); }}>
        <div className="Login__input">
          <TextField
            name="email"
            id="email"
            label="email"
            value={email}
            onChange={(e) => {
              setEmail(e.value);
            }}
            rules={[
              (v) => v !== '' || 'Cant be empty',
              (v) => v.trim() !== '' || 'Cant be only white space',
            ]}
            width={265}
          />
          <TextField
            rules={[
              (v) => v !== '' || 'Cant be empty',
              (v) => v.trim() !== '' || 'Cant be only white space',
              (v) => v.length >= 8 || 'Must be longer than 8',
            ]}
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => {
              setPassword(e.value);
            }}
            label="password"
            width={265}
          />
        </div>
        <div style={{ width: '266px' }}>
          <Button
            disabled={!!postLoginLoading}
            variant="primary"
            size="lg"
            block
            onClick={(event) => handleLogin(event, email, password)}
          >
            {postLoginLoading ? 'loading' : 'login'}
          </Button>
        </div>
      </form>

      <div className="Login__extra">
        <Button linkTo="/signup">signup</Button>
        <Button
          disabled={!!postGuestLoginLoading}
          onClick={guestLogin}
          variant="secondary"
          block
        >
          {postGuestLoginLoading ? 'loading' : 'login as guest'}
        </Button>
      </div>
    </div>
  );
}
