import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Header } from 'components/organisms';
import { ThemeProvider } from 'styled-components';

import 'font-awesome/css/font-awesome.min.css';
import 'ui-neumorphism/dist/index.css';

import './Root.css';

const theme = {
  primaryColor: '#eee',
  secondaryColor: '#dedede',
  dangerColor: '#ab0f1f',
  blueText: 'blue',
  buttonText: '#1a1a1a',
  backgroundColor: 'rgb(238,238,238)',
  fontFamily: 'Josefin Sans',

  cardColors: {
    blue: 'rgba(0, 34, 255, 0.6)',
    green: 'rgba(38, 194, 129, 0.7)',
    yellow: 'rgba(255, 252, 127, 0.8)',
    orange: 'rgba(230, 126, 34, 0.8)',
    red: 'rgba(255, 0, 0, 0.7)',
    violet: 'rgba(165, 55, 253, 0.8)',
    default: 'rgba(210, 215, 211, 0.8)',
  },

  statColors: {
    mpText: '#3498db',
    minusHpText: '#e74c3c',
    plusHpText: '#2ecc71',
  },
};

export default function Root({ children, joinKey, teamJoin }) {
  return (
    <div className="Root">
      <ThemeProvider theme={theme}>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Header joinKey={joinKey} teamJoin={teamJoin}>
          {children}
        </Header>
      </ThemeProvider>
    </div>
  );
}
