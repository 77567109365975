import styled, { ThemeContext } from 'styled-components';
import { Card as NeuCard } from 'ui-neumorphism';
import { useContext, useRef } from 'react';
import { ImageCacheContext } from 'contexts/ImageCache';

const StyledCardDesc = styled.div`
  margin: 5px;
  margin-bottom: 10px;
  padding: 2px;
  font-size: 10px;
  @media screen and (max-width: 844px) {
    font-size: 8px;
  }
`;

function Description({ description }) {
  return (
    <StyledCardDesc>
      {description}
    </StyledCardDesc>
  );
}

const StyledCardImage = styled.div`
  background-size: cover;
  overflow: hidden;
  height: 88px;
  @media screen and (max-width: 844px) {
    height: 60px;
  }
`;

function Image({ img }) {
  const key = img.toLowerCase();
  const { [key]: image } = useContext(ImageCacheContext);
  if (!image) {
    return <div>Loading</div>;
  }
  return (
    <StyledCardImage style={{ backgroundImage: `url(${image.src})` }} />
  );
}

const MpSpan = styled.span`
  float: right;
  font-size: 8px;
`;

const NameSpan = styled.span`
  font-weight: bold;
`;

const StyledHeader = styled.div`
  font-size: 10px;
  height: 24px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  @media screen and (max-width: 844px) {
    font-size: 8px;
    height: 20px;
  }
`;

function Header({ name, cost, color }) {
  const mpStr = `mp:${cost}`;
  const { cardColors } = useContext(ThemeContext);
  const backgroundColor = cardColors[color.toLowerCase()] || cardColors.default;

  let padding = '8px';
  if (name.length > 20) {
    padding = '4px';
  }

  return (
    <StyledHeader style={{ padding, backgroundColor }}>
      <NameSpan>{name}</NameSpan>
      <MpSpan>{mpStr}</MpSpan>
    </StyledHeader>
  );
}

const CardDiv = styled.div`
  height: 200px;
  width: 142px;
  margin: 5px;
  background-size: contain;
  font-family: ${(props) => props.theme.fontFamily};
  text-align: left;
  opacity: 100%;

  @media screen and (max-width: 844px) {
    height: 145px;
    width: 107px;
  }
`;

const StyledNeuCard = styled(NeuCard)`
  height: 200px;
  width: 142px;

  @media screen and (max-width: 844px) {
    height: 145px;
    width: 107px;
  }
`;

export default function Card({ card, onClick }) {
  const ref = useRef(null);

  const { id } = card;
  const { name } = card;
  const { mpCost } = card;
  const { description } = card;
  const { color } = card;

  const { backgroundColor } = useContext(ThemeContext);

  const cursor = onClick ? 'pointer' : 'default';

  // Card2__hidden-id is used to get the card id when clicking on the card
  // This is a hacky way to do it, but it works
  return (
    <CardDiv key={`card-${id}`} ref={ref} onClick={onClick} style={{ cursor }}>
      <div className="Card2__hidden-id" style={{ display: 'none' }}>
        {card.id}
      </div>
      <StyledNeuCard style={{ backgroundColor }}>
        <Header name={name} cost={mpCost} color={color} />
        <Image img={name} />
        <Description description={description} />
      </StyledNeuCard>
    </CardDiv>
  );
}
