import { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Root } from 'components/templates';
import { routeConf } from 'Config';
import { serializeAllToCardData } from 'CardData';
import CardIndex from './CardIndex';

export default function FetchedCardIndex() {
  const [cards, setCards] = useState([]);
  useEffect(() => {
    (async function fetchCards() {
      try {
        const response = await (await fetch(routeConf.cardPath)).json();
        const c = serializeAllToCardData(response.data);
        setCards(c);
      } catch (e) {
        toast.error(e);
      }
    }());
  }, []);

  return (
    <Root>
      <CardIndex cards={cards} />
    </Root>
  );
}
