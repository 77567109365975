import { Titled, Root } from 'components/templates';
import { HomeNav } from 'components/organisms';

export default function Menu() {
  return (
    <Root>
      <Titled>
        <HomeNav />
      </Titled>
    </Root>
  );
}
