import { Tooltip as ReactTooltip } from 'react-tooltip';
import styled from 'styled-components';

const TooltipContainer = styled.div`
  max-width: 200px;
`;

export default function Tooltip({
  id, text, children, place = 'top',
}) {
  const tooltipId = `tooltip-${id}`;
  return (
    <div>
      <a data-tooltip-id={tooltipId}>{children}</a>
      <ReactTooltip
        id={tooltipId}
        place={place}
        style={{ zIndex: 100 }}
      >
        <TooltipContainer>
          {text}
        </TooltipContainer>
      </ReactTooltip>
    </div>
  );
}
