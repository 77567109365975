export default class MapNode {
  constructor(nodeId, category, depth) {
    this.nodeId = nodeId;
    this.category = category;
    this.depth = depth;
    this.position = { x: 0, y: 0, z: 0 };
    this.force = { x: 0, y: 0, z: 0 };
    this.color = 0x0000ff;
    this.textColor = 'rgba(255, 255, 255, 1)';
    this.isCurrent = false;
    this.isSelected = false;
  }

  setPosition(x, y, z) {
    this.position = { x, y, z };
  }
}
