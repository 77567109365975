import styled from 'styled-components';

const StyledNavList = styled.div`
  text-align: left;
  font-size: 40px;
  a {
    text-decoration: none;
  }
  &:hover {
    color: #000;
    transition: color 0.15s ease-in-out;
  }
`;

export default function NavList({ children }) {
  return (
    <StyledNavList>
      <nav>
        {children}
      </nav>
    </StyledNavList>
  );
}
