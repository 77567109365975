import { useSearchParams } from 'react-router-dom';
import React, { useState } from 'react';

import { EventSender } from 'services';
import { TargetOptionModal, ChooseMageModal } from 'components/organisms';
import { AnimationProvider } from 'contexts/AnimationContext';
import Battlefield from './Battlefield';
import ActionModal from './ActionModal';
import DescriptorsModal from './DescriptorsModal';
import GameOverModal from './GameOverModal';

import { getCardListFromPlayer } from '../../CardData';

import './Game.css';

function Game({ socket }) {
  const [searchParams] = useSearchParams();

  // used as a container for the response to requestBattleLogs
  const [battleLogs, setBattleLogs] = useState([]);

  const [addedCallback, setAddedCallback] = useState(false);

  const [actionModalIsOpen, setActionModalIsOpen] = useState(false);

  const [optionModalIsOpen, setOptionlModalIsOpen] = useState(false);
  const [optionModalOptions, setOptionModalOptions] = useState(null);

  const [targetOptionIsOpen, setTargetOptionIsOpen] = useState(false);
  const [targetOptions, setTargetOptions] = useState(null);

  const [gameOverEvent, setGameOverEvent] = useState(null);
  const [gameOverModalIsOpen, setGameOverModalIsOpen] = useState(false);

  const openTargetOptionModal = () => {
    setTargetOptionIsOpen(true);
  };

  const closeTargetOptionModal = () => {
    setTargetOptionIsOpen(false);
  };

  const sender = EventSender(socket);

  const [descriptors, setDescriptors] = useState(null);
  const [descriptorsIsOpen, setDescriptorsIsOpen] = useState(false);
  const [descriptorCallback, setDescriptorCallback] = useState(null);
  const openDescriptorsModal = () => {
    setDescriptorsIsOpen(true);
  };
  const closeDescriptorsModal = (phase) => {
    setDescriptors([]);
    setDescriptorsIsOpen(false);
    sender.ackPhaseChange(phase);
  };
  const closeEndDescriptorsModal = () => {
    setDescriptors([]);
    setDescriptorsIsOpen(false);
    sender.ackUpkeep();
  };

  const [playChoiceRequired, setPlayChoiceRequired] = useState(false);

  const [gameState, setGameState] = useState({ turn: 0, phase: 'Unknown' });
  const [player, setPlayer] = useState('');

  const setGameState2 = (newGameState) => {
    window.gs = newGameState;
    setGameState(newGameState);
  };

  const [validActions, setValidActions] = useState([]);
  const closeActionModal = () => {
    setActionModalIsOpen(false);
    setValidActions([]);
  };
  const openActionModal = (actions) => {
    setActionModalIsOpen(true);
    setValidActions(actions);
  };

  const openOptionModal = () => {
    setOptionlModalIsOpen(true);
  };

  const closeOptionModal = () => {
    setOptionlModalIsOpen(false);
    setOptionModalOptions(null);
  };

  const actionCallback = (action) => {
    sender.actionChoice(action);
    closeActionModal();
  };

  // TODO: rename to changeChoice not optionChoice
  const optionModalCallback = (option) => {
    sender.optionChoice(option);
    closeOptionModal();
  };

  const targetOptionModalCallback = (card, effect, option) => {
    sender.targetChoice(card, effect, option);
    closeTargetOptionModal();
  };

  const sendPlaysToServer = (cards) => {
    /*
      color : "COLORLESS"
      description : "Deal 25 unblockable damage"
      id : "04bc6350"
      img : "/static/media/earthquake.44bc7134158ee4e0d168.png"
      img_name : "earthquake"
      mpCost : 30
      name : "FastPunch"
    */
    const slimCards = [];
    cards.forEach((card) => {
      slimCards.push({ name: card.name, id: card.id });
    });
    sender.plays(slimCards);
  };

  const filterCardsFromHand = (cards) => {
    // TODO: this only works before change phase
    // Remove played cards from hand
    const newGameState = { ...gameState };
    const newHand = [];
    const playedIds = cards.map((c) => c.id);
    for (let i = 0; i < newGameState[player].team.active_mage.deck.hand.length; i += 1) {
      const card = newGameState[player].team.active_mage.deck.hand[i];
      if (!playedIds.includes(card.tiny_id)) {
        newHand.push(card);
      }
    }
    newGameState[player].team.active_mage.deck.hand = newHand;
    setGameState2(newGameState);
  };

  const playChoiceCallback = (cards) => {
    sendPlaysToServer(cards);
    filterCardsFromHand(cards);
    // close modal
    setPlayChoiceRequired(false);
  };

  if (socket != null && !addedCallback) {
    const callback = (event) => {
      const e = JSON.parse(event.data);
      // This event is recv after the engine has conducted the end phase
      if (e.type === 'Upkeep') {
        // This should contain the EndDescriptors and is called
        // is sent as part of the upkeep event but from engine.end
        if (e.descriptors && Object.keys(e.descriptors).length) {
          setDescriptors(e.descriptors);
          openDescriptorsModal();
          setDescriptorCallback(() => () => { closeEndDescriptorsModal(); });
        } else {
          sender.ackUpkeep();
        }
      } else if (e.type === 'PhaseChange') {
        // surprisingly, upkeep descriptors hit this code block
        if (e.descriptors && Object.keys(e.descriptors).length) {
          setDescriptors(e.descriptors);
          openDescriptorsModal();
          setDescriptorCallback(() => () => { closeDescriptorsModal(e.phase); });
        } else {
          sender.ackPhaseChange(e.phase);
        }
      } else if (e.type === 'GameState') {
        // TODO: not sure if this will be problematic
        setGameState2(e.state);
        window.ee = e;
        // TODO this will be problematic
        setPlayer(e.player);

        if (e.state.phase === 'upkeep') {
          sender.ackGameStateUpkeep();
        }
        if (e.state.phase === 'action') {
          openActionModal(e.valid_actions);
        }
        if (e.state.phase === 'concede') {
          sender.ackGameStateConcede();
        }
        if (e.state.phase === 'input') {
          sender.ackGameStateInput();
        }
        if (e.state.phase === 'change') {
          sender.ackGameStateChange();
        }
        if (e.state.phase === 'play') {
          sender.ackGameStatePlay();
        }
        if (e.state.phase === 'end') {
          sender.ackGameStateEnd();
        }
      } else if (e.type === 'PlayOption') {
        // TODO: rename to PlayOption or something
        setPlayChoiceRequired(true);
      // This is used for mage changes, might be used for other things but that might break shit
      } else if (e.type === 'Option') {
        if (e.options) {
          setOptionModalOptions(e);
          openOptionModal();
        }
        sender.ackOption();
      // Also used for mage changes, but required ones at end of turn. Feels like this could be factored better
      } else if (e.type === 'RequireMageInput') {
        const params = new URLSearchParams(window.location.search);
        const p = params.get('p');
        if (e.players.includes(p)) {
          setOptionModalOptions(e);
          openOptionModal();
        }
        // not really sure this is necessary?
        sender.ackRequireMageInput();
      } else if (e.type === 'TargetOption') {
        setTargetOptions(e);
        openTargetOptionModal();
      } else if (e.type === 'GameOver') {
        setGameOverEvent(e);
        setGameOverModalIsOpen(true);
        const joinKey = searchParams.get('join');
        localStorage.removeItem(joinKey);
      } else if (e.type === 'BattleLogs') {
        setBattleLogs(e.logs);
      }
    };

    socket.addMessageCallback(callback);
    setAddedCallback(true);
  }

  const cards = gameState[player] ? getCardListFromPlayer(gameState[player]) : [];

  const requestBattleLogs = () => {
    // Send async request battlelogs msg
    sender.requestBattleLogs();
  };

  return (
    <div className="Game-container">
      <AnimationProvider>
        <GameOverModal
          isOpen={gameOverModalIsOpen}
          gameOverEvent={gameOverEvent}
          requestBattleLogs={requestBattleLogs}
          battleLogs={battleLogs}
        />
        <DescriptorsModal
          modalIsOpen={descriptorsIsOpen}
          descriptors={descriptors}
          callback={() => descriptorCallback()}
        />
        <TargetOptionModal
          isOpen={targetOptionIsOpen}
          callback={targetOptionModalCallback}
          options={targetOptions}
        />
        <ActionModal
          modalIsOpen={actionModalIsOpen}
          actionCallback={actionCallback}
          validActions={validActions}
        />
        <ChooseMageModal
          isOpen={optionModalIsOpen}
          callback={optionModalCallback}
          optionsEvent={optionModalOptions}
          player={player}
        />
        <Battlefield
          player={player}
          cards={cards}
          p1={gameState.p1}
          p2={gameState.p2}
          turn={gameState.turn}
          phase={gameState.phase}
          playChoiceRequired={playChoiceRequired}
          playChoiceCallback={(c) => { playChoiceCallback(c); }}
        />
      </AnimationProvider>
    </div>
  );
}

export default Game;
