import { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import styled, { ThemeContext } from 'styled-components';
import { Card } from 'ui-neumorphism';

import { EntityTable } from 'components/organisms';
import { TextField, Button } from 'components/atoms';
import { getMages } from '../../api/mage';
import { getDecks } from '../../api/deck';
import useApi from '../../api/useApi';

import './TeamEditor.css';

import blueMage from '../../wizards/blue-mage.png';
import greenMage from '../../wizards/green-mage.png';
import redMage from '../../wizards/red-mage.png';
import yellowMage from '../../wizards/yellow-mage.png';
import violetMage from '../../wizards/violet-mage.png';
import orangeMage from '../../wizards/orange-mage.png';
import greyMage from '../../wizards/grey-mage.png';

const getImage = (img) => {
  switch (img) {
    case 'blue-mage.png':
      return blueMage;
    case 'green-mage.png':
      return greenMage;
    case 'red-mage.png':
      return redMage;
    case 'yellow-mage.png':
      return yellowMage;
    case 'violet-mage.png':
      return violetMage;
    case 'orange-mage.png':
      return orangeMage;
    default:
      return greyMage;
  }
};

function MageSlotEmpty({ mageSlotId, setAddMageState }) {
  const { backgroundColor } = useContext(ThemeContext);
  const classes = `TeamEditor__mages--mage TeamEditor__mages--mage--empty TeamEditor__mages--mage-${mageSlotId}`;
  return (
    <Card style={{ backgroundColor, margin: '5px', flex: 1 }}>
      <div className={classes} onClick={() => setAddMageState(mageSlotId)}>
        Add Mage
      </div>
    </Card>
  );
}

function MageSlot({
  mageSlotId, setAddMageState, setAddDeckState, mage, deck,
}) {
  const { backgroundColor } = useContext(ThemeContext);

  if (!mage) {
    return MageSlotEmpty({ mageSlotId, setAddMageState });
  }

  const classes = `TeamEditor__mages--mage TeamEditor__mages--mage-${mageSlotId}`;

  // TODO: this is really gross, ideally this uses public path and we dont deal with image transformers
  let image = '';
  if (mage.image.startsWith('data')) {
    image = mage.image;
  } else {
    image = getImage(mage.image);
  }

  return (
    <Card style={{ backgroundColor, margin: '10px', flex: 1 }}>
      <div className={classes}>
        <div className="TeamEditor__mages--present">
          <div>
            <img src={image} alt={mage.name} />
          </div>
          <div>
            {mage.name}
          </div>
          <div>
            {deck ? deck.name : 'No Deck'}
          </div>
          <Button size="sm" onClick={() => setAddMageState(mageSlotId)}>Change Mage</Button>
          <Button size="sm" onClick={() => setAddDeckState(mageSlotId)}>Change Deck</Button>
        </div>
      </div>
    </Card>
  );
}

const getMageById = (mageId, mages) => {
  const mage = mages.find((m) => m.mage_id === mageId);
  return mage;
};

const getDeckById = (deckId, decks) => {
  const deck = decks.find((d) => d.deck_id === deckId);
  return deck;
};

const imageTransformerForMage = (mage) => {
  if (!mage) {
    return mage;
  }
  const newMage = { ...mage };
  newMage.image = getImage(mage.image);
  return newMage;
};

const imageTransformer = (result) => {
  const newResult = result.map((r) => imageTransformerForMage(r));
  return newResult;
};

const NameAndSave = styled.div`
  display: flex;
`;

export default function TeamEditor({ team, onSave }) {
  const [name, setName] = useState(team.name);

  const [mage1, setMage1] = useState(team.mage_1);
  const [mage2, setMage2] = useState(team.mage_2);
  const [mage3, setMage3] = useState(team.mage_3);

  // TODO: not sure I like this data model as flat
  // mages: [{mage, deck}, ...]
  const [deck1, setDeck1] = useState(team.deck_1);
  const [deck2, setDeck2] = useState(team.deck_2);
  const [deck3, setDeck3] = useState(team.deck_3);

  const [showMageSelector, setShowMageSelector] = useState(false);
  const [showDeckSelector, setShowDeckSelector] = useState(false);

  const [currentMageSlot, setCurrentMageSlot] = useState(0);

  const {
    loading: mageLoading, error: mageError, data: mageData, request: mageRequest,
  } = useApi(getMages, imageTransformer);

  useEffect(() => {
    try {
      mageRequest();
    } catch (err) {
      toast.error(err);
    }
  }, [mageRequest]);

  const {
    loading: deckLoading, error: deckError, data: deckData, request: deckRequest,
  } = useApi(getDecks);

  useEffect(() => {
    try {
      deckRequest();
    } catch (err) {
      toast.error(err);
    }
  }, [deckRequest]);

  const handleNameChange = (event) => {
    setName(event.value);
  };

  const handleSave = () => {
    const updatedTeam = {
      ...team, name,
    };
    // remove mage objects
    delete updatedTeam.mage_1;
    delete updatedTeam.mage_2;
    delete updatedTeam.mage_3;

    // add mage_ids
    updatedTeam.mage_id_1 = mage1 ? mage1.mage_id : null;
    updatedTeam.mage_id_2 = mage2 ? mage2.mage_id : null;
    updatedTeam.mage_id_3 = mage3 ? mage3.mage_id : null;

    // remove deck objects
    delete updatedTeam.deck_1;
    delete updatedTeam.deck_2;
    delete updatedTeam.deck_3;

    // add deck ids
    updatedTeam.deck_id_1 = deck1 ? deck1.deck_id : null;
    updatedTeam.deck_id_2 = deck2 ? deck2.deck_id : null;
    updatedTeam.deck_id_3 = deck3 ? deck3.deck_id : null;

    // TODO: this needs some sort of handling
    onSave(updatedTeam);
  };

  const setAddMageState = (mageSlot) => {
    setShowMageSelector(true);
    setShowDeckSelector(false);
    setCurrentMageSlot(mageSlot);
  };

  const setAddDeckState = (mageSlot) => {
    setShowMageSelector(false);
    setShowDeckSelector(true);
    setCurrentMageSlot(mageSlot);
  };

  const deckRowClickHandler = (row) => {
    const deck = getDeckById(row.deck_id, deckData);

    if (currentMageSlot === 1) {
      setDeck1(deck);
    } else if (currentMageSlot === 2) {
      setDeck2(deck);
    } else if (currentMageSlot === 3) {
      setDeck3(deck);
    }

    setShowDeckSelector(false);
  };

  const deckColumns = ['deck_id', 'name'];

  const mageRowClickHandler = (row) => {
    const mage = getMageById(row.mage_id, mageData);

    if (currentMageSlot === 1) {
      setMage1(mage);
    } else if (currentMageSlot === 2) {
      setMage2(mage);
    } else if (currentMageSlot === 3) {
      setMage3(mage);
    }

    setShowMageSelector(false);
  };

  const mageColumns = [
    'mage_id',
    // { type: 'image', column: 'image' },
    'name',
    'color',
  ];

  return (
    <div className="TeamEditor">
      <NameAndSave>
        <TextField
          label="Name"
          value={name}
          onChange={handleNameChange}
          rules={[
            (v) => v !== '' || 'Cant be empty',
          ]}
        />
        <Button onClick={handleSave}>Save</Button>
      </NameAndSave>

      <div className="TeamEditor__mages">
        <MageSlot
          mageSlotId={1}
          setAddMageState={setAddMageState}
          setAddDeckState={setAddDeckState}
          mage={mage1}
          deck={deck1}
        />
        <MageSlot
          mageSlotId={2}
          setAddMageState={setAddMageState}
          setAddDeckState={setAddDeckState}
          mage={mage2}
          deck={deck2}
        />
        <MageSlot
          mageSlotId={3}
          setAddMageState={setAddMageState}
          setAddDeckState={setAddDeckState}
          mage={mage3}
          deck={deck3}
        />
      </div>

      { showMageSelector && (
      <div className="TeamEditor__mageSelector">
        <EntityTable
          data={mageData}
          loading={mageLoading}
          error={mageError}
          columns={mageColumns}
          editHandler={mageRowClickHandler}
        />
      </div>
      )}

      { showDeckSelector && (
        <div className="TeamEditor__deckSelector">
          <EntityTable
            data={deckData}
            loading={deckLoading}
            error={deckError}
            columns={deckColumns}
            editHandler={deckRowClickHandler}
          />
        </div>
      )}

    </div>
  );
}
