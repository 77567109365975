import { useRef, useEffect } from 'react';
import { Tooltip } from 'components/atoms';
import styled from 'styled-components';

import './MessageList.css';

// TODO: message.id is not unique enough
function generateUUID() {
  const randomBytes = crypto.getRandomValues(new Uint8Array(16));
  // eslint-disable-next-line no-bitwise
  randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40; // set version 4
  // eslint-disable-next-line no-bitwise
  randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80; // set variant

  return Array.from(randomBytes).map((byte, index) => {
    const isDashNeeded = [4, 6, 8, 10].includes(index);
    return (isDashNeeded ? '-' : '') + byte.toString(16).padStart(2, '0');
  }).join('');
}

const LogMsgContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  font-family: monospace;
  padding-top: 0.2em;
`;

function LogMsg({ m }) {
  const { msg, type, turn } = m;
  const prefix = `${turn}|${(type || 'unknown')[0].toLowerCase()}`;
  const phase = type === 'PhaseChange' ? m.phase : null;
  const tip = `Turn: ${turn}, Type: ${type}${phase != null ? `, Phase: ${phase}` : ''}`;
  const message = `:${msg}`;
  return (
    <LogMsgContainer>
      <Tooltip id={m.id} text={tip}>
        {prefix}
      </Tooltip>
      {message}
    </LogMsgContainer>
  );
}

function ChatMsg({ m }) {
  return (
    <div>
      {m.msg}
    </div>
  );
}

// MessageList is used for both log and chat
function MessageList({ messages, forChat = false }) {
  const messageList = useRef(null);

  useEffect(() => {
    if (messageList != null && messageList.current != null) {
      messageList.current.scrollTop = messageList.current.scrollHeight;
    }
  });

  return (
    <div
      className="Message__list"
      ref={messageList}
    >
      {messages.map((msg) => (
        <div key={msg.id + generateUUID()} className="Message__message">
          {forChat ? <ChatMsg m={msg} /> : <LogMsg m={msg} />}
        </div>
      ))}
      <div key={messages.length + 1} className="Message__message" style={{ textAlign: 'center', fontSize: '2em' }}>
        ⚔
      </div>
    </div>
  );
}

export default MessageList;
