import styled from 'styled-components';
import { Link } from 'components/atoms';

import Section from './Section';

const ShortDiv = styled.div`
  width: window.innerWidth / 2;
`;

export default function LinkSection() {
  return (
    <Section title="Link">
      <ShortDiv>
        <Link to="/">inherit</Link>
        <Link to="/" variant="large">large</Link>
        <Link to="/" variant="small">small</Link>
      </ShortDiv>
    </Section>
  );
}
