import styled, { ThemeContext } from 'styled-components';
import { Card as NeuCard } from 'ui-neumorphism';
import { useContext } from 'react';
import DeckDetails from './DeckDetails';
import Conditions from './Conditions';
import Bar from './Bar';

const StyledNeuCard = styled(NeuCard)`
  height: 125px;
  width: 107px;
  font-size: 1.2em;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
  text-align: left;
`;

const StyledHeader = styled.div`
  font-size: 0.6em;
  height: 1em;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;

  @media screen and (max-width: 844px) {
    font-size: 8px;
    height: 20px;
  }
`;

const NameSpan = styled.span`
  font-weight: bold;
  top: -3px;
  position: relative;
`;

// TODO: Atomize this
function Header({
  name, color, right, size,
}) {
  const { cardColors } = useContext(ThemeContext);
  const backgroundColor = cardColors[color.toLowerCase()] || cardColors.default;

  const float = right ? 'right' : '';

  let padding = '8px';
  if (name.length > 20) {
    padding = '4px';
  }

  let nName = name;
  if (size === 'md') {
    nName = `* ${nName}`;
  }

  return (
    <StyledHeader style={{ padding, backgroundColor }}>
      <NameSpan style={{ float }}>{nName}</NameSpan>
    </StyledHeader>
  );
}

export default function MageOption({ mageData, onClick, id }) {
  const name = mageData.hp !== 0 ? mageData.name : `${mageData.name} ☠`;
  const ownerText = `o: ${mageData.owner}`;
  const { backgroundColor } = useContext(ThemeContext);

  return (
    <Container key={id} onClick={onClick}>
      <StyledNeuCard style={{ backgroundColor }}>
        <Header name={name} color={mageData.color} />
        <div style={{ margin: '0.5em' }}>
          <Bar
            initVal={mageData.hp}
            max={mageData.maxHP}
            regen={mageData.regenHP}
            size="sm"
          />
          <Bar
            initVal={mageData.mp}
            max={mageData.maxMP}
            regen={mageData.regenMP || 0}
            variant="mp"
            size="sm"
          />
          <DeckDetails deck={mageData.deck} width="80%" />
          <Conditions conditions={mageData.conditions} />
          <div>{ownerText}</div>
        </div>
      </StyledNeuCard>
    </Container>
  );
}
