import { createContext, useState, useEffect } from 'react';

import CardMap from '../CardMap';

export const ImageCacheContext = createContext({});

export function ImageCacheProvider({ children }) {
  const [imageCache, setImageCache] = useState({});

  useEffect(() => {
    const foo = CardMap;
    Object.keys(foo).forEach((key) => {
      const img = new Image();
      img.onload = () => {
        setImageCache((prevCache) => ({ ...prevCache, [key]: img }));
      };
      img.src = CardMap[key];
    });
  }, []);

  return (
    <ImageCacheContext.Provider value={imageCache}>
      {children}
    </ImageCacheContext.Provider>
  );
}
