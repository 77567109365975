import Section from './Section';

export default function FontSection() {
  const caps = 'A B C D E F G H I J K L M N O P Q R S T U V W X Y Z';
  const lower = 'a b c d e f g h i j k l m n o p q r s t u v w x y z';
  const num = '0 1 2 3 4 5 6 7 8 9';
  const special = '! @ # $ % ^ & * ( ) _ + - = [ ] \\ { } | ; \' : " , . / < > ? ` ~ "';

  return (
    <Section title="Font">
      <div>Josefin Sans</div>
      <div>{caps}</div>
      <div>{lower}</div>
      <div>{num}</div>
      <div>{special}</div>
    </Section>
  );
}
