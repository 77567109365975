import { Button, Modal } from 'components/atoms';
import { useState } from 'react';
import Section from './Section';

export default function ModalSection() {
  const [open, setOpen] = useState(false);
  const onClick = () => setOpen(!open);
  const [open2, setOpen2] = useState(false);
  const onClick2 = () => setOpen2(!open2);
  return (
    <Section title="Modal">
      <Button onClick={onClick}>Open Simple Modal</Button>
      <Button onClick={onClick2}>Open Action Modal</Button>
      <Modal open={open}>
        <p>Modal Content</p>
        <Button onClick={onClick}>Close Modal</Button>
      </Modal>
      <Modal open={open2} minimizable>
        <Button onClick={onClick2}>Play</Button>
        <Button onClick={onClick2}>Pass</Button>
        <Button onClick={onClick2}>Change</Button>
        <Button onClick={onClick2} variant="dangerous">Concede</Button>
      </Modal>
    </Section>
  );
}
