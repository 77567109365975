import { useState, useEffect } from 'react';
import process from 'process';
import { toast } from 'react-toastify';

const development = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

function isDev() {
  return development;
}

const devHost = 'localhost';
const devPort = 42069;
const devBasePath = `http://${devHost}:${devPort}/v1`;
const prodBasePath = '/api/v1';

const paths = {
  cardPath: '/cards',
  configPath: '/config',
  currentUserPath: '/current_user',
  loginPath: '/login',
  guestLoginPath: '/login/guest',
  logoutPath: '/logout',
  signupPath: '/signup',
  deckPath: '/decks',
  teamPath: '/teams',
  magePath: '/mages',
  buildPath: '/builds',
  buildEmbarkPath: '/builds/embark',
};

const getConfigPath = () => {
  if (isDev()) {
    return devBasePath + paths.configPath;
  }
  return prodBasePath + paths.configPath;
};

const getWsPath = () => {
  if (isDev()) {
    return `ws://${devHost}:${devPort}/ws`;
  }
  const host = window.location.hostname;
  const path = 'api/ws';
  const schema = 'wss';
  const prodPath = `${schema}://${host}/${path}`;
  return prodPath;
};

const getFromLocalStorage = () => {
  // TODO: check if the stored value contains the paths above or pop
  if (isDev()) {
    return null;
  }
  const conf = localStorage.getItem('config');
  const confObj = conf ? JSON.parse(conf) : null;
  return confObj;
};

const getPath = (basePath, path) => `${basePath}${path}`;

function getRouteConfig() {
  const conf = {};

  if (isDev()) {
    conf.basePath = devBasePath;
  } else {
    conf.basePath = prodBasePath;
  }

  Object.keys(paths).forEach((k) => {
    conf[k] = getPath(conf.basePath, paths[k]);
  });

  conf.wsPath = getWsPath();

  return conf;
}

const routeConf = getRouteConfig();

/*
 * Not really used anymore, maybe useful for server side conf at somepoint
 */
export default function useConfig() {
  const [config, setConfig] = useState({});

  const lsConfig = getFromLocalStorage();
  if (lsConfig) {
    return lsConfig;
  }

  const setConfigWithDefaults = (c) => {
    const rc = getRouteConfig();
    const conf = { ...c.data, ...rc };
    setConfig(conf);
    localStorage.setItem('config', JSON.stringify(conf));
  };

  useEffect(() => {
    (async function fetchConfig() {
      try {
        const response = await (await fetch(getConfigPath())).json();
        if (response.status >= 400) {
          throw new Error(`Bad response from server: ${response.status}`);
        }
        setConfigWithDefaults(response);
      } catch (e) {
        toast.error(e);
      }
    }());
  }, []);

  return config;
}

export { useConfig, routeConf };
