import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Root, Content } from 'components/templates';
import useApi from 'api/useApi';
import { createDeck } from 'api/deck';

import DeckEditor from './DeckEditor';

export default function DeckNew() {
  const deck = { name: '', description: '', cards: [] };
  const navigate = useNavigate();
  const {
    loading, error, data, request,
  } = useApi(createDeck);

  useEffect(() => {
    if (data) {
      toast.success('Deck created');
      navigate(`/decks/${data.deck_id}`);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const onSave = (updatedDeck) => {
    request(updatedDeck);
  };

  return (
    <Root>
      <Content>
        <h1>New Deck</h1>
        <DeckEditor deck={deck} onSave={onSave} loading={loading} />
      </Content>
    </Root>
  );
}
