import { Tooltip } from 'components/atoms';
import {
  MoonCrossIcon,
  SkullBoneIcon,
  SwirlIcon,
  RockIcon,
  ShieldIcon,
  ManIcon,
  FireIcon,
  PoisonIcon,
  HurtIcon,
  SwordIcon,
  KiteShieldIcon,
  StarPlusIcon,
  HandDropletIcon,
  XIcon,
  PlusIcon,
  TribalIcon,
  GemInHandIcon,
  ReverseSwirlIcon,
} from './EffectIcons';

function ConditionSigil({ condition, children }) {
  const text = `${condition.name}: ${condition.description}`;
  const salt = Math.random().toString(36).substr(2, 9);
  const id = `condition-${condition.name}-${condition.tiny_id}-${salt}`;
  return (
    <Tooltip id={id} text={text} place="bottom">
      {children}
    </Tooltip>
  );
}

export function GoadSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <ManIcon />
    </ConditionSigil>
  );
}

export function EnrageSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <FireIcon />
    </ConditionSigil>
  );
}

export function WeakenSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <HurtIcon />
    </ConditionSigil>
  );
}

export function EmbrazenSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <SwordIcon />
    </ConditionSigil>
  );
}

export function GraniteArmorSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <ShieldIcon />
    </ConditionSigil>
  );
}

export function PetrifySigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <RockIcon />
    </ConditionSigil>
  );
}

export function StonewallSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <KiteShieldIcon />
    </ConditionSigil>
  );
}

export function ManaBatterySigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <StarPlusIcon />
    </ConditionSigil>
  );
}

export function DewfallSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <HandDropletIcon />
    </ConditionSigil>
  );
}

export function EnsnareSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <XIcon />
    </ConditionSigil>
  );
}

export function ReplenishSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <PlusIcon />
    </ConditionSigil>
  );
}

export function ThornsSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <TribalIcon />
    </ConditionSigil>
  );
}

export function WhirlpoolSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <SwirlIcon />
    </ConditionSigil>
  );
}

export function NecrotaintSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <SkullBoneIcon />
    </ConditionSigil>
  );
}

export function TaxSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <GemInHandIcon />
    </ConditionSigil>
  );
}

export function PoisonSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <PoisonIcon />
    </ConditionSigil>
  );
}

export function SyphonSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <MoonCrossIcon />
    </ConditionSigil>
  );
}

export function VortexSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <ReverseSwirlIcon />
    </ConditionSigil>
  );
}

export function InfernoShiftSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <ReverseSwirlIcon />
    </ConditionSigil>
  );
}

export function TerracottaManeuveurSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <ShieldIcon />
    </ConditionSigil>
  );
}

export function VolticExchangeSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <ReverseSwirlIcon />
    </ConditionSigil>
  );
}

export function VerdantPassageSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <ReverseSwirlIcon />
    </ConditionSigil>
  );
}

export function TideTurnSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <ReverseSwirlIcon />
    </ConditionSigil>
  );
}

export function VileTransferenceSigil({ condition }) {
  return (
    <ConditionSigil condition={condition}>
      <PoisonIcon />
    </ConditionSigil>
  );
}
