import { useState } from 'react';

import { NavList } from 'components/molecules';
import { Link, Button } from 'components/atoms';
import { RootTitled } from 'components/templates';
import { TeamChooser } from 'components/organisms';

function PlayChooser({ teamName, teamId, clearChoice }) {
  const teamText = `Team: ${teamName}`;
  return (
    <div>
      <div>
        { teamText }
        <Button onClick={clearChoice}>Clear Choice</Button>
      </div>
      <NavList>
        <Link to={`/scene?cpu=true&tid=${teamId}`}>Vs. CPU</Link>
        <Link to={`/scene?tid=${teamId}`}>Vs. Player</Link>
        <Link to={`/join?tid=${teamId}`}>Join</Link>
      </NavList>
    </div>
  );
}

export default function Team() {
  const [teamId, setTeamId] = useState(null);
  const [teamName, setTeamName] = useState(null);

  const choiceCallback = (d) => {
    setTeamId(d.team_id);
    setTeamName(d.name);
  };

  const clearChoice = () => {
    setTeamId(null);
    setTeamName(null);
  };

  return (
    <RootTitled>
      {teamId
        ? (<PlayChooser clearChoice={clearChoice} teamName={teamName} teamId={teamId} />)
        : (<TeamChooser choiceCallback={choiceCallback} />)}
    </RootTitled>
  );
}
