import { useState } from 'react';
import { Button } from 'components/atoms';
import { ChooseMageModal } from 'components/organisms';
import Section from './Section';

export default function ChooseMageModalSection() {
  const [open, setOpen] = useState(false);
  const onClick = () => setOpen(!open);
  const [open2, setOpen2] = useState(false);
  const onClick2 = () => setOpen2(!open2);

  const genMage = () => ({
    name: 'Mage',
    hp: 20,
    max_hp: 20,
    regen_hp: 0,
    mp: 10,
    max_mp: 10,
    deck: {
      deck: [],
      hand: [],
      discard_pile: [],
    },
    conditions: [{ tiny_id: '0', name: 'Thorns' }],
    owner: 'owner',
    color: 'RED',
    front_img: 'frontPensiveWizard',
    back_img: 'backSketchWarlock',
  });

  const chooseMageModalDirect = {
    options: [
      genMage(),
      genMage(),
      genMage(),
      genMage(),
      genMage(),
    ],
  };

  const chooseMageModalBroadcast = {
    players: ['p1', 'p2'],
    options: {
      p1: [
        genMage(),
        genMage(),
        genMage(),
      ],
      p2: [
        genMage(),
        genMage(),
        genMage(),
      ],
    },
  };

  return (
    <Section title="ChooseMageModalSection">
      <Button onClick={onClick}>Open ChooseMageModalDirect</Button>
      <ChooseMageModal isOpen={open} optionsEvent={chooseMageModalDirect} callback={onClick} />
      <Button onClick={onClick}>Open ChooseMageModalBroadcast</Button>
      <ChooseMageModal isOpen={open2} optionsEvent={chooseMageModalBroadcast} callback={onClick2} player="p1" />
    </Section>
  );
}
