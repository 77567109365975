import styled from 'styled-components';

import { Card } from 'components/molecules';
import { CardData } from 'CardData';
import Section from './Section';

const genCard = (color, longText, n) => {
  let desc = 'Test Description';
  let name = n || `${color} Card`;
  const id = 1;
  if (longText) {
    name = 'Chrono-Warper, Master of Time and Space';
    desc = "At the start of each turn, the Chrono-Warper's passive ability, 'Time Warp,' grants its owner an "
      + 'additional point of mana, allowing them to play more cards and gain the upper hand in their battles.';
  }

  return new CardData(
    name,
    id,
    100,
    desc,
    'thorns',
    color,
  );
};

const FlexSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
`;

export default function CardSection() {
  const colors = ['BLUE', 'GREEN', 'YELLOW', 'ORANGE', 'RED', 'VIOLET'];
  const cards = colors.map((color) => genCard(color));

  return (
    <Section title="Card">
      <FlexSection>
        {cards.map((c) => <Card card={c} />)}
      </FlexSection>
      <FlexSection>
        <Card card={genCard('GREEN', true)} />
        <Card onClick={() => {}} card={genCard('BLUE', false, 'Clickable')} />
      </FlexSection>
    </Section>
  );
}
