const getRequestOptions = (method, body) => {
  const requestOptions = {
    method,
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  };
  if (body) {
    requestOptions.body = JSON.stringify(body);
  }
  return requestOptions;
};

const makeRequest = async (url, requestOptions) => {
  const resp = await fetch(url, requestOptions);
  const data = await resp.json();
  // TODO not ideal, JSON.s of data detail should parse things
  if (resp.status >= 400) {
    const err = new Error(`Error ${resp.status}: ${JSON.stringify(data.detail)}`);
    err.status = resp.status;
    err.data = data;
    throw err;
  }
  return data;
};

const createApiRequest = (url, method) => {
  const requestOptions = getRequestOptions(method);
  return async (body) => {
    if (body) {
      requestOptions.body = JSON.stringify(body);
    }
    const data = await makeRequest(url, requestOptions);
    return data;
  };
};

export { createApiRequest, getRequestOptions, makeRequest };
