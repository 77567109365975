import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  useNavigate, Navigate, useLocation,
} from 'react-router-dom';
import { TelegramShareButton, TelegramIcon } from 'react-share';
import { useRef, useState, useEffect } from 'react';
import { Logout } from 'components/molecules';
import { Link } from 'components/atoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faLink } from '@fortawesome/free-solid-svg-icons';
import { getCurrentUser } from 'api/user';
import useApi from 'api/useApi';

import './Header.css';

const HomeLink = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

function HeaderNav() {
  return (
    <div className="nav-container">
      <HomeLink>
        <Link to="/menu" variant="small">Home</Link>
      </HomeLink>
    </div>
  );
}

const CopyButton = styled.button`
  cursor: pointer;
  border-radius: 5px;
  padding: 5px;
  border: none;
  &:active {
    box-shadow: inset 4px 4px 8px #aaa, inset -4px -4px 8px #fff;
  }
`;

function InviteGame({ joinKey, teamJoin }) {
  if (!joinKey) {
    return <div />;
  }

  let sharePath = `/join?join=${joinKey}`;
  if (teamJoin) {
    sharePath = `${sharePath}&teamJoin=1`;
  }
  const shareUrl = `${window.location.protocol}//${window.location.host}${sharePath}`;
  const gameId = useRef(null);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(joinKey).then(
      () => {},
      (e) => {
        toast.error(e);
      },
    );
  };

  const copyUrlToClipboard = () => {
    navigator.clipboard.writeText(shareUrl).then(
      () => {},
      (e) => {
        toast.error(e);
      },
    );
  };

  return (
    <div className="Scene__share">
      <a href={sharePath}>
        <span className="gameid" ref={gameId}>{joinKey}</span>
      </a>
      <div className="Scene__share--telegram">
        <TelegramShareButton url={shareUrl}>
          <TelegramIcon size={15} round />
        </TelegramShareButton>
      </div>
      <CopyButton type="button" onClick={copyToClipboard} title="Copy JoinKey to clipboard">
        <FontAwesomeIcon icon={faCopy} />
      </CopyButton>
      <CopyButton type="button" onClick={copyUrlToClipboard} title="Copy Join URL to clipboard">
        <FontAwesomeIcon icon={faLink} />
      </CopyButton>
    </div>
  );
}

export default function Header({ children, joinKey, teamJoin }) {
  const [currentUser, setCurrentUser] = useState(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const {
    data: getCurrentUserData,
  } = useApi(getCurrentUser);

  useEffect(() => {
    if (getCurrentUserData) {
      setCurrentUser(getCurrentUserData);
      localStorage.setItem('user', JSON.stringify(getCurrentUserData));
    }
  }, [getCurrentUserData]);

  const userStr = currentUser ? `${currentUser.email}` : '*unauth*';
  const navigate = useNavigate();
  const location = useLocation();

  const redirectExclusionList = ['/', '/signup'];

  if (!currentUser && !redirectExclusionList.includes(location.pathname)) {
    return (
      <Navigate replace to="/" />
    );
  }

  const callback = () => {
    navigate('/');
    window.location.reload();
  };

  return (
    <div>
      <div className="Header">
        <HeaderNav />
        <InviteGame joinKey={joinKey} teamJoin={teamJoin} />
        <div>
          <span>
            {userStr}
          </span>
          <Logout callback={() => callback()} disabled={!currentUser} />
        </div>
      </div>
      {children}
    </div>
  );
}
