import { Button } from 'components/atoms';
import Section from './Section';

export default function ButtonSection() {
  return (
    <Section title="Button">
      <div>
        <Button>inherit</Button>
        <Button variant="primary">primary</Button>
        <Button variant="secondary">secondary</Button>
        <Button variant="dangerous">dangerous</Button>
        <Button>lots of words which extend</Button>
      </div>
      <div>
        <Button block>block</Button>
      </div>
      <div>
        <Button square>square</Button>
        <Button size="xs">xs</Button>
        <Button size="sm">sm</Button>
        <Button size="md">mg</Button>
        <Button size="lg">lg</Button>
      </div>
    </Section>
  );
}
