import styled from 'styled-components';
import {
  FizzleIcon,
  MoonCrossIcon,
  SkullBoneIcon,
  SwirlIcon,
  TribalIcon,
  TargetIcon,
  FlowerIcon,
  SnowIcon,
  XIcon,
  FireIcon,
  PlusIcon,
  SunIcon,
  RockIcon,
  BrokenHeartIcon,
  PoisonIcon,
  BloodIcon,
  SwordIcon,
  DeckIcon,
  HandIcon,
  DiscardIcon,
  StarPlusIcon,
  ShieldIcon,
  DeckSigil,
  HandSigil,
  DiscardSigil,
  ManIcon,
  HurtIcon,
  KiteShieldIcon,
  HandDropletIcon,
  GemInHandIcon,
} from 'components/molecules';
import Section from './Section';

const LabelledEffectIcon = styled.div`
  display: flex;
`;

const StyledH3 = styled.h3`
  margin-bottom: 1em;
  margin-top: 1em;
`;

const iconMap = {
  fizzleIcon: FizzleIcon,
  targetIcon: TargetIcon,
  flowerIcon: FlowerIcon,
  snowIcon: SnowIcon,
  xIcon: XIcon,
  fireIcon: FireIcon,
  plusIcon: PlusIcon,
  sunIcon: SunIcon,
  rockIcon: RockIcon,
  brokenHeartIcon: BrokenHeartIcon,
  poisonIcon: PoisonIcon,
  bloodIcon: BloodIcon,
  swordIcon: SwordIcon,
  starPlusIcon: StarPlusIcon,
  shieldIcon: ShieldIcon,
  manIcon: ManIcon,
  hurtIcon: HurtIcon,
  kiteShieldIcon: KiteShieldIcon,
  handDropletIcon: HandDropletIcon,
  tribalIcon: TribalIcon,
  swirlIcon: SwirlIcon,
  skullBoneIcon: SkullBoneIcon,
  gemInHandIcon: GemInHandIcon,
  moonCrossIcon: MoonCrossIcon,
};

export default function EffectIconSection() {
  return (
    <Section title="EffectIcon">
      <div style={{ display: 'flex' }}>
        {Object.keys(iconMap).map((iconKey) => {
          const Icon = iconMap[iconKey];
          return (
            <div key={iconKey}>
              <Icon />
            </div>
          );
        })}
      </div>
      <StyledH3>Labelled</StyledH3>
      <div>
        {Object.keys(iconMap).map((iconKey) => {
          const Icon = iconMap[iconKey];
          return (
            <LabelledEffectIcon key={iconKey}>
              <span style={{ marginRight: '0.5em' }}>{iconKey}</span>
              <Icon />
            </LabelledEffectIcon>
          );
        })}
      </div>
      <StyledH3>DeckIcons</StyledH3>
      <div style={{ display: 'flex' }}>
        <DeckIcon />
        <HandIcon />
        <DiscardIcon />
      </div>
      <StyledH3>Deck Sigils</StyledH3>
      <div style={{ display: 'flex' }}>
        <DeckSigil />
        <HandSigil />
        <DiscardSigil />
      </div>
    </Section>
  );
}
