import { Link as Link2 } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link2)`
  text-decoration: none;
  font-family: inherit;
  font-weight: 800;
  position: relative;
  border: none;
  background: none;
  text-transform: uppercase;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
  color: #333;

  &:focus, &:hover {
    color: #444;
  }

  &:focus:after, &:hover:after {
    text-decoration: none;
    width: 100%;
    left: 0%;
  }

  &:after {
    content: "";
    pointer-events: none;
    bottom: -2px;
    left: 50%;
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #333;
    transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
    transition-duration: 400ms;
    transition-property: width, left;
  }
`;

const SmallLink = styled(StyledLink)`
  font-size: 8px;
`;

const Container = styled.div`
  cursor: pointer;
  text-align: center;
  margin:10px;
`;

const SmallContainer = styled(Container)`
  margin:3px;
`;

const LargeLink = styled(StyledLink)`
  font-size: 32px;
`;

export default function Link({
  children, to, variant = 'inherit', onClick,
}) {
  let LinkComponent = StyledLink;
  let LinkContainer = Container;

  if (variant === 'small') {
    LinkComponent = SmallLink;
    LinkContainer = SmallContainer;
  } else if (variant === 'large') {
    LinkComponent = LargeLink;
  }

  return (
    <LinkContainer>
      <LinkComponent to={to} onClick={onClick}>{children}</LinkComponent>
    </LinkContainer>
  );
}
