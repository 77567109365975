import React, { useRef, useState } from 'react';

import { MessageList } from 'components/organisms';

import './Chat.css';

function Chat({ socket }) {
  const chatInput = useRef(null);

  const [textAreaValue, setTextAreaValue] = useState('');
  const [addedCallback, setAddedCallback] = useState(false);
  const [chatContent, setChatContent] = useState([]);

  if (socket != null && !addedCallback) {
    setAddedCallback(true);

    socket.addMessageCallback((event) => {
      const e = JSON.parse(event.data);
      if (e.type === 'Chat') {
        const msg = `c: ${e.player}: ${e.msg}`;
        const { id } = e;
        setChatContent((prev) => [...prev, { msg, id }]);
      }
    });
  }

  const handleKeyDown = (event) => {
    // handles the key event in a case statement
    switch (event.key) {
      case 'Enter':
        socket.sendMsg(textAreaValue, 'Chat');
        chatInput.current.value = '';
        break;
      default:
        break;
    }
  };

  const handleChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  return (
    <div className="Chat__container">
      <MessageList messages={chatContent} forChat />
      <div className="Chat__enter">
        <div className="Chat__input-container">
          <textarea
            ref={chatInput}
            onKeyUp={handleKeyDown}
            className="Chat__textarea"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>

  );
}

export default Chat;
