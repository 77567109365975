import { routeConf } from '../Config';

const getMages = async () => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    credentials: 'include',
  };

  const resp = await fetch(routeConf.magePath, requestOptions);
  const data = await resp.json();
  return data;
};

const noop = () => {};

export { getMages, noop };
