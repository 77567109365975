/*
 * Functions for sending events to the server
 */
function EventSender(socket) {
  const sendMsg = (msg, type, name) => {
    socket.sendMsg(msg, type, name);
  };

  const ackUpkeep = () => {
    sendMsg('Upkeep', 'Ack', 'msg');
  };

  const ackGameStateUpkeep = () => {
    sendMsg('GameState-upkeep', 'Ack', 'msg');
  };

  const ackPhaseChange = (phase) => {
    sendMsg(`PhaseChange-${phase}`, 'Ack', 'msg');
  };

  const ackGameStateConcede = () => {
    sendMsg('GameState-concede', 'Ack', 'msg');
  };

  const ackGameStateInput = () => {
    sendMsg('GameState-input', 'Ack', 'msg');
  };

  const ackGameStateChange = () => {
    sendMsg('GameState-change', 'Ack', 'msg');
  };

  const ackGameStatePlay = () => {
    sendMsg('GameState-play', 'Ack', 'msg');
  };

  const ackGameStateEnd = () => {
    sendMsg('GameState-end', 'Ack', 'msg');
  };

  const ackOption = () => {
    sendMsg('Option', 'Ack', 'msg');
  };

  const actionChoice = (action) => {
    sendMsg(action, 'ActionChoice', 'action');
  };

  const ackRequireMageInput = () => {
    sendMsg('RequireMageInput', 'Ack', 'msg');
  };

  const optionChoice = (option) => {
    sendMsg(option, 'ChangeChoice', 'option');
  };

  const plays = (cards) => {
    sendMsg(cards, 'Plays', 'plays');
  };

  const targetChoice = (card, effect, choice) => {
    const body = { card, effect, choice };
    sendMsg(body, 'TargetChoice', 'target');
  };

  const requestBattleLogs = () => {
    // msg and name dont make sense for this one as its just an RPC
    sendMsg('RequestBattleLogs', 'RequestBattleLogs', 'msg');
  };

  return {
    ackUpkeep,
    ackGameStateUpkeep,
    ackPhaseChange,
    ackGameStateConcede,
    ackGameStateInput,
    ackGameStateChange,
    ackGameStatePlay,
    ackGameStateEnd,
    ackOption,
    ackRequireMageInput,
    actionChoice,
    optionChoice,
    plays,
    targetChoice,
    requestBattleLogs,
  };
}
export default EventSender;
