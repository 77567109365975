import { routeConf } from 'Config';
import { createApiRequest, getRequestOptions, makeRequest } from './lib';

const getTeams = createApiRequest(routeConf.teamPath, 'GET');
const createTeam = createApiRequest(routeConf.teamPath, 'POST');

const getTeam = async (teamId) => {
  const requestOptions = getRequestOptions('GET');
  const path = `${routeConf.teamPath}/${teamId}`;
  const data = await makeRequest(path, requestOptions);
  return data;
};

const updateTeam = async (team) => {
  const requestOptions = getRequestOptions('PUT', team);
  const path = `${routeConf.teamPath}/${team.team_id}`;
  const data = await makeRequest(path, requestOptions);
  return data;
};

export {
  getTeams, getTeam, createTeam, updateTeam,
};
