import { useState } from 'react';
import { Mage } from 'components/organisms';
import { Button, TextField } from 'components/atoms';
import styled from 'styled-components';
import { useAnimation } from 'contexts/AnimationContext';
import Section from './Section';

import {
  RedMage, OrangeMage, YellowMage, GreenMage, BlueMage, VioletMage,
} from './MageFixtures';

const SixWidthGrid = styled.div`
  display: flex;
`;

const Header = styled.h4`
  width: 4em;
`;

const genAnimation = (category, data, owner, subjectClass, subjectId) => {
  const id = crypto.randomUUID();
  return {
    id,
    type: 'animate',
    category,
    data,
    subject_owner: owner,
    subject_class: subjectClass,
    subject_id: subjectId,
  };
};

export default function MageAnimationSection() {
  const { enqueue } = useAnimation();

  const damageMage = (mage) => {
    const val = parseInt((Math.random() - 0.55) * 100, 10);
    const animation = genAnimation('addHP', val, mage.playerConstant, 'Mage', mage.tinyID);
    enqueue(animation);
  };

  const spendMana = (mage) => {
    const val = parseInt((Math.random() - 0.55) * 100, 10);
    const animation = genAnimation('addMP', val, mage.playerConstant, 'Mage', mage.tinyID);
    enqueue(animation);
  };

  const drawCard = (mage) => {
    const val = parseInt((Math.random()) * 10, 10);
    const animation = genAnimation('drawCard', val, mage.playerConstant, 'Mage', mage.tinyID);
    enqueue(animation);
  };

  const shuffleInDiscard = (mage) => {
    const animation1 = genAnimation('shuffleInDiscard', 'nonce', mage.playerConstant, 'Mage', mage.tinyID);
    const animation2 = genAnimation('addHP', -30, mage.playerConstant, 'Mage', mage.tinyID);
    enqueue(animation1);
    enqueue(animation2);
  };

  const fizzle = (mage) => {
    const animation = genAnimation('fizzle', 'nonce', mage.playerConstant, 'Mage', mage.tinyID);
    enqueue(animation);
  };

  const [condition, setCondition] = useState('Enrage');

  const changeCondition = (e) => {
    setCondition(e.value);
  };

  const applyCondition = (mage) => {
    const data = {
      id: crypto.randomUUID(),
      type: 'apply',
      condition: {
        name: condition,
        tiny_id: crypto.randomUUID(),
        is_over: false,
        number_of_turns: 1,
        description: 'This is a test description',
      },
    };
    const animation = genAnimation('changeCondition', data, mage.playerConstant, 'Mage', mage.tinyID);
    enqueue(animation);
  };

  return (
    <Section title="MageAnimation">
      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Mage mageData={RedMage} size="md" right primary />
          <Mage mageData={OrangeMage} size="sm" right />
          <Mage mageData={YellowMage} size="sm" right />
        </div>
        <Mage mageData={GreenMage} size="sm" />
        <Mage mageData={BlueMage} size="sm" />
        <Mage mageData={VioletMage} size="md" primary />
      </div>
      <SixWidthGrid>
        <Header>Add HP</Header>
        <Button size="sm" onClick={() => damageMage(RedMage)}>Red Mage</Button>
        <Button size="sm" onClick={() => damageMage(OrangeMage)}>Orange Mage</Button>
        <Button size="sm" onClick={() => damageMage(YellowMage)}>Yellow Mage</Button>
        <Button size="sm" onClick={() => damageMage(GreenMage)}>Green Mage</Button>
        <Button size="sm" onClick={() => damageMage(BlueMage)}>Blue Mage</Button>
        <Button size="sm" onClick={() => damageMage(VioletMage)}>Violet Mage</Button>
      </SixWidthGrid>
      <SixWidthGrid>
        <Header>Mana</Header>
        <Button size="sm" onClick={() => spendMana(RedMage)}>Red Mage</Button>
        <Button size="sm" onClick={() => spendMana(OrangeMage)}>Orange Mage</Button>
        <Button size="sm" onClick={() => spendMana(YellowMage)}>Yellow Mage</Button>
        <Button size="sm" onClick={() => spendMana(GreenMage)}>Green Mage</Button>
        <Button size="sm" onClick={() => spendMana(BlueMage)}>Blue Mage</Button>
        <Button size="sm" onClick={() => spendMana(VioletMage)}>Violet Mage</Button>
      </SixWidthGrid>
      <SixWidthGrid>
        <Header>Draw</Header>
        <Button size="sm" onClick={() => drawCard(RedMage)}>Red Mage</Button>
        <Button size="sm" onClick={() => drawCard(OrangeMage)}>Orange Mage</Button>
        <Button size="sm" onClick={() => drawCard(YellowMage)}>Yellow Mage</Button>
        <Button size="sm" onClick={() => drawCard(GreenMage)}>Green Mage</Button>
        <Button size="sm" onClick={() => drawCard(BlueMage)}>Blue Mage</Button>
        <Button size="sm" onClick={() => drawCard(VioletMage)}>Violet Mage</Button>
      </SixWidthGrid>
      <SixWidthGrid>
        <Header>Shuffle</Header>
        <Button size="sm" onClick={() => shuffleInDiscard(RedMage)}>Red Mage</Button>
        <Button size="sm" onClick={() => shuffleInDiscard(OrangeMage)}>Orange Mage</Button>
        <Button size="sm" onClick={() => shuffleInDiscard(YellowMage)}>Yellow Mage</Button>
        <Button size="sm" onClick={() => shuffleInDiscard(GreenMage)}>Green Mage</Button>
        <Button size="sm" onClick={() => shuffleInDiscard(BlueMage)}>Blue Mage</Button>
        <Button size="sm" onClick={() => shuffleInDiscard(VioletMage)}>Violet Mage</Button>
      </SixWidthGrid>
      <SixWidthGrid>
        <Header>Fizzle</Header>
        <Button size="sm" onClick={() => fizzle(RedMage)}>Red Mage</Button>
        <Button size="sm" onClick={() => fizzle(OrangeMage)}>Orange Mage</Button>
        <Button size="sm" onClick={() => fizzle(YellowMage)}>Yellow Mage</Button>
        <Button size="sm" onClick={() => fizzle(GreenMage)}>Green Mage</Button>
        <Button size="sm" onClick={() => fizzle(BlueMage)}>Blue Mage</Button>
        <Button size="sm" onClick={() => fizzle(VioletMage)}>Violet Mage</Button>
      </SixWidthGrid>
      <SixWidthGrid>
        <Header>Conditions</Header>
        <TextField onChange={changeCondition} value="Enrage" />
      </SixWidthGrid>
      <SixWidthGrid>
        <Header />
        <Button size="sm" onClick={() => applyCondition(RedMage)}>Red Mage</Button>
        <Button size="sm" onClick={() => applyCondition(OrangeMage)}>Orange Mage</Button>
        <Button size="sm" onClick={() => applyCondition(YellowMage)}>Yellow Mage</Button>
        <Button size="sm" onClick={() => applyCondition(GreenMage)}>Green Mage</Button>
        <Button size="sm" onClick={() => applyCondition(BlueMage)}>Blue Mage</Button>
        <Button size="sm" onClick={() => applyCondition(VioletMage)}>Violet Mage</Button>
      </SixWidthGrid>
    </Section>
  );
}
