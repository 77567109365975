import { RootTitled } from 'components/templates';
import { Tile } from 'components/atoms';
import styled from 'styled-components';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { chooseStarter } from 'api/build';
import useApi from 'api/useApi';
import { useNavigate } from 'react-router-dom';

import blueMage from '../../wizards/blue-mage.png';
import greenMage from '../../wizards/green-mage.png';
import redMage from '../../wizards/red-mage.png';
import yellowMage from '../../wizards/yellow-mage.png';
import violetMage from '../../wizards/violet-mage.png';
import orangeMage from '../../wizards/orange-mage.png';

// create a grid of 3 by 2
const TileContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  margin: 2em;
`;

function MageTile({ name, img, onClick }) {
  return (
    <Tile onClick={onClick}>
      <div style={{ padding: '1rem' }}>
        {name}
        <img src={img} />
      </div>
    </Tile>
  );
}

export default function NewBuild() {
  const navigate = useNavigate();

  const {
    data, loading, error, request,
  } = useApi(chooseStarter);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const urlParams = new URLSearchParams(window.location.search);
      const buildId = urlParams.get('bid');
      navigate(`/build/map?bid=${buildId}`);
    }
  }, [data]);

  const chooseMage = (color) => {
    const urlParams = new URLSearchParams(window.location.search);
    const buildId = urlParams.get('bid');
    request(buildId, color);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <RootTitled>
      <h1>Choose Your Mage!</h1>
      <TileContainer>
        <MageTile name="red" img={redMage} onClick={() => chooseMage('red')} />
        <MageTile name="orange" img={orangeMage} onClick={() => chooseMage('orange')} />
        <MageTile name="yellow" img={yellowMage} onClick={() => chooseMage('yellow')} />
        <MageTile name="green" img={greenMage} onClick={() => chooseMage('green')} />
        <MageTile name="blue" img={blueMage} onClick={() => chooseMage('blue')} />
        <MageTile name="violet" img={violetMage} onClick={() => chooseMage('violet')} />
      </TileContainer>
    </RootTitled>
  );
}
