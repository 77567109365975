import { useSearchParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import styled from 'styled-components';

import { Root, Content } from 'components/templates';
import { TextField, Button } from 'components/atoms';
import { TeamChooser } from 'components/organisms';

const JoinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

function TeamSelected({ teamName, clearCallback }) {
  return (
    <div>
      <span>{teamName}</span>
      <Button onClick={clearCallback}>Clear</Button>
    </div>
  );
}

function TeamSelector({ choiceCallback, clearCallback }) {
  const [teamId, setTeamId] = useState(null);
  const [teamName, setTeamName] = useState(null);

  const cb = (d) => {
    setTeamId(d.team_id);
    setTeamName(d.name);
    choiceCallback(d);
  };

  const clearChoice = () => {
    setTeamId(null);
    setTeamName(null);
    clearCallback();
  };

  return (
    <JoinContainer>
      {teamId ? <TeamSelected teamName={teamName} clearCallback={clearChoice} /> : <TeamChooser choiceCallback={cb} />}
    </JoinContainer>
  );
}

export default function Join() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // TODO: If this team is given we should fetch it by id and show it as TeamSelected
  const initialTeamId = searchParams.get('tid');
  const [teamId, setTeamId] = useState(initialTeamId);

  const param = searchParams.get('join');
  const jk = param || '';
  const [joinKey, setJoinKey] = useState(jk);

  const teamJoin = searchParams.get('teamJoin');
  const showTeamChooser = !!teamJoin;

  const choiceCallback = (d) => {
    setTeamId(d.team_id);
  };
  const clearChoiceCallback = () => {
    setTeamId(null);
  };
  const handleChange = (e) => {
    setJoinKey(e.value);
  };

  const joinGame = (e, tid) => {
    e.preventDefault();
    let path = `/scene?join=${joinKey}`;
    if (teamId) {
      path = `${path}&tid=${tid}`;
    }
    navigate(path);
  };

  // TODO nav-container should function between join and scene
  return (
    <Root>
      <Content>
        <h1>Join</h1>
        <JoinContainer>
          <TextField
            placeholder="Enter Join Key"
            value={joinKey}
            onChange={handleChange}
          />
          <Button onClick={(e) => joinGame(e, teamId)}>Join</Button>
        </JoinContainer>
        {showTeamChooser ? <TeamSelector choiceCallback={choiceCallback} clearCallback={clearChoiceCallback} /> : null}
      </Content>
    </Root>
  );
}
