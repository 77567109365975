import MageData from 'MageData';

function baseData() {
  return {
    name: 'Mage',
    hp: 100,
    max_hp: 100,
    regen_hp: 0,
    mp: 100,
    max_mp: 100,
    deck: {
      deck: [0, 1, 2, 3, 4, 5],
      hand: [0, 1, 2, 3, 4, 5],
      discard_pile: [0, 1, 2, 3, 4, 5],
    },
    conditions: [{ tiny_id: '0', name: 'Thorns' }],
    owner: 'owner',
    owner_constant: 'p1',
    color: 'RED',
    front_img: 'red1front',
    back_img: 'red1back',
  };
}

function genMageData(color) {
  const data = baseData();
  data.color = color.toUpperCase();
  const front = `${color.toLowerCase()}1front`;
  const back = `${color.toLowerCase()}1back`;
  data.front_img = front;
  data.back_img = back;
  data.conditions = [];
  data.tiny_id = crypto.randomUUID();
  return new MageData(data);
}

const RedMage = genMageData('red');
const OrangeMage = genMageData('orange');
const YellowMage = genMageData('yellow');
const GreenMage = genMageData('green');
const BlueMage = genMageData('blue');
const VioletMage = genMageData('violet');

export {
  RedMage, OrangeMage, YellowMage, GreenMage, BlueMage, VioletMage,
};
