import { toast } from 'react-toastify';
import styled from 'styled-components';
import { Modal } from 'components/atoms';
import { MageOption } from 'components/molecules';
import MageData from 'MageData';

const Container = styled.div`
  display: flex;
`;

export default function ChooseMageModal({
  isOpen, optionsEvent, callback, player,
}) {
  if (!optionsEvent) {
    return (
      <div />
    );
  }

  // TODO: this is hacky and gross, should clean up to handle broadcasted options
  // and direct options can be fixed with note in RequireMageInputEvent class server side
  let options = [];
  if (optionsEvent.players) {
    options = optionsEvent.options[player];
  } else {
    options = optionsEvent.options;
  }

  // This is an error state as we should always have options
  if (options === undefined) {
    toast.error('No options available, something has gone terribly wrong!');
  }

  options = options || [];

  return (
    <Modal
      open={isOpen}
      minimizable
    >
      <Container>
        {options.map((option) => (
          <div key={option.tiny_id}>
            <MageOption
              mageData={new MageData(option)}
              onClick={() => callback(option)}
              id={option.tiny_id}
            />
          </div>
        ))}
      </Container>
    </Modal>
  );
}
