import { memo, useContext } from 'react';
import { IconCacheContext } from 'contexts/IconCache';
import styled from 'styled-components';

const IconWrapper = styled.div`
  width: ${({ scaleFactor, width }) => `${scaleFactor * width}px`};
  height: ${({ scaleFactor, height }) => `${scaleFactor * height}px`};
  background-image: ${({ image }) => `url(${image.src})`};
  background-position:
    ${({ scaleFactor, xOffset, yOffset }) => `-${scaleFactor * xOffset}px -${scaleFactor * yOffset}px`};
  background-size: ${({ scaleFactor, image }) => `${scaleFactor * image.width}px ${scaleFactor * image.height}px`};
  background-repeat: no-repeat;
`;

function IconSection({
  filename, width, height, xOffset, yOffset, scaleFactor = 1,
}) {
  const { [filename]: image } = useContext(IconCacheContext);

  if (!image) {
    return (
      <div>⟳</div>
    );
  }

  return (
    <div>
      <IconWrapper
        scaleFactor={scaleFactor}
        width={width}
        height={height}
        xOffset={xOffset}
        yOffset={yOffset}
        image={image}
      />
    </div>
  );
}

export default memo(IconSection);
