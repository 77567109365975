import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { postSignup } from 'api/user';
import useApi from 'api/useApi';
import { Titled, Root } from 'components/templates';
import { Button, TextField } from 'components/atoms';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { parseErrorForToast } from 'Error';

const StyledSignupForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button {
    margin: 10px;
    width: 100%;
  }
`;

function SignupForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {
    error, data, request,
  } = useApi(postSignup);

  useEffect(() => {
    if (data) {
      toast.success('Signup Successful!');
      localStorage.setItem('user', JSON.stringify(data));
      navigate('/menu');
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const err = parseErrorForToast(error);
      toast.error(err);
    }
  }, [error]);

  const handleSignup = (e) => {
    e.preventDefault();
    request({ email, password });
  };

  return (
    <StyledSignupForm>
      <TextField
        label="email"
        name="email"
        id="email"
        value={email}
        onChange={(e) => setEmail(e.value)}
      />
      <TextField
        label="password"
        name="password"
        id="password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.value)}
      />
      <Button onClick={handleSignup}>Signup</Button>
    </StyledSignupForm>
  );
}

export default function Signup() {
  return (
    <Root>
      <Titled>
        <div className="Signup">
          <SignupForm />
        </div>
      </Titled>
    </Root>
  );
}
