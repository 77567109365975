import { IconSection } from 'components/atoms';

// -- effectIcon1 --

export function FizzleIcon() {
  return (
    <IconSection filename="effectIcons1" width={89} height={89} xOffset={127} yOffset={610} scaleFactor={0.25} />
  );
}

export function TargetIcon() {
  return (
    <IconSection filename="effectIcons1" width={89} height={89} xOffset={14} yOffset={14} scaleFactor={0.25} />
  );
}

export function FlowerIcon() {
  return (
    <IconSection filename="effectIcons1" width={89} height={89} xOffset={127} yOffset={14} scaleFactor={0.25} />
  );
}

export function SnowIcon() {
  return (
    <IconSection filename="effectIcons1" width={89} height={89} xOffset={239} yOffset={14} scaleFactor={0.25} />
  );
}

export function XIcon() {
  return (
    <IconSection filename="effectIcons1" width={89} height={89} xOffset={351} yOffset={14} scaleFactor={0.25} />
  );
}

export function FireIcon() {
  return (
    <IconSection filename="effectIcons1" width={111} height={111} xOffset={682} yOffset={0} scaleFactor={0.2} />
  );
}

export function PlusIcon() {
  return (
    <IconSection filename="effectIcons1" width={89} height={89} xOffset={13} yOffset={130} scaleFactor={0.25} />
  );
}

export function SunIcon() {
  return (
    <IconSection filename="effectIcons1" width={89} height={89} xOffset={351} yOffset={130} scaleFactor={0.25} />
  );
}

export function SkullBoneIcon() {
  return (
    <IconSection filename="effectIcons1" width={89} height={89} xOffset={350} yOffset={370} scaleFactor={0.25} />
  );
}

// -- effectIcon2 --

export function TribalIcon() {
  return (
    <IconSection filename="effectIcons2" width={149} height={149} xOffset={310} yOffset={3} scaleFactor={0.15} />
  );
}

export function SwirlIcon() {
  return (
    <IconSection filename="effectIcons2" width={149} height={149} xOffset={937} yOffset={150} scaleFactor={0.15} />
  );
}

export function ReverseSwirlIcon() {
  return (
    <IconSection filename="effectIcons2" width={149} height={149} xOffset={785} yOffset={150} scaleFactor={0.15} />
  );
}

export function MoonCrossIcon() {
  return (
    <IconSection filename="effectIcons2" width={149} height={149} xOffset={450} yOffset={440} scaleFactor={0.15} />
  );
}

// -- effectIcon4 --

export function GemInHandIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={850} yOffset={220} scaleFactor={0.15} />
  );
}

export function RockIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={29} yOffset={37} scaleFactor={0.15} />
  );
}

export function BrokenHeartIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={190} yOffset={35} scaleFactor={0.15} />
  );
}

export function PoisonIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={353} yOffset={35} scaleFactor={0.15} />
  );
}

export function BloodIcon() {
  return (
    <IconSection filename="effectIcons4" width={165} height={165} xOffset={18} yOffset={808} scaleFactor={0.135} />
  );
}

export function SwordIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={28} yOffset={230} scaleFactor={0.15} />
  );
}

export function HandDropletIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={353} yOffset={429} scaleFactor={0.15} />
  );
}

export function StarPlusIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={522} yOffset={429} scaleFactor={0.15} />
  );
}

export function ShieldIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={192} yOffset={817} scaleFactor={0.15} />
  );
}

export function ManIcon() {
  return (
    <IconSection filename="effectIcons4" width={149} height={149} xOffset={668} yOffset={626} scaleFactor={0.15} />
  );
}

// -- EffectIcon5 --

export function HurtIcon() {
  return (
    <IconSection filename="effectIcons5" width={157} height={157} xOffset={537} yOffset={15} scaleFactor={0.14} />
  );
}

export function KiteShieldIcon() {
  return (
    <IconSection filename="effectIcons5" width={157} height={157} xOffset={187} yOffset={186} scaleFactor={0.14} />
  );
}
