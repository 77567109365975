import { HomeNav } from 'components/organisms';
import { HomeOrLogin } from 'components/templates';

export default function Home() {
  return (
    <HomeOrLogin>
      <HomeNav />
    </HomeOrLogin>
  );
}
