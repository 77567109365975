import { useState } from 'react';
import { toast } from 'react-toastify';

import { MessageList } from 'components/organisms';

import './Log.css';

function Log({ socket }) {
  const [addedCallback, setAddedCallback] = useState(false);
  const [logContent, setLogContent] = useState([]);

  if (socket != null && !addedCallback) {
    setAddedCallback(true);

    const callback = (event) => {
      const e = JSON.parse(event.data);
      // We dont care about this event
      if (e.type === 'GameState') {
        return;
      }

      const {
        msg, turn, id, type,
      } = e;

      if (e.type === 'Error') {
        toast.error(e.msg);
        setLogContent((prev) => [...prev, {
          msg, id, type, turn: e.turn,
        }]);
      } else if (e.type === 'Log') {
        // TODO: dead code?
        setLogContent((prev) => [...prev, {
          msg, id, type, turn,
        }]);
      } else if (e.type === 'Upkeep') {
        setLogContent((prev) => [...prev, {
          msg, id, type, turn,
        }]);
      } else if (e.type === 'Concede') {
        setLogContent((prev) => [...prev, {
          msg, id, type, turn,
        }]);
      } else if (e.type === 'Change') {
        setLogContent((prev) => [...prev, {
          msg, id, type, turn,
        }]);
      } else if (e.type === 'Play') {
        setLogContent((prev) => [...prev, {
          msg, id, type, turn,
        }]);
      } else if (e.type === 'End') {
        setLogContent((prev) => [...prev, {
          msg, id, type, turn,
        }]);
      } else if (e.type === 'PhaseChange') {
        setLogContent((prev) => [...prev, {
          msg, id, type, turn, phase: e.phase,
        }]);
      } else if (e.type === 'BattleLog') {
        setLogContent((prev) => [...prev, {
          msg, id, type, turn,
        }]);
      } else if (e.type === 'Turn') {
        setLogContent((prev) => [...prev, {
          msg, id, type, turn,
        }]);
      } else if (e.type === 'GameOver') {
        const cmsg = `Game Over! ${e.winner} wins! ${e.outcome}`;
        setLogContent((prev) => [...prev, {
          msg: cmsg, id, type, turn,
        }]);
      }
    };

    socket.addMessageCallback(callback);
  }

  return (
    <div className="Log__container">
      <MessageList messages={logContent} />
    </div>
  );
}

export default Log;
