import styled from 'styled-components';

const Container = styled.div``;

// TODO: might be dead code?
export default function Name({ name, right }) {
  const float = right ? 'right' : '';
  return (
    <Container style={{ float }}>
      {name}
    </Container>
  );
}
