import {
  useRef, useContext, useState, useEffect,
} from 'react';
import styled, { ThemeContext, keyframes } from 'styled-components';
import { Card as NeuCard } from 'ui-neumorphism';
import { faArrowRotateRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MageAvatar } from 'components/atoms';
import { useAnimation } from 'contexts/AnimationContext';
import {
  LargeDiscardIcon, Bar, DeckDetails, Conditions, HandSigil, FizzleIcon, MageHeader,
} from 'components/molecules';

const Container = styled.div``;

const getSizeStyles = (size) => {
  switch (size) {
    case 'sm':
      return {
        width: '300px',
        height: '100px',
      };
    default:
      return {
        width: '400px',
        height: '136px',
      };
  }
};

const StyledNeuCard = styled(NeuCard)`
  height: ${(props) => getSizeStyles(props.size).height};
  max-width: ${(props) => getSizeStyles(props.size).width};
  font-size: 1.2em;
  margin: 0.5em;
  border-left-width: ${(props) => (props.primary && !props.right ? '3px' : '0px')};
  border-right-width: ${(props) => (props.primary && props.right ? '3px' : '0px')};
  border-bottom-width: 0px;
  border-top-width: 0px;
  border-style: solid;
  border-color: #57d961;
`;

const Body = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.right ? 'row-reverse' : 'row')};
`;

const Details = styled.div`
  width: 100%;
  padding-left: 0.2em;
  padding-right: 0.2em;
  text-align: ${(props) => (props.right ? 'right' : 'left')};
`;

// Draw Splash Componentry

const driftRight = keyframes`
  0% { transform: translate(-10px, 0px); opacity: 1; }
  100% { transform: translate(0px, -10px); opacity: 0; }
`;

const driftLeft = keyframes`
  0% { transform: translate(10px, 0px); opacity: 1; }
  100% { transform: translate(0px, -10px); opacity: 0; }
`;

const DrawSplashContainer = styled.div`
  position: relative;
`;

const DrawSplashVal = styled.div`
  position: absolute;
  font-size: 2em;
  animation: ${(props) => (props.position === 'left' ? driftLeft : driftRight)}  1.5s ease-out;
  ${(props) => (props.position === 'left' ? `left: -${props.dist}em;` : `right: -${props.dist}em;`)}
  top: -2em;
  display: flex;
`;

function DrawSplash({ drawVal, position }) {
  const drawStr = `+${drawVal}`;

  return (
    <DrawSplashContainer>
      <DrawSplashVal dist={3} position={position}>
        {drawStr}
        <HandSigil />
      </DrawSplashVal>
    </DrawSplashContainer>
  );
}

// End Draw Splash

// Shuffle in Discard Componentry
// This reuses the DrawSplashContainer and DrawSplashVal
// TODO: we should refactor the animation tray to its own component

function ShuffleInDiscardSplash({ position }) {
  return (
    <DrawSplashContainer>
      <DrawSplashVal dist={2} position={position}>
        <FontAwesomeIcon icon={faArrowRotateRight} style={{ fontSize: '0.6em' }} />
        <LargeDiscardIcon />
      </DrawSplashVal>
    </DrawSplashContainer>
  );
}

// End Shuffle in Discard

// Fizzle Splash

function FizzleSplash({ position }) {
  return (
    <DrawSplashContainer>
      <DrawSplashVal dist={4} position={position}>
        <span style={{ fontSize: '0.5em' }}>Fizzle</span>
        <FizzleIcon />
      </DrawSplashVal>
    </DrawSplashContainer>
  );
}

// Condition Splash

function ConditionSplash({ position, data }) {
  const { condition } = data;
  let prefix = '+';
  if (data.type === 'remove') {
    prefix = '-';
  }

  return (
    <DrawSplashContainer>
      <DrawSplashVal dist={2} position={position}>
        {prefix}
        <Conditions conditions={[condition]} />
      </DrawSplashVal>
    </DrawSplashContainer>
  );
}

const isMyAnimation = (peak, mageData) => (peak
    && mageData
    && peak.subject_owner === mageData.playerConstant
    && peak.subject_id === mageData.tinyID);

export default function Mage({
  mageData, size = 'md', right, primary = false,
}) {
  const { backgroundColor } = useContext(ThemeContext);
  const [isMobile] = useState(window.innerWidth < 844);
  const hpRef = useRef();
  const mpRef = useRef();

  // animation contexts and states, TODO: this should likely be encapsulated into its own component
  const { queue, dequeue } = useAnimation();
  const [showDraw, setShowDraw] = useState(false);
  const [drawVal, setDrawVal] = useState(0);
  const [showShuffleInDiscard, setShowShuffleInDiscard] = useState(false);
  const [showFizzle, setShowFizzle] = useState(false);
  const [showCondition, setShowCondition] = useState(false);
  const [conditionSplash, setConditionSplash] = useState(null);

  useEffect(() => {
    if (queue.length > 0) {
      const peak = queue[0];
      if (isMyAnimation(peak, mageData)) {
        if (peak.category === 'addMP') {
          mpRef.current.addValue(peak.data);
        } else if (peak.category === 'addHP') {
          hpRef.current.addValue(peak.data);
        } else if (peak.category === 'drawCard') {
          setShowDraw(true);
          setDrawVal(peak.data);
          setTimeout(() => {
            setShowDraw(false);
            setDrawVal(0);
          }, 1000);
        } else if (peak.category === 'shuffleInDiscard') {
          setShowShuffleInDiscard(true);
          setTimeout(() => {
            setShowShuffleInDiscard(false);
          }, 1000);
        } else if (peak.category === 'fizzle') {
          setShowFizzle(true);
          setTimeout(() => {
            setShowFizzle(false);
          }, 1000);
        } else if (peak.category === 'changeCondition') {
          setShowCondition(true);
          setConditionSplash(peak.data);
          setTimeout(() => {
            setShowCondition(false);
            setConditionSplash(null);
          }, 1000);
        }
        dequeue();
      }
    }
  }, [queue]);

  let forcedSize = size;

  if (isMobile) {
    forcedSize = 'sm';
  }

  const float = right ? 'right' : '';
  const splash = right ? 'left' : 'right';

  return (
    <Container>
      <StyledNeuCard primary={primary} right={right} style={{ backgroundColor, float }} size={forcedSize}>
        <MageHeader
          name={mageData.name}
          color={mageData.color.toLowerCase()}
          right={right}
        />
        <Body right={right}>
          <div>
            <MageAvatar
              img={right ? mageData.frontImage : mageData.backImage}
              size={forcedSize}
            />
          </div>
          <Details right={right}>
            <Bar
              initVal={mageData.hp}
              max={mageData.maxHP}
              regen={mageData.regenHP}
              size={forcedSize}
              ref={hpRef}
              splash={splash}
            />
            <Bar
              initVal={mageData.mp}
              max={mageData.maxMP}
              regen={mageData.regenMP || 0}
              variant="mp"
              size={forcedSize}
              ref={mpRef}
              splash={splash}
            />
            <DeckDetails deck={mageData.deck} />
            <Conditions conditions={mageData.conditions} />
          </Details>
        </Body>
        {showDraw && (
          <DrawSplash position={splash} drawVal={drawVal} />
        )}
        {showShuffleInDiscard && (
          <ShuffleInDiscardSplash position={splash} />
        )}
        {showFizzle && (
          <FizzleSplash position={splash} />
        )}
        {showCondition && (
          <ConditionSplash position={splash} data={conditionSplash} />
        )}
      </StyledNeuCard>
    </Container>
  );
}
