import {
  createContext, useContext, useMemo, useState,
} from 'react';

const AnimationContext = createContext({
  queue: [],
  enqueue: () => {},
  dequeue: () => {},
});

const useAnimation = () => useContext(AnimationContext);

function AnimationProvider({ children }) {
  const [queue, setQueue] = useState([]);

  const enqueue = (animation) => {
    setQueue((prevQueue) => [...prevQueue, animation]);
  };
  const dequeue = () => {
    setQueue((prevQueue) => prevQueue.slice(1));
  };

  const contextValue = useMemo(() => ({
    queue,
    enqueue,
    dequeue,
  }), [queue]);

  return (
    <AnimationContext.Provider value={contextValue}>
      {children}
    </AnimationContext.Provider>
  );
}

export { useAnimation, AnimationContext, AnimationProvider };
