import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

import { Button, TextField } from 'components/atoms';
import useApi from 'api/useApi';
import { getCards } from 'api/card';
// TODO: component
import CardIndex from '../cards/CardIndex';
import { serializeAllToCardData } from '../../CardData';

const getCardById = (id, cards2) => {
  // returns the card and index from the list if the id is the given id
  let card = null;
  cards2.map((c) => {
    if (c.id === id) {
      card = c;
    }
    return c;
  });
  return card;
};

const getCardByName = (n, cards2) => {
  const card = cards2.find((c) => c.name === n);
  return card;
};

const Header = styled.div`
  display: flex;
`;

export default function DeckEditor({ deck, onSave, loading }) {
  const [name, setName] = useState(deck.name);
  const [description, setDescription] = useState(deck.description);
  const [cards, setCards] = useState([]);
  const [deckCards, setDeckCards] = useState([]);

  const {
    data: cardsData, request: cardsRequest,
  } = useApi(getCards, (result) => serializeAllToCardData(result.data));

  useEffect(() => {
    try {
      cardsRequest();
    } catch (e) {
      toast.error(e);
    }
  }, [cardsRequest]);

  useEffect(() => {
    if (cardsData) {
      setCards(cardsData);
      // swapping card name response into card objects from card index, also gross
      const dc = [...deck.cards].map((n) => getCardByName(n, cardsData));
      // TODO ugh, hacks to get react lists to behave correctly with ids
      const dc2 = [...dc].map((c2) => ({ ...c2, id: `${c2.id}-${Math.random()}` }));
      setDeckCards(dc2);
    }
  }, [cardsData]);

  const handleNameChange = (event) => {
    setName(event.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.value);
  };

  const handleSave = () => {
    const cardNames = [...deckCards].map((c) => c.name);
    const updatedDeck = {
      ...deck, name, description, cards: cardNames,
    };
    onSave(updatedDeck);
  };

  const deckCardClickCallback = (event) => {
    // Ge the card id
    let id = event.currentTarget.getElementsByClassName('Card2__hidden-id')[0].textContent;
    id = id.replace(/\s/g, '');
    // Get the Card
    const c = getCardById(id, deckCards);
    const newDeckCards = [...deckCards].filter((card) => card.id !== c.id);
    setDeckCards(newDeckCards);
  };

  const cardClickCallback = (event) => {
    // Get the Clicked Card ID
    let id = event.currentTarget.getElementsByClassName('Card2__hidden-id')[0].textContent;
    // removes all whitespace from id
    id = id.replace(/\s/g, '');

    // Get the Card
    const c = getCardById(id, cards);
    const cn = { ...c };
    cn.id += `-${Math.random()}`;

    // Add to Deck Cards
    const newDeckCards = [...deckCards];
    newDeckCards.push(cn);
    setDeckCards(newDeckCards);
  };

  return (
    <div>
      { loading && <p>Loading...</p> }
      <Header>
        <TextField label="Name" value={name} onChange={handleNameChange} />
        <TextField label="Description" value={description} onChange={handleDescriptionChange} />
        <Button onClick={handleSave}>Save</Button>
      </Header>
      <Header>
        <h2>Stats</h2>
        <p>
          cards:
          {deck.cards.length}
        </p>
        {/* Add more stats as needed */}
      </Header>
      <div className="DeckEditor__cards">
        <h2>Cards</h2>
        <ul>
          <CardIndex cards={deckCards} cardClickCallback={deckCardClickCallback} />
        </ul>
      </div>
      <div>
        <h2>All Cards</h2>
        <CardIndex cards={cards} cardClickCallback={cardClickCallback} />
      </div>
    </div>
  );
}
