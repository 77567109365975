import styled from 'styled-components';

const getSizeStyles = (size) => {
  switch (size) {
    case 'sm':
      return {
        width: '80px',
        height: '80px',
      };
    default:
      return {
        width: '120px',
        height: '120px',
      };
  }
};

const Avatar = styled.div`
  width: ${(props) => getSizeStyles(props.size).width};
  height: ${(props) => getSizeStyles(props.size).height};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // border radius on the bottom only
  border-radius: 0 0 8px 8px / 0 0 8px 8px;
`;

export default function MageAvatar({ img, size = 'md' }) {
  return (
    <Avatar
      style={{ backgroundImage: `url(${img})` }}
      size={size}
    />
  );
}
