import { toast } from 'react-toastify';
import useApi from 'api/useApi';
import { embarkBuildGame } from 'api/build';
import { useEffect } from 'react';
import { Button } from 'components/atoms';
import { useNavigate } from 'react-router-dom';

// TODO gross refactor
function makeBuildReq(graph) {
  const urlParams = new URLSearchParams(window.location.search);
  const build_id = urlParams.get('bid'); // noqa
  const user_internal_id = JSON.parse(localStorage.getItem('user')).internal_id;
  const buildReq = {
    build_id,
    user_internal_id,
    graph,
  };
  return buildReq;
}

export default function EmbarkButton({ graph, mapGraphRef }) {
  const {
    data, request: embarkBuildRequest, error, loading,
  } = useApi(embarkBuildGame);
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      if (data.category === 'BATTLE') {
        const urlParams = new URLSearchParams(window.location.search);
        const buildId = urlParams.get('bid');
        navigate(`/scene?bid=${buildId}&cpu=true`);
      } else {
        debugger;
      }
    }
  }, [data]);

  const clickEmbark = () => {
    const selected = mapGraphRef.current.getSelected();
    if (!selected) {
      toast.error('Please select a node to embark from');
      return;
    }
    if (!graph.current_node.forward.includes(selected.nodeId)) {
      toast.error('You can only embark from a node that is ahead of the current node');
      return;
    }

    // Update current node and save
    graph.current_node = graph.nodes.find((node) => node.node_id === selected.nodeId);
    const buildReq = makeBuildReq(graph);
    embarkBuildRequest(buildReq);
  };

  return (
    <Button disabled={loading} size="sm" square onClick={clickEmbark}>Embark</Button>
  );
}
