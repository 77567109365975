import { Tooltip } from 'components/atoms';
import { DeckIcon, HandIcon, DiscardIcon } from './DeckIcons';

export function DeckSigil() {
  const id = `${Math.random().toString(36).substr(2, 9)}-deck}`;
  return (
    <Tooltip id={id} text="deck" place="bottom">
      <DeckIcon />
    </Tooltip>
  );
}

export function HandSigil() {
  const id = `${Math.random().toString(36).substr(2, 9)}-hand}`;
  return (
    <Tooltip id={id} text="hand" place="bottom">
      <HandIcon />
    </Tooltip>
  );
}

export function DiscardSigil() {
  const id = `${Math.random().toString(36).substr(2, 9)}-discard}`;
  return (
    <Tooltip id={id} text="discard" place="bottom">
      <DiscardIcon />
    </Tooltip>
  );
}
