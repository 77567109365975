import { useState } from 'react';
import { Button } from 'components/atoms';
import { TargetOptionModal } from 'components/organisms';
import Section from './Section';

export default function TargetOptionModalSection() {
  const [open, setOpen] = useState(false);
  const onClick = () => setOpen(!open);

  const genMage = () => ({
    name: 'Mage',
    hp: 20,
    max_hp: 20,
    regen_hp: 0,
    mp: 10,
    max_mp: 10,
    deck: {
      deck: [],
      hand: [],
      discard_pile: [],
    },
    conditions: [{ tiny_id: '0', name: 'Thorns' }],
    owner: 'owner',
    color: 'RED',
    front_img: 'frontPensiveWizard',
    back_img: 'backSketchWarlock',
  });

  const targetOptions = {
    card: {
      name: 'Fireball',
      tiny_id: '0',
      mp_cost: 1,
      description: 'Deal 2 damage to a target.',
      img: 'fireball',
      color: 'RED',
    },
    player: 'Player 1',
    effect: {
      name: 'Burn',
    },
    options: [
      genMage(),
      genMage(),
      genMage(),
      genMage(),
      genMage(),
      genMage(),
    ],
  };

  return (
    <Section title="TargetOptionModal">
      <Button onClick={onClick}>Open TargetOption Modal</Button>
      <TargetOptionModal isOpen={open} options={targetOptions} callback={onClick} />
    </Section>
  );
}
