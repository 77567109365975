import { routeConf } from 'Config';
import { createApiRequest } from './lib';

// TODO: this works for now but we should really update it to check the stored JWT for expiration and refresh if needed
// lots of queries
const getCurrentUser = createApiRequest(routeConf.currentUserPath, 'GET');
const postSignup = createApiRequest(routeConf.signupPath, 'POST');
const postLogin = createApiRequest(routeConf.loginPath, 'POST');
const postGuestLogin = createApiRequest(routeConf.guestLoginPath, 'POST');

export {
  getCurrentUser, postSignup, postLogin, postGuestLogin,
};
