import { createContext, useState, useEffect } from 'react';

import effectIcons1 from 'icons/effectIcons1.webp';
import effectIcons2 from 'icons/effectIcons2.webp';
import effectIcons3 from 'icons/effectIcons3.webp';
import effectIcons4 from 'icons/effectIcons4.webp';
import effectIcons5 from 'icons/effectIcons5.webp';
import deck from 'icons/deck.webp';
import hand from 'icons/hand.webp';
import discard from 'icons/discard.webp';

const iconMap = {
  effectIcons1,
  effectIcons2,
  effectIcons3,
  effectIcons4,
  effectIcons5,
  deck,
  hand,
  discard,
};

export const IconCacheContext = createContext({});

export function IconCacheProvider({ children }) {
  const [iconCache, setIconCache] = useState({});

  useEffect(() => {
    Object.keys(iconMap).forEach((key) => {
      const img = new Image();
      img.onload = () => {
        setIconCache((prevCache) => ({ ...prevCache, [key]: img }));
      };
      img.src = iconMap[key];
    });
  }, []);

  return (
    <IconCacheContext.Provider value={iconCache}>
      {children}
    </IconCacheContext.Provider>
  );
}
