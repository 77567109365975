import styled from 'styled-components';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import { EntityTable } from 'components/organisms';
import { getDecks } from 'api/deck';
import useApi from 'api/useApi';
import { Root } from 'components/templates';

const IndexDiv = styled.div`
  margin: 20px;
`;

export default function DeckIndex() {
  const {
    loading, error, data, request,
  } = useApi(getDecks);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      request();
    } catch (err) {
      toast.error(err);
    }
  }, [request]);

  const cols = ['name', 'description', 'created_at'];
  const newHandler = () => {
    navigate('/decks/new');
  };
  const editHandler = (d) => {
    navigate(`/decks/${d.deck_id}`);
  };

  return (
    <Root>
      <IndexDiv>
        <h1>Decks</h1>
        <EntityTable
          data={data}
          loading={loading}
          error={error}
          columns={cols}
          newHandler={newHandler}
          editHandler={editHandler}
        />
      </IndexDiv>
    </Root>
  );
}
