import Titled from './Titled';
import Root from './Root';

export default function RootTitled({ children }) {
  return (
    <Root>
      <Titled>
        {children}
      </Titled>
    </Root>
  );
}
