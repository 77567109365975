import {
  forwardRef, useImperativeHandle, useState, useEffect,
} from 'react';
import styled, { keyframes } from 'styled-components';

import { StatText } from 'components/atoms';

const getSizeStyles = (size) => {
  switch (size) {
    case 'sm':
      return {
        height: '14px',
        fontSize: '0.9em',
        boxShadow: '-2px -2px 4px #d1d1d1, 2px 2px 4px #ffffff',
      };
    case 'lg':
      return {
        height: '30px',
        fontSize: '1.3em',
        boxShadow: '-10px -10px 20px #d1d1d1, 10px 10px 20px #ffffff',
      };
    case 'md':
    default:
      return {
        height: '20px',
        fontSize: '1.1em',
        boxShadow: '-5px -5px 10px #d1d1d1, 5px 5px 10px #ffffff',
      };
  }
};

const Out = styled.div`
  width: -webkit-fill-available;
  height: ${(props) => getSizeStyles(props.size).height};
  background: #e0e0e0;
  border-radius: 10px;
  box-shadow:  ${(props) => getSizeStyles(props.size).boxShadow};
  margin-top: 5px;
  margin-bottom: 5px;
  overflow: hidden;
`;

const In = styled.div`
  transition: width 2s ease-out;
  background: ${(props) => `linear-gradient(to right, ${props.color} ${props.gPercent}%, ${props.regenColor} 0%)`};
  width: ${(props) => props.width};
  height: 100%;
  font-size: ${(props) => getSizeStyles(props.size).fontSize};
  line-height: ${(props) => getSizeStyles(props.size).height};
  box-shadow: inset -2px -2px 3px #d1d1d1, inset 2px 2px 3px #ffffff;
  position: relative;
`;

const Text = styled.span`
  position: relative;
  margin-left: 0.5em;
  margin-right: 0.5em;
  top: 0.15em;
  z-index: 0;
  color: #676767;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.1);
  font-weight: 700;
  font-size: ${(props) => getSizeStyles(props.size).fontSize};
`;

const Overlay = styled.div`
  background: ${(props) => `linear-gradient(to right, ${props.color} 0%, transparent 100%)`};
  width: ${(props) => props.width};
  height: 100%;
  position: absolute;
  left: 0;
  border: solid;
  border-color: #d5edd7;
  box-shadow: inset -2px -2px 3px #d1d1d1, inset 2px 2px 3px #ffffff;
`;

const ValBarContainer = styled.div`
  position: relative;
`;

const driftRight = keyframes`
  0% { transform: translate(-10px, 0px); opacity: 1; }
  100% { transform: translate(0px, -10px); opacity: 0; }
`;

const driftLeft = keyframes`
  0% { transform: translate(10px, 0px); opacity: 1; }
  100% { transform: translate(0px, -10px); opacity: 0; }
`;

const DamageSplash = styled.div`
  position: absolute;
  ${(props) => (props.position === 'left' ? `left: -${props.charlen}em;` : `right: -${props.charlen}em;`)}
  color: black;
  animation: ${(props) => (props.position === 'left' ? driftLeft : driftRight)}  1.5s ease-out;
  font-size: 2em;
`;

const Bar = forwardRef(({
  initVal, max, regen, variant = 'hp', size = 'md', splash = 'right',
}, ref) => {
  const [val, setVal] = useState(initVal);
  const [showDamage, setShowDamage] = useState(false);
  const [damageAmount, setDamageAmount] = useState(0);

  // Stuff copied
  const text = `${val}/${max}`;
  let percent = (val / max) * 100;
  const rPercent = (regen / max) * 100;
  const gPercent = (val / (val + regen)) * 100;
  const width = `${percent + rPercent}%`;

  // TODO these should be part of themed context
  let overlayColor = '#77DD77'; // pastel green
  let color = '#57d961'; // slightly darker green
  const regenColor = '#fdfd96'; // pastel yellow
  if (variant === 'mp') {
    overlayColor = '#4bb1eb'; // pastel blue
    color = '#74a4f2'; // slightly darker blue
  }

  let overlayWidth = '0%';
  if (val > max) {
    overlayWidth = `${percent - 100}%`;
    percent = 100;
  }
  // end stuff copied

  useImperativeHandle(ref, () => ({
    addValue(amount) {
      setShowDamage(true);
      setDamageAmount(amount);
      setVal((prevVal) => Math.max(0, prevVal + amount));
    },
  }));

  useEffect(() => {
    let timeout;
    if (showDamage) {
      timeout = setTimeout(() => {
        setShowDamage(false);
      }, 1000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [showDamage]);

  useEffect(() => {
    setVal(initVal);
  }, [initVal]);

  return (
    <ValBarContainer>
      {showDamage && (
        <DamageSplash charlen={String(damageAmount).length + 2} position={splash}>
          <StatText value={damageAmount} suffix={variant} />
        </DamageSplash>
      )}
      <Out>
        <In width={width} gPercent={gPercent} color={color} regenColor={regenColor} size={size}>
          { val > max && <Overlay width={overlayWidth} color={overlayColor} /> }
          <Text size={size}>{text}</Text>
        </In>
      </Out>
    </ValBarContainer>
  );
});

export default Bar;
