import styled from 'styled-components';
import { memo } from 'react';
import { DeckSigil, HandSigil, DiscardSigil } from './DeckSigils';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
`;

const Detail = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

function DeckDetails({ deck, width = '50%' }) {
  const deckLength = `${deck.deck.length}`;
  const handLength = `${deck.hand.length}`;
  const discardLength = `${deck.discard_pile.length}`;

  return (
    <Container style={{ width }}>
      <Detail>
        <DeckSigil />
        {deckLength}
      </Detail>
      <Detail>
        <HandSigil />
        {handLength}
      </Detail>
      <Detail>
        <DiscardSigil />
        {discardLength}
      </Detail>
    </Container>
  );
}

export default memo(DeckDetails);
