/*
 * Three.js scene for the 'build' game mode map
 */
import { useEffect, useRef } from 'react';
import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import useApi from 'api/useApi';
import { updateBuildGame } from 'api/build';
import { toast } from 'react-toastify';

import styled from 'styled-components';
import MapGraph from './MapGraph';
import MapNode from './MapNode';
import AbandonButton from './AbandonButton';
import EmbarkButton from './EmbarkButton';
import setupEventListeners from './EventListeners';

import { updateNodePositions, iterationsTillSettle } from './forceDirectedGraph';
import {
  constructSky, constructFloor, constructLight, constructPlateau, constructRenderer, constructOrbitControls,
} from './construction';

// float the button to the bottom right corner
const ButtonContainer = styled.div`
  background: white;
  border-radius: 1em;
  padding: 0.5em;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1rem;
  display: flex;
  flex-direction: column;
`;

function updateCubesPosition(cubes) {
  cubes.forEach((cube) => {
    const { node } = cube.userData;
    if (node) {
      cube.position.x = node.position.x;
      cube.position.y = node.position.y;
      cube.position.z = node.position.z;
    }
  });
}

function createTextTexture(text, color) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = 'Bold 30px Arial';
  context.fillStyle = color;
  context.fillText(text, 50, 50);

  const texture = new THREE.Texture(canvas);
  texture.needsUpdate = true;

  return texture;
}

function createTextSprite(text, color) {
  const texture = createTextTexture(text, color);
  const material = new THREE.SpriteMaterial({ map: texture });
  const sprite = new THREE.Sprite(material);
  sprite.scale.set(1, 1, 1);
  return sprite;
}

const modelCache = {};
const gltfLoader = new GLTFLoader();

const loadModel = (path, onLoad) => {
  if (modelCache[path]) {
    onLoad(modelCache[path].clone());
  } else {
    gltfLoader.load(path, (gltf) => {
      modelCache[path] = gltf.scene;
      onLoad(gltf.scene.clone());
    }, undefined, (error) => {
      toast.error(`Failed to load model from path:'${path}', ${error}`);
    });
  }
};

function makeCube(node, currentNode) {
  const color = 0xeee000;
  node.color = color;

  const size = 0.001;
  const geometry = new THREE.BoxGeometry(size, size, size);
  const material = new THREE.MeshStandardMaterial({ color });
  const cube = new THREE.Mesh(geometry, material);
  cube.position.x = node.position.x;
  cube.position.y = node.position.y;
  cube.position.z = node.position.z;

  cube.userData.node = node;

  const text = `${node.category}`;
  let textColor = 'rgba(255, 255, 255, 1)';
  if (currentNode.forward.includes(node.nodeId)) {
    textColor = 'rgba(0, 255, 0, 1)';
  }
  const textSprite = createTextSprite(text, textColor);
  let y = 0.3;
  if (node.category === 'BOSS') {
    y = 0.9;
  }
  textSprite.position.set(0.2, y, 0);
  cube.userData.textSprite = textSprite;
  cube.add(textSprite);

  if (node.category === 'REST' || node.category === 'HOME') {
    loadModel('/glb/Campfire.glb', (scene) => {
      scene.position.set(0, 0, 0);
      const scale = 0.05;
      scene.scale.set(scale, scale, scale);
      scene.traverse((object) => {
        if (object.isMesh) {
          object.castShadow = true;
        }
      });
      cube.add(scene);
    });
  }

  if (node.category === 'CARD') {
    loadModel('/glb/Chest.glb', (scene) => {
      scene.position.set(0, -0.1, 0);
      const scale = 0.005;
      scene.scale.set(scale, scale, scale);
      scene.traverse((object) => {
        if (object.isMesh) {
          object.castShadow = true;
        }
      });
      cube.add(scene);
    });
  }

  if (node.category === 'BATTLE') {
    loadModel('/glb/Battle.glb', (scene) => {
      scene.position.set(0, -0.1, 0);
      const scale = 0.003;
      scene.scale.set(scale, scale, scale);
      scene.traverse((object) => {
        if (object.isMesh) {
          object.castShadow = true;
        }
      });
      cube.add(scene);
    });
  }

  if (node.category === 'MAGE') {
    loadModel('/glb/Mage.glb', (scene) => {
      scene.position.set(0, 0.1, 0);
      const scale = 0.13;
      scene.scale.set(scale, scale, scale);
      scene.rotation.y = -1 * Math.PI / 2;
      scene.traverse((object) => {
        if (object.isMesh) {
          object.castShadow = true;
        }
      });
      cube.add(scene);
    });
  }

  if (node.category === 'BOSS') {
    loadModel('/glb/BOSS.glb', (scene) => {
      scene.position.set(0, 0.5, 0);
      const scale = 0.5;
      scene.scale.set(scale, scale, scale);
      scene.rotation.y = -1 * Math.PI;
      scene.traverse((object) => {
        if (object.isMesh) {
          object.castShadow = true;
        }
      });
      cube.add(scene);
    });
  }

  if (node.category === 'DELETE') {
    loadModel('/glb/Delete.glb', (scene) => {
      scene.position.set(0, 0.1, 0);
      const scale = 0.2;
      scene.scale.set(scale, scale, scale);
      scene.traverse((object) => {
        if (object.isMesh) {
          object.castShadow = true;
        }
      });
      cube.add(scene);
    });
  }

  if (node.isCurrent) {
    loadModel('/glb/Arrow.glb', (scene) => {
      scene.position.set(0, 1.5, 0);
      const scale = 0.2;
      scene.scale.set(scale, scale, scale);
      cube.add(scene);
    });
  }

  return cube;
}

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

function drawLine(scene, start, end) {
  const color = 0xffffff;
  const material = new THREE.LineBasicMaterial({ color });
  const points = [];

  points.push(new THREE.Vector3(start.position.x, start.position.y, start.position.z));
  points.push(new THREE.Vector3(end.position.x, end.position.y, end.position.z));

  const geometry = new THREE.BufferGeometry().setFromPoints(points);
  const line = new THREE.Line(geometry, material);
  line.userData.start = start;
  line.userData.end = end;
  scene.add(line);
  return line;
}

function updateLines(lines) {
  lines.forEach((line) => {
    const { start } = line.userData;
    const { end } = line.userData;

    const points = [];
    points.push(new THREE.Vector3(start.position.x, start.position.y, start.position.z));
    points.push(new THREE.Vector3(end.position.x, end.position.y, end.position.z));
    const geometry = new THREE.BufferGeometry().setFromPoints(points);

    line.geometry.dispose();
    line.geometry = geometry;
  });
}

// Function to generate tree positions with a focus on outer areas and smaller scales
function generateTreePositions(radius, count) {
  const positions = [];
  for (let i = 0; i < count; i += 1) {
    // Generate Gaussian-distributed distances
    const u = Math.random();
    const v = Math.random();
    const gaussianDistance = Math.sqrt(-2.0 * Math.log(u)) * Math.cos(2.0 * Math.PI * v);
    const distance = radius * Math.abs(gaussianDistance / 3); // Use absolute value to focus on outer ring

    const angle = Math.random() * Math.PI * 2; // Random angle
    const x = Math.cos(angle) * distance;
    const z = Math.sin(angle) * distance;
    const scale = 0.9 + Math.random() * 0.5; // Smaller scale between 0.5 and 1.0

    // Only add trees that are within the radius limit and away from the very center
    if (Math.abs(distance) <= radius && Math.abs(distance) > radius / 3) {
      positions.push({
        x, y: -2.0, z, scale,
      });
    }
  }
  return positions;
}

function focusOnCurrentNode(scene, renderer, camera, orbitControls, currentNode) {
  const targetPosition = new THREE.Vector3();
  targetPosition.copy(currentNode.position);
  const distance = camera.position.distanceTo(orbitControls.target);
  const direction = new THREE.Vector3().subVectors(camera.position, orbitControls.target).normalize();
  const newPosition = new THREE.Vector3().addVectors(targetPosition, direction.multiplyScalar(distance));
  const targetTween = new TWEEN.Tween(orbitControls.target)
    .to({ x: targetPosition.x, y: targetPosition.y, z: targetPosition.z }, 500)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => orbitControls.update());
  const cameraTween = new TWEEN.Tween(camera.position)
    .to({ x: newPosition.x, y: newPosition.y, z: newPosition.z }, 500)
    .easing(TWEEN.Easing.Quadratic.InOut)
    .onUpdate(() => renderer.render(scene, camera));
  targetTween.start();
  cameraTween.start();
}

export default function MapScene({ graph }) {
  const mountRef = useRef(null);
  const mapGraphRef = useRef(null);

  const { request: updateBuildRequest, error } = useApi(updateBuildGame);

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    mapGraphRef.current = new MapGraph();

    // enrich MapGraph from the given graph
    graph.nodes.forEach((node) => {
      const n = new MapNode(node.node_id, node.category, node.depth);
      // if we already settled
      if (node.data.position) {
        n.position = node.data.position;
        mapGraphRef.current.settled = true;
      } else {
        const min = -1;
        const max = 1;
        let x = 0;
        if (node.category !== 'BOSS' && node.category !== 'HOME') {
          x = getRandomArbitrary(min, max);
        }
        // needs to be 0.1 less than plateauHeight
        const y = -0.4;
        const z = (-2 * node.depth) + 5; // 5 because max depth is 20
        n.position = { x, y, z };
      }
      if (node.node_id === graph.current_node.node_id) {
        n.isCurrent = true;
      }
      mapGraphRef.current.addNode(n);
      node.forward.forEach((targetId) => {
        mapGraphRef.current.addEdge(node.node_id, targetId);
      });
    });

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.z = 10;
    camera.position.y = 5;

    const renderer = constructRenderer();
    mountRef.current.appendChild(renderer.domElement);

    // Set up orbit controls for mouse drag
    const orbitControls = constructOrbitControls(camera, renderer);

    const sky = constructSky();
    scene.add(sky);

    // Add Directional Light with shadows
    const light = constructLight();
    scene.add(light);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.9);
    scene.add(ambientLight);

    // Add the floor to the scene
    const floor = constructFloor();
    scene.add(floor);

    // Add plateau
    const circleFloor = constructPlateau();
    scene.add(circleFloor);

    // Add fog
    scene.fog = new THREE.FogExp2(0x9dbcd4, 0.005);

    // Add trees
    loadModel('/glb/Spruce.glb', (spruceScene) => {
      const positionsAndScales = generateTreePositions(150, 1000);
      positionsAndScales.forEach((item) => {
        const treeClone = spruceScene.clone();
        treeClone.position.set(item.x, item.y, item.z);
        treeClone.scale.set(item.scale, item.scale, item.scale); // Scale uniformly
        treeClone.traverse((object) => {
          if (object.isMesh) {
            object.castShadow = true;
          }
        });
        scene.add(treeClone);
      });
    });

    const cubes = [];
    const lines = [];

    mapGraphRef.current.nodes.forEach((node) => {
      const cube = makeCube(node, graph.current_node);
      cubes.push(cube);
      scene.add(cube);
    });

    mapGraphRef.current.edges.forEach(({ sourceId, targetId }) => {
      const start = mapGraphRef.current.getNode(sourceId);
      const end = mapGraphRef.current.getNode(targetId);
      const line = drawLine(scene, start, end);
      lines.push(line);
    });

    // focus on current node
    const cNode = mapGraphRef.current.getNode(graph.current_node.node_id);
    focusOnCurrentNode(scene, renderer, camera, orbitControls, cNode);

    setupEventListeners(
      renderer,
      scene,
      camera,
      orbitControls,
    );

    let iterations = 0;
    const animate = () => {
      requestAnimationFrame(animate);

      if (!mapGraphRef.current.settled) {
        if (iterations < iterationsTillSettle) {
          updateNodePositions(mapGraphRef.current.nodes, mapGraphRef.current.edges);
          iterations += 1;
        } else if (iterations === iterationsTillSettle) {
          // TODO update current_node position as well?
          // for each node in graph.nodes set the corresponding mapGraph.node position into the data field then update
          graph.nodes.forEach((node) => {
            const n = mapGraphRef.current.getNode(node.node_id);
            if (n) {
              const pos = { position: n.position };
              node.data = { ...node.data, ...pos };
            }
          });
          // TODO gross refactor
          const urlParams = new URLSearchParams(window.location.search);
          const build_id = urlParams.get('bid');
          const user_internal_id = JSON.parse(localStorage.getItem('user')).internal_id;
          const buildReq = {
            build_id,
            user_internal_id,
            graph,
          };
          updateBuildRequest(buildReq);
          iterations += 1;
        }
      }

      TWEEN.update();
      updateLines(lines);
      updateCubesPosition(cubes);
      orbitControls.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      mapGraphRef.current = new MapGraph();
      orbitControls.dispose();
      cubes.forEach((cube) => {
        scene.remove(cube);
      });
      if (mountRef.current && renderer.domElement.parentNode) {
        mountRef.current.removeChild(renderer.domElement);
      }
    };
  }, []);

  return (
    <div>
      <div ref={mountRef} />
      <ButtonContainer>
        <AbandonButton graph={graph} />
        <EmbarkButton graph={graph} mapGraphRef={mapGraphRef} />
      </ButtonContainer>
    </div>
  );
}
