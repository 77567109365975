import styled, { ThemeContext } from 'styled-components';
import { useContext } from 'react';

import Section from './Section';

const ColorBox = styled.div`
  width: 100px;
  height: 100px;
  margin: 5px;
  border: solid 1px;
`;

const ColorName = styled.p`
  margin-top: 5px;
  font-size: 14px;
`;

function ThemeColor({ name, color }) {
  const val = `${name} ${color}`;
  return (
    <div>
      <ColorBox style={{ backgroundColor: color }} />
      <ColorName>{val}</ColorName>
    </div>
  );
}

const StyledThemeColors = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

function ThemeColors() {
  const {
    primaryColor, secondaryColor, dangerColor, blueText, buttonText, backgroundColor,
  } = useContext(ThemeContext);

  return (
    <StyledThemeColors>
      <ThemeColor name="primaryColor" color={primaryColor} />
      <ThemeColor name="secondaryColor" color={secondaryColor} />
      <ThemeColor name="dangerColor" color={dangerColor} />
      <ThemeColor name="blueText" color={blueText} />
      <ThemeColor name="buttonText" color={buttonText} />
      <ThemeColor name="backgroundColor" color={backgroundColor} />
    </StyledThemeColors>
  );
}

export default function ColorSection() {
  return (
    <Section title="Color">
      <ThemeColors />
    </Section>
  );
}
