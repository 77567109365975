// TODO: perhaps a molecule?
import { Button, TextField } from 'components/atoms';
import { Table as NeuTable } from 'ui-neumorphism';
import styled, { ThemeContext } from 'styled-components';
import { useContext, useState, useEffect } from 'react';
import Fuse from 'fuse.js';

const StyledHeader = styled.div`
  display: flex;
`;

const StyledNewButton = styled.div`
  margin-top: -5px;
`;

const TextContainer = styled.div`
  flex-grow: 1;
`;

function EntityTableHeader({ newHandler, children, onChange }) {
  return (
    <div>
      <StyledHeader>
        <TextContainer>
          <TextField label="Search" onChange={onChange} />
        </TextContainer>
        { newHandler && <StyledNewButton><Button onClick={newHandler}>New</Button></StyledNewButton>}
      </StyledHeader>
      {children}
    </div>
  );
}

const StyledTableHeader = styled.div`
  font-weight: bold;
  font-size: 12px;
`;

const getHeaders = (columns) => [...columns].map((col) => {
  const val = <StyledTableHeader>{col}</StyledTableHeader>;
  let align = 'left';
  if (col === 'edit') {
    align = 'right';
  }
  return {
    text: val, align, value: col,
  };
});

function EditButton({ info, editHandler }) {
  return (
    <i
      className="fa fa-edit"
      style={{ cursor: 'pointer', fontSize: '12px' }}
      onClick={() => editHandler(info)}
    />
  );
}

const StyledEditableRow = styled.div`
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: ${({ theme }) => theme.secondaryColor};
    }
`;

const getJsxItem = (row, editHandler) => {
  const item = {};
  Object.entries(row).forEach((v) => {
    if (editHandler) {
      item[v[0]] = <StyledEditableRow onClick={() => editHandler(row)}>{v[1]}</StyledEditableRow>;
    } else {
      item[v[0]] = <div>{v[1]}</div>;
    }
  });
  if (editHandler) {
    item.edit = <EditButton info={row} editHandler={editHandler} />;
  }
  return item;
};

const getJsxItems = (data, editHandler) => [...data].map((row) => getJsxItem(row, editHandler));

// This will be for total table styles
const StyledEntityTable = styled.div`
  margin: 5px;
`;

const NonState = styled.div`
  text-align: center;
  padding: 20px;
`;

const TableContainer = styled.div`
  margin: 12px;
`;

export default function EntityTable({
  data, loading, error, columns, newHandler, editHandler,
}) {
  const { backgroundColor, fontFamily } = useContext(ThemeContext);

  const [searchResult, setSearchResult] = useState(data);
  const options = { keys: Object.keys(data && data.length > 0 ? data[0] : {}) };
  const fuse = new Fuse(data || [], options);

  useEffect(() => {
    setSearchResult(data);
  }, [data]);

  const onSearchChange = (e) => {
    const result = fuse.search(e.value);
    const vals = result.map((r) => r.item);
    if (e.value !== '') {
      setSearchResult(vals);
    } else {
      setSearchResult(data);
    }
  };

  if (!columns) {
    return <NonState>No Columns</NonState>;
  }

  let cols = [...columns];
  if (editHandler) {
    cols = [...columns, 'edit'];
  }

  const headers = getHeaders(cols);

  let body = <NonState>Loading...</NonState>;
  if (!loading && data) {
    let jsxItems = [];
    if (Array.isArray(searchResult)) {
      jsxItems = getJsxItems(searchResult, editHandler);
    }
    body = (
      <NeuTable
        headers={headers}
        items={jsxItems}
        style={{ backgroundColor, fontFamily }}
        inset
      />
    );
  } else if (error) {
    body = (
      <NonState>{error}</NonState>
    );
  }

  return (
    <StyledEntityTable>
      <EntityTableHeader newHandler={newHandler} onChange={onSearchChange}>
        <TableContainer>
          {body}
        </TableContainer>
      </EntityTableHeader>
    </StyledEntityTable>
  );
}
