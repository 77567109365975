import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Root, Content } from 'components/templates';
import { getDeck, updateDeck } from 'api/deck';
import useApi from 'api/useApi';

import DeckEditor from './DeckEditor';

export default function DeckEdit() {
  const [deck, setDeck] = useState(null);
  const { id } = useParams();

  const {
    data: deckData, request: getDeckRequest, error: getDeckError, loading: getDeckLoading,
  } = useApi(getDeck);

  useEffect(() => {
    getDeckRequest(id);
  }, [id]);

  useEffect(() => {
    if (deckData) {
      setDeck(deckData);
    }
  }, [deckData]);

  useEffect(() => {
    if (getDeckError) {
      toast.error(getDeckError.message);
    }
  }, [getDeckError]);

  const {
    loading: updateDeckLoading, data: updatedDeckData, request: updateDeckRequest, error: updateDeckError,
  } = useApi(updateDeck);

  useEffect(() => {
    if (updateDeckError) {
      toast.error(updateDeckError);
    }
  }, [updateDeckError]);

  useEffect(() => {
    if (updatedDeckData) {
      setDeck(updatedDeckData);
    }
  }, [updatedDeckData]);

  const handleSaveDeck = (updatedDeck) => {
    // Make a PUT request to the server to update the deck
    updateDeckRequest(updatedDeck);
  };

  return (
    <Root>
      <Content>
        <h1>Edit Deck</h1>
        { getDeckLoading && <div>Loading deck...</div> }
        { getDeckError && <div>Failed to load deck</div> }
        { deck
          && <DeckEditor deck={deck} onSave={handleSaveDeck} loading={updateDeckLoading} />}
      </Content>
    </Root>
  );
}
