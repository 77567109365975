import * as THREE from 'three';
import * as TWEEN from '@tweenjs/tween.js';

export default function setupEventListeners(renderer, scene, camera, orbitControls) {
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  let lastSelected = null;

  function onMouseClick(event) {
    // Calculate mouse position in normalized device coordinates (-1 to +1) for both components
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    // Update the picking ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera);

    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(scene.children, true);

    for (let i = 0; i < intersects.length; i += 1) {
      let { object } = intersects[i];
      while (object.parent && !object.userData.node) {
        object = object.parent;
      }

      if (object.userData.node && orbitControls) {
        object.material.color.set(0xff0000);
        object.userData.node.isSelected = true;
        if (lastSelected) {
          lastSelected.userData.node.isSelected = false;
        }
        lastSelected = object;

        const targetPosition = new THREE.Vector3();
        targetPosition.copy(object.position);

        const distance = camera.position.distanceTo(orbitControls.target);
        const direction = new THREE.Vector3().subVectors(camera.position, orbitControls.target).normalize();
        const newPosition = new THREE.Vector3().addVectors(targetPosition, direction.multiplyScalar(distance));

        const targetTween = new TWEEN.Tween(orbitControls.target)
          .to({ x: targetPosition.x, y: targetPosition.y, z: targetPosition.z }, 500)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(() => orbitControls.update());

        const cameraTween = new TWEEN.Tween(camera.position)
          .to({ x: newPosition.x, y: newPosition.y, z: newPosition.z }, 500)
          .easing(TWEEN.Easing.Quadratic.InOut)
          .onUpdate(() => renderer.render(scene, camera));

        targetTween.start();
        cameraTween.start();

        break;
      }
    }
  }

  renderer.domElement.addEventListener('click', onMouseClick);

  let currentIntersected = null; // Store the currently intersected object

  function onMouseMove(event) {
    // Calculate mouse position in normalized device coordinates (-1 to +1) for both components
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    // Update the ray with the camera and mouse position
    raycaster.setFromCamera(mouse, camera);

    // Calculate objects intersecting the picking ray
    const intersects = raycaster.intersectObjects(scene.children, true);

    if (intersects.length === 0) {
      if (currentIntersected) {
        currentIntersected.material.color.set(currentIntersected.userData.node.color);
        currentIntersected = null;
      }
    }

    let shouldUnset = true;
    for (let i = 0; i < intersects.length; i += 1) {
      let { object } = intersects[i];
      while (object.parent && !object.userData.node) {
        object = object.parent;
      }
      // if this object is not a node
      if (!object.userData.node) {
        continue;
      }
      // if we are already intersecting this object
      if (currentIntersected && currentIntersected.userData.node.nodeId === object.userData.node.nodeId) {
        shouldUnset = false;
        break;
      }

      // if there is an object reset and set
      if (currentIntersected) {
        currentIntersected.material.color.set(currentIntersected.userData.node.color);
        currentIntersected.userData.textSprite.material.color.set(currentIntersected.userData.node.textColor);

        object.userData.textSprite.material.color.set(0xFF00FF);
        object.material.color.set(0xFF00FF);
        currentIntersected = object;
        shouldUnset = false;
        break;
      }

      // if there isnt an object just set it
      object.userData.textSprite.material.color.set(0xFF00FF);
      object.material.color.set(0xFF00FF);
      currentIntersected = object;
      shouldUnset = false;
    }

    // if we should leave mouseover do so
    if (shouldUnset && currentIntersected) {
      currentIntersected.material.color.set(currentIntersected.userData.node.color);
      currentIntersected.userData.textSprite.material.color.set(currentIntersected.userData.node.textColor);
      currentIntersected = null;
    }
  }

  renderer.domElement.addEventListener('mousemove', onMouseMove);
}
