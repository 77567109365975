import { routeConf } from '../Config';
import { createApiRequest, getRequestOptions, makeRequest } from './lib';

const getDecks = createApiRequest(routeConf.deckPath, 'GET');
const createDeck = createApiRequest(routeConf.deckPath, 'POST');

const getDeck = async (deckId) => {
  const requestOptions = getRequestOptions('GET');
  const path = `${routeConf.deckPath}/${deckId}`;
  const data = await makeRequest(path, requestOptions);
  return data;
};

const updateDeck = async (deck) => {
  const requestOptions = getRequestOptions('PUT', deck);
  const path = `${routeConf.deckPath}/${deck.deck_id}`;
  const data = await makeRequest(path, requestOptions);
  return data;
};

export {
  getDecks, getDeck, createDeck, updateDeck,
};
