import { routeConf } from 'Config';
import { createApiRequest, getRequestOptions, makeRequest } from './lib';

const getInProgress = createApiRequest(routeConf.buildPath, 'GET');
const createBuildGame = createApiRequest(routeConf.buildPath, 'POST');
const updateBuildGame = createApiRequest(routeConf.buildPath, 'PUT');
const embarkBuildGame = createApiRequest(routeConf.buildEmbarkPath, 'POST');

const chooseStarter = async (buildId, color) => {
  const path = `${routeConf.buildPath}/${buildId}/starter/${color}`;
  const requestOptions = getRequestOptions('POST', {});
  const data = await makeRequest(path, requestOptions);
  return data;
};

export {
  getInProgress, createBuildGame, updateBuildGame, chooseStarter, embarkBuildGame,
};
