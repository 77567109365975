import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';

import Scene from 'pages/scene/Scene';

import Join from 'pages/join/Join';

import FetchedCardIndex from 'pages/cards/FetchedCardIndex';

import DeckIndex from 'pages/deck/DeckIndex';
import DeckNew from 'pages/deck/DeckNew';
import DeckEdit from 'pages/deck/DeckEdit';

import TeamIndex from 'pages/team/TeamIndex';
import TeamNew from 'pages/team/TeamNew';
import TeamEdit from 'pages/team/TeamEdit';
import Components from 'pages/components/Components';

import Signup from 'pages/signup/Signup';
import Home from 'pages/home/Home';
import Team from 'pages/play/Team';
import Quick from 'pages/play/Quick';
import Play from 'pages/play/Play';
import Build from 'pages/play/Build';
import Menu from 'pages/menu/Menu';
import BuildMap from 'pages/build/BuildMap';
import NewBuild from 'pages/build/New';

// import reportWebVitals from './reportWebVitals';

import { ImageCacheProvider } from 'contexts/ImageCache';
import { IconCacheProvider } from 'contexts/IconCache';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ImageCacheProvider>
    <IconCacheProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/play" element={<Play />} />
          <Route path="/play/quick" element={<Quick />} />
          <Route path="/play/team" element={<Team />} />
          <Route path="/play/build" element={<Build />} />
          <Route path="/build/map" element={<BuildMap />} />
          <Route path="/build/new" element={<NewBuild />} />
          <Route path="/scene" element={<Scene />} />
          <Route path="/join" element={<Join />} />
          <Route path="/cards" element={<FetchedCardIndex />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/decks" element={<DeckIndex />} />
          <Route path="/decks/new" element={<DeckNew />} />
          <Route path="/decks/:id" element={<DeckEdit />} />
          <Route path="/teams" element={<TeamIndex />} />
          <Route path="/teams/new" element={<TeamNew />} />
          <Route path="/teams/:id" element={<TeamEdit />} />
          <Route path="/components" element={<Components />} />
        </Routes>
      </BrowserRouter>
    </IconCacheProvider>
  </ImageCacheProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
