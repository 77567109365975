import styled, { ThemeContext } from 'styled-components';
import { useContext } from 'react';

const ValSpan = styled.span`
  font-size: 1.2em;
  color: ${({ color }) => color};
`;

const SuffixSpan = styled.span`
  font-size: 0.8em;
  color: ${({ color }) => color};
`;

export default function StatText({ value, suffix }) {
  const { statColors } = useContext(ThemeContext);

  let dmgStr;
  if (value > 0) {
    dmgStr = `+${value}`;
  } else {
    dmgStr = `${value}`;
  }

  let color;
  if (suffix === 'mp') {
    color = statColors.mpText;
  } else if (value >= 0) {
    color = statColors.plusHpText;
  } else {
    color = statColors.minusHpText;
  }

  return (
    <div>
      <ValSpan color={color}>{dmgStr}</ValSpan>
      <SuffixSpan color={color}>{suffix}</SuffixSpan>
    </div>
  );
}
