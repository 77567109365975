import { IconSection } from 'components/atoms';

export function DeckIcon() {
  return <IconSection filename="deck" width={150} height={150} xOffset={0} yOffset={0} scaleFactor={0.1} />;
}

export function HandIcon() {
  return <IconSection filename="hand" width={150} height={150} xOffset={0} yOffset={0} scaleFactor={0.1} />;
}

export function DiscardIcon() {
  return <IconSection filename="discard" width={150} height={150} xOffset={0} yOffset={0} scaleFactor={0.1} />;
}

export function LargeDiscardIcon() {
  return <IconSection filename="discard" width={150} height={150} xOffset={0} yOffset={0} scaleFactor={0.15} />;
}
