import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { getTeams } from 'api/team';
import useApi from 'api/useApi';
import EntityTable from './EntityTable';

export default function TeamChooser({ choiceCallback }) {
  // Fetch Teams
  const {
    loading, error, data, request,
  } = useApi(getTeams);

  useEffect(() => {
    try {
      request();
    } catch (err) {
      toast.error(err);
    }
  }, [request]);

  const cols = ['name', 'created_at'];

  return (
    <div>
      <div>Choose Team</div>
      <EntityTable
        data={data}
        loading={loading}
        error={error}
        columns={cols}
        editHandler={choiceCallback}
      />
    </div>
  );
}
