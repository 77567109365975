import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import styled, { keyframes } from 'styled-components';
import { Button } from 'components/atoms';
import { Card } from 'components/molecules';
import { useAnimation } from 'contexts/AnimationContext';
import { CardData } from '../../CardData';

import './DescriptorsModal.css';

const DescriptorContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1em;
  padding-right: 1em;
`;

function LineDescriptor({ descriptor }) {
  const { lines } = descriptor;

  return (
    <DescriptorContainer>
      {lines.map((obj) => (
        <div key={obj.id}>{obj.line}</div>
      ))}
    </DescriptorContainer>
  );
}

const ResolutionTimeOnScreen = 4000;
const ResolutionTimeTillDisappear = 3000;

const slideInLeft = keyframes`
  from {
    transform: translate(-100vw, 50vh);
  }
  to {
    transform: translate(0, 0);
  }
`;

const slideInRight = keyframes`
  from {
    transform: translate(100vw, -50vh);
  }
  to {
    transform: translate(0, 0);
  }
`;

const disappear = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const Box = styled.div`
  width: 142px;
  height: 200px;
  margin: 10px;
  animation: ${(props) => props.animation} 300ms cubic-bezier(0.4, 0, 0.2, 1)  forwards;
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 3;
`;

function SlideBox({ initAnimation, cardData }) {
  const [animation, setAnimation] = useState(initAnimation);
  const [key, setKey] = useState(0);

  const dissapearAfter = ResolutionTimeTillDisappear;
  const resetAfter = ResolutionTimeOnScreen;

  useEffect(() => {
    const disappearTimer = setTimeout(() => {
      setAnimation(disappear);
    }, dissapearAfter);

    const resetTimer = setTimeout(() => {
      setAnimation(initAnimation);
      setKey((prevKey) => prevKey + 1);
    }, resetAfter);

    return () => {
      clearTimeout(disappearTimer);
      clearTimeout(resetTimer);
    };
  }, [key]);

  return (
    <Box animation={animation} key={key}>
      <Card card={cardData} />
    </Box>

  );
}

const ResolutionLineContainers = styled.div`
  height: auto;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  background-color: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  font-size: 1em;
  position: absolute;
  bottom: 2vh;
  left: 2vw;
  right: 30vw;
  z-index: 2;
`;

function LinesBox({ lines }) {
  return (
    <ResolutionLineContainers>
      {lines.map((obj) => (
        <div key={obj.id}>{obj.line}</div>
      ))}
    </ResolutionLineContainers>
  );
}

function CardResolutionDescriptor2({ descriptor }) {
  const { lines } = descriptor;

  const p1Card = descriptor.p1_card;
  const p2Card = descriptor.p2_card;
  const p1CardData = new CardData(
    p1Card.name,
    p1Card.tiny_id,
    p1Card.mp_cost,
    p1Card.description,
    p1Card.img,
    p1Card.color,
  );
  const p2CardData = new CardData(
    p2Card.name,
    p2Card.tiny_id,
    p2Card.mp_cost,
    p2Card.description,
    p2Card.img,
    p2Card.color,
  );

  return (
    <Backdrop>
      <SlideBox initAnimation={slideInRight} cardData={p2CardData} />
      <SlideBox initAnimation={slideInLeft} cardData={p1CardData} />
      <LinesBox lines={lines} />
    </Backdrop>
  );
}

function DescriptorDisplay({ descriptor }) {
  if (!descriptor) {
    return (
      <div />
    );
  }

  if (descriptor.type === 'line') {
    return (
      <LineDescriptor descriptor={descriptor} />
    );
  }

  return (
    <div />
  );
}

/*
 * average reading word per minute is 200
 * average char count per word is 5
 * average char per minutes is 1000
 * average char per second is 16
 * average ms per char is 62.5
 *
 *   we make this a bit faster as pattern recognition is faster than reading
 *   const msPerChar = 30;
 *
 * The naive msPerChar * char doesnt work, long descriptors display for too long
 * This updated equation will taper off at higher char counts
 *
 *   log(1 + (charCount / 60)) * 4000
 */
const getDelay = (descriptor) => {
  if (!descriptor) {
    return 0;
  }
  if (!descriptor.lines) {
    return 0;
  }

  if (descriptor.type === 'resolution') {
    return ResolutionTimeOnScreen;
  }

  // sum the characters in descriptor.lines
  const charCount = descriptor.lines.reduce((acc, line) => acc + line.line.length, 0);
  const delay = Math.log10(1 + (charCount / 60)) * 4000;
  return delay;
};

function DescriptorsModal({ modalIsOpen, descriptors, callback }) {
  const [shownDescriptor, setShownDescriptor] = useState(null);
  const [timeouts, setTimeouts] = useState([]);
  const { enqueue } = useAnimation();

  if (!modalIsOpen) {
    return (
      <>
      </>
    );
  }

  if (!descriptors) {
    return (
      <>
      </>
    );
  }

  const afterOpen = () => {
    // setup the lines
    const orderedDescriptors = [];

    descriptors.descriptors.forEach((descriptor) => {
      if (descriptor.type === 'line') {
        orderedDescriptors.push(descriptor);
      } else if (descriptor.type === 'resolution') {
        orderedDescriptors.push(descriptor);
      } else if (descriptor.type === 'animate') {
        enqueue(descriptor);
      }
    });

    // Enqueue the first descriptor
    const firstDescriptor = orderedDescriptors.shift();
    if (firstDescriptor) {
      // if we are an animate descriptor just animate
      if (firstDescriptor.type === 'animate') {
        enqueue(firstDescriptor);
      } else {
        // Otherwise we have something to show
        setShownDescriptor(firstDescriptor);
        // Resolution descriptors also have animations
        if (firstDescriptor.animations) {
          // enqueue each animation in the descriptor
          firstDescriptor.animations.forEach((animation) => {
            enqueue(animation);
          });
        }
      }
    }

    const initDelay = getDelay(firstDescriptor);

    const timeoutIds = [];
    const totalWait = orderedDescriptors.reduce((acc, descriptor) => {
      const timeoutId = setTimeout(() => {
        if (descriptor.type === 'animate') {
          enqueue(descriptor);
        } else {
          setShownDescriptor(descriptor);
          // Resolution descriptors have animations
          if (descriptor.animations) {
            // enqueue each animation in the descriptor
            descriptor.animations.forEach((animation) => {
              enqueue(animation);
            });
          }
        }
      }, acc);
      timeoutIds.push(timeoutId);
      return acc + getDelay(descriptor);
    }, initDelay);
    setTimeouts(timeoutIds);

    // queue the close of the modal
    const nextDelay = getDelay(orderedDescriptors[orderedDescriptors.length - 1]);
    const closeModalDelayMs = totalWait + nextDelay;
    const closeTimeoutId = setTimeout(() => {
      setShownDescriptor(null);
      setTimeouts([]);
      callback();
    }, closeModalDelayMs);
    setTimeouts((prevTimeouts) => [...prevTimeouts, closeTimeoutId]);
  };

  const handleCloseModal = () => {
    timeouts.forEach((timeoutId) => clearTimeout(timeoutId));
    setTimeouts([]);
    setShownDescriptor(null);
    callback();
  };

  if (shownDescriptor && shownDescriptor.type === 'resolution') {
    return <CardResolutionDescriptor2 descriptor={shownDescriptor} />;
  }

  return (
    <ReactModal
      isOpen={modalIsOpen}
      className="DescriptorsModal"
      overlayClassName="DescriptorsModal__overlay"
      appElement={document.getElementById('root')}
      onAfterOpen={afterOpen}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
        },
      }}
    >
      <div style={{ position: 'absolute', top: '10px', right: '10px' }}>
        <Button square size="xs" onClick={handleCloseModal}>close</Button>
      </div>
      <DescriptorDisplay descriptor={shownDescriptor} />
    </ReactModal>
  );
}

export default DescriptorsModal;
