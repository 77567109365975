import DescriptorsModal from 'pages/game/DescriptorsModal';
import { useState } from 'react';
import { Button } from 'components/atoms';
import Section from './Section';

const upkeepDescriptors = {
  upkeep: [
    {
      id: 'dffcaed5-822c-44bd-80be-a879895f6d98',
      type: 'descriptor',
      text: 'Violet Mage draws 3 cards',
    },
    {
      id: '3bb28b1e-ef2f-4031-9c92-3534a823ce86',
      type: 'descriptor',
      text: 'Green Mage draws 3 cards',
    },
    {
      id: '025e7365-cb76-4989-b53d-9a8c6686434e',
      type: 'line',
      lines: [
        'Green Mage is affected by Replenish. (1)',
        'Green Mage is already at max hp! (1)',
      ],
    },
  ],
};

/*
const resolutionDescriptorWithAnimations = {
  resolutions: [
    {
      id: '31323721-77b1-4006-8c58-5d9f0571af16',
      type: 'resolution',
      p1_card: {
        tiny_id: '2616bc782ded43cc9b663bbb6aa8e933-17288',
        name: 'Grow',
        description: 'Heals the current mage for 30 and draws a card',
        color: 'GREEN',
        mp_cost: 40,
        img: 'grow',
      },
      p2_card: {
        tiny_id: '5ffef5d194604c3a805a65b90f7b8d5a-19800',
        name: 'Block',
        description: 'Block 40 damage',
        color: 'COLORLESS',
        mp_cost: 10,
        img: 'block',
      },
      lines: [
        "Player1 red-s-56-nc-6's casts Grow.",
        "Player2 green-s-26-nc-1's casts Block.",
        'Grow costs -40 more MP because it is an enemy color.',
        'red-s-56-nc-6 tried to play Grow but did not have enough mana',
      ],
      animations: [
        {
          id: 'ed5e37cc-4c35-48ea-b03b-eeccca1e1e16',
          type: 'animate',
          category: 'addMP',
          data: -10,
          subject_owner: 'p2',
          subject_class: 'Mage',
          subject_id: 'db4016ce12ef43b890b44edbd419a195',
        },
      ],
    },
    {
      id: 'fc5f85ee-5139-4a2b-bc10-271d2315ee58',
      type: 'resolution',
      p1_card: {
        tiny_id: '36d5ed3fb9144736b502927f384eebaf-24077',
        name: 'Nothing',
        description: 'A card played when nothing is cast.',
        color: 'COLORLESS',
        mp_cost: 0,
        img: 'nothing',
      },
      p2_card: {
        tiny_id: 'cc94283c035341b6aff127f49b50c738-19796',
        name: 'BlossomBloom',
        description: 'Heals 30 HP to a target.',
        color: 'GREEN',
        mp_cost: 40,
        img: 'blossombloom',
      },
      lines: [
        "Player1 red-s-56-nc-6's casts Nothing.",
        "Player2 green-s-26-nc-1's casts BlossomBloom, targeting Player2's green-s-26-nc-1",
        'green-s-26-nc-1 is healed for 30 hp.',
      ],
      animations: [
        {
          id: '5a42f1ee-881b-44d3-8b4b-f23fb4cc79ee',
          type: 'animate',
          category: 'addMP',
          data: -40,
          subject_owner: 'p2',
          subject_class: 'Mage',
          subject_id: 'db4016ce12ef43b890b44edbd419a195',
        },
        {
          id: '640e615a-54d0-4311-95cd-fba2409c67da',
          type: 'animate',
          category: 'addHP',
          data: 30,
          subject_owner: 'p2',
          subject_class: 'Mage',
          subject_id: 'db4016ce12ef43b890b44edbd419a195',
        },
      ],
    },
  ],
};
*/

const turnDescriptors = {
  changes: [
    {
      id: 'b29c41e0-e3db-4ef6-b15a-801dc013f0a9',
      type: 'change',
      text: 'Player1 changed mage to Blue Mage',
    },
  ],
  resolutions: [
    {
      id: '11fd4295-6429-431a-be9d-fca0c8a72403',
      type: 'resolution',
      p1_card: {
        tiny_id: 'd8649d3c044b4ad1982dadf78f34e66c-10',
        name: 'Hellfire',
        description: '\n    Deals 90 damage.\n    ',
        color: 'RED',
        mp_cost: 85,
        img: 'hellfire',
      },
      p2_card: {
        tiny_id: 'd81e4f95ec09454bb240692485c85c82-59',
        name: 'LeafBlade',
        description: '\n    Deals 20 damage.\n    ',
        color: 'GREEN',
        mp_cost: 20,
        img: 'leafblade',
      },
      lines: [
        "Player1 Red Mage's casts Hellfire.",
        "Player2 Green Mage's casts LeafBlade.",
        'Red Mage took 20 dmg, they have 80 hp left!',
        'Green Mage took 90 dmg, they have 10 hp left!',
      ],
    },
    {
      id: 'd946b5ab-8943-40ac-93f7-0f31ef42ee4c',
      type: 'resolution',
      p1_card: {
        tiny_id: '6c28d6b52adb4985b363769c1070ff28-7',
        name: 'Fireball',
        description: '\n    Deals 45 damage.\n    ',
        color: 'RED',
        mp_cost: 40,
        img: 'fireball',
      },
      p2_card: {
        tiny_id: 'b73a6ff908e24b988fd2425f0ccaef77-48',
        name: 'Thorns',
        description: '\n    Applys the condition which deals damage when dealt damage\n    ',
        color: 'GREEN',
        mp_cost: 10,
        img: 'thorns',
      },
      lines: [
        "Player1 Red Mage's casts Fireball.",
        "Player2 Green Mage's casts Thorns, targeting Player2's Green Mage",
        'Red Mage tried to play Fireball but did not have enough mana',
      ],
    },
    {
      id: '1d96831b-165d-4bdb-a981-90f0e296d67d',
      type: 'resolution',
      p1_card: {
        tiny_id: '6fd47cec379a4880b4bdcfbb39ed7aa7-6',
        name: 'Block',
        description: 'Block 40 damage',
        color: 'COLORLESS',
        mp_cost: 10,
        img: 'block',
      },
      p2_card: {
        tiny_id: '5d77183c87c54bb181b917eda19b58bd-50',
        name: 'LeafBlade',
        description: '\n    Deals 20 damage.\n    ',
        color: 'GREEN',
        mp_cost: 20,
        img: 'leafblade',
      },
      lines: [
        "Player1 Red Mage's casts Block.",
        "Player2 Green Mage's casts LeafBlade.",
        '20 damage is blocked!',
      ],
    },
    {
      id: '941c5d80-9f82-4c41-8edc-5f45a47943fa',
      type: 'resolution',
      p1_card: {
        tiny_id: '0cd3bdf85395463e8f9220e6b2765076-92',
        name: 'Nothing',
        description: '\n    A card played when nothing is cast.\n    ',
        color: 'COLORLESS',
        mp_cost: 0,
        img: 'nothing',
      },
      p2_card: {
        tiny_id: '2a6a587c2d344102aa7c202b89ed47ff-54',
        name: 'Block',
        description: 'Block 40 damage',
        color: 'COLORLESS',
        mp_cost: 10,
        img: 'block',
      },
      lines: [
        "Player1 Red Mage's casts Nothing.",
        "Player2 Green Mage's casts Block.",
      ],
    },
    {
      id: '8c2c2c77-dfd5-4b0a-bab2-2ef96c351a2e',
      type: 'resolution',
      p1_card: {
        tiny_id: '934159fd336242e081303cd0e818a47e-93',
        name: 'Nothing',
        description: 'A card played when nothing is cast.',
        color: 'COLORLESS',
        mp_cost: 0,
        img: 'nothing',
      },
      p2_card: {
        tiny_id: '3b1c47987ab948de9a9290ab99a250e6-49',
        name: 'Ensnare',
        description: 'Traps the active mage in battle, cannot switch except on death or when the effect wheres off',
        color: 'GREEN',
        mp_cost: 10,
        img: 'ensnare',
      },
      lines: [
        "Player1 Red Mage's casts Nothing.",
        "Player2 Green Mage's casts Ensnare, targeting Player1's Red Mage",
      ],
    },
    {
      id: '1670319b-b894-4647-8c0d-04303adf3bf0',
      type: 'resolution',
      p1_card: {
        tiny_id: '8d55234652bc473c80b2eca95df24d84-94',
        name: 'Nothing',
        description: '\n    A card played when nothing is cast.\n    ',
        color: 'COLORLESS',
        mp_cost: 0,
        img: 'nothing',
      },
      p2_card: {
        tiny_id: '0c0b2d949b1145ac8c5b49d1130bdd94-55',
        name: 'Grow',
        description: '\n    Heals the current mage for 30 and draws a card\n    ',
        color: 'GREEN',
        mp_cost: 30,
        img: 'grow',
      },
      lines: [
        "Player1 Red Mage's casts Nothing.",
        "Player2 Green Mage's casts Grow.",
        'Green Mage draws 1 cards.',
        'Green Mage is healed for 30 hp.',
      ],
    },
  ],
};

const endDescriptors = {
  end: [
    {
      id: '26256f53-3188-45aa-ad65-3dec592ec89d',
      type: 'descriptor',
      text: "Player2's mage ran out of cards! Green Mage takes shuffle burn damage."
            + " Player2's mage ran out of cards! Green Mage takes shuffle burn damage.",
    },
    {
      id: '4c14f342-d204-4184-8e83-a5d36d3b939a',
      type: 'descriptor',
      text: 'Green Mage took 30 dmg, they have 0 hp left!',
    },
    {
      id: '9014c1b9-63f1-4623-a25e-12f8322c770a',
      type: 'descriptor',
      text: 'Green Mage has died!',
    },
    {
      id: '4a39a0bc-8793-478e-8668-64cc047f8a03',
      type: 'descriptor',
      text: 'Changing active mage from Green Mage to Orange Mage',
    },
  ],
};

export default function DescriptorSection() {
  const [showUpkeepModal, setShowUpkeepModal] = useState(false);
  const onUpkeepClick = () => {
    setShowUpkeepModal(true);
  };
  const upkeepCallback = () => {
    setShowUpkeepModal(false);
  };

  const [showTurnModal, setShowTurnModal] = useState(false);
  const onTurnClick = () => {
    setShowTurnModal(true);
  };
  const turnCallback = () => {
    setShowTurnModal(false);
  };

  const [showEndModal, setShowEndModal] = useState(false);
  const onEndClick = () => {
    setShowEndModal(true);
  };
  const endCallback = () => {
    setShowEndModal(false);
  };

  return (
    <Section title="Descriptor">
      <Button onClick={onUpkeepClick}>Open Upkeep Descriptors</Button>
      <DescriptorsModal
        modalIsOpen={showUpkeepModal}
        descriptors={upkeepDescriptors}
        callback={upkeepCallback}
      />
      <Button onClick={onTurnClick}>Open Turn Descriptors</Button>
      <DescriptorsModal
        modalIsOpen={showTurnModal}
        descriptors={turnDescriptors}
        callback={turnCallback}
      />
      <Button onClick={onEndClick}>Open End Descriptors</Button>
      <DescriptorsModal
        modalIsOpen={showEndModal}
        descriptors={endDescriptors}
        callback={endCallback}
      />
    </Section>
  );
}
