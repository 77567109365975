import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

const StyledHeader = styled.div`
  font-size: 0.8em;
  min-height: 2em;
  max-height: 2em;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  background-color: ${(props) => (props.color)};
  padding: 8px;

  //@media screen and (max-width: 844px) {
  //  font-size: 0.5em;
  //  max-height: 100em;
  //}
`;

const NameSpan = styled.span`
  font-weight: bold;
  top: -3px;
  position: relative;
`;

export default function MageHeader({ name, color, right }) {
  const { cardColors } = useContext(ThemeContext);
  const backgroundColor = cardColors[color.toLowerCase()] || cardColors.default;
  const float = right ? 'right' : '';
  return (
    <StyledHeader color={backgroundColor}>
      <NameSpan style={{ float }}>{name}</NameSpan>
    </StyledHeader>
  );
}
