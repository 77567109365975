import * as THREE from 'three';
import { OrbitControls } from 'three-stdlib';
import { Sky } from './sky';

function constructFloor() {
  // Create the geometry with more segments
  const floorGeometry = new THREE.PlaneGeometry(500, 500, 100, 100);
  // Access the position attribute of the geometry
  const positions = floorGeometry.attributes.position;
  // Deform the geometry to create hills
  for (let i = 0; i < positions.count; i += 1) {
    // Access the x, y, z coordinates of the vertex
    const x = positions.getX(i);
    const y = positions.getY(i);
    let z = positions.getZ(i);

    const xp = 0.5;
    const yp = 1.25;
    const xyp = 0.25;
    const cp = 2.75;
    const cd = 6.5;

    // Modify the z coordinate based on x and y to create a hill pattern
    z += (Math.sin(x * xp) + Math.sin(y * yp)) * xyp + (Math.random() * cp - cd);
    // Set the modified z coordinate back to the geometry
    positions.setZ(i, z);
  }
  // Update the geometry to reflect the changes
  positions.needsUpdate = true;
  floorGeometry.computeVertexNormals(); // To smooth the shading
  // Material
  const color = 0x41980a;
  const floorMaterial = new THREE.MeshStandardMaterial({ color, roughness: 0.8, flatShading: true });
  // Mesh
  const floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.rotation.x = -Math.PI / 2;
  floor.position.y = 3;
  floor.receiveShadow = true;
  return floor;
}

function constructPlateau() {
  const width = 15; // width of the plateau
  const height = 50; // length of the plateau
  const depth = 3; // depth to extrude, this will be the ramp height
  const radius = 0.5; // radius of the rounded corners

  // Define the plateau shape with rounded corners
  const shape = new THREE.Shape();
  shape.moveTo(-width / 2 + radius, -height / 2);
  shape.lineTo(width / 2 - radius, -height / 2);
  shape.absarc(width / 2 - radius, -height / 2 + radius, radius, Math.PI * 1.5, Math.PI * 2, false);
  shape.lineTo(width / 2, height / 2 - radius);
  shape.absarc(width / 2 - radius, height / 2 - radius, radius, 0, Math.PI * 0.5, false);
  shape.lineTo(-width / 2 + radius, height / 2);
  shape.absarc(-width / 2 + radius, height / 2 - radius, radius, Math.PI * 0.5, Math.PI, false);
  shape.lineTo(-width / 2, -height / 2 + radius);
  shape.absarc(-width / 2 + radius, -height / 2 + radius, radius, Math.PI, Math.PI * 1.5, false);

  const extrudeSettings = {
    steps: 2,
    depth,
    bevelEnabled: false, // No bevels to make a clean ramp
  };

  const geometry = new THREE.ExtrudeGeometry(shape, extrudeSettings);
  const color = 0x41980a; // Green color
  const material = new THREE.MeshPhongMaterial({ color, flatShading: false });
  const floor = new THREE.Mesh(geometry, material);
  floor.rotation.x = -Math.PI / 2; // Rotate to lay flat
  floor.receiveShadow = true; // Enable shadow reception

  const plateauHeight = -0.5;

  // Adjust the position so that the plateau starts at ground level and ramps down
  floor.position.set(0, plateauHeight - depth, -18);

  return floor;
}

function constructRenderer() {
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerWidth, window.innerHeight);
  renderer.setClearColor(new THREE.Color(0xFFFFFF)); // Set background to white
  renderer.toneMapping = THREE.ACESFilmicToneMapping;
  renderer.toneMappingExposure = 0.5;
  renderer.shadowMap.enabled = true; // Enable shadow mapping
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  return renderer;
}

function constructLight() {
  const light = new THREE.DirectionalLight(0xffffff, 1.0);
  light.position.set(0, 10, -100); // Position the light
  light.castShadow = true; // Enable shadows for this light
  light.shadow.mapSize.width = 1024; // Default is 512
  light.shadow.mapSize.height = 1024; // Default is 512
  light.shadow.camera.near = 0.5; // Default is 0.5
  light.shadow.camera.far = 500; // Default is 500
  light.shadow.camera.left = -30;
  light.shadow.camera.right = 30;
  light.shadow.camera.top = 30;
  light.shadow.camera.bottom = -30;
  return light;
}

function constructSky() {
  const sky = new Sky();
  sky.scale.setScalar(450000);
  const { uniforms } = sky.material;
  uniforms.turbidity.value = 10;
  uniforms.rayleigh.value = 3;
  uniforms.mieCoefficient.value = 0.005;
  uniforms.mieDirectionalG.value = 0.7;
  const sun = new THREE.Vector3();
  const phi = THREE.MathUtils.degToRad(90);
  const theta = THREE.MathUtils.degToRad(180);
  sun.setFromSphericalCoords(1, phi, theta);
  uniforms.sunPosition.value.copy(sun);
  return sky;
}

function constructOrbitControls(camera, renderer) {
  const orbitControls = new OrbitControls(camera, renderer.domElement);
  orbitControls.enableDamping = true;
  orbitControls.dampingFactor = 0.25;
  orbitControls.screenSpacePanning = false;
  orbitControls.minPolarAngle = 0;
  orbitControls.maxPolarAngle = Math.PI / 2.1;
  orbitControls.maxDistance = 10;
  return orbitControls;
}

export {
  constructSky, constructFloor, constructLight, constructPlateau, constructRenderer, constructOrbitControls,
};
