import { Link } from 'components/atoms';
import { useNavigate } from 'react-router-dom';

import { RootTitled } from 'components/templates';
import { NavList } from 'components/molecules';
import { getInProgress, createBuildGame } from 'api/build';
import useApi from 'api/useApi';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

function NewOrContinue() {
  const navigate = useNavigate();

  const {
    data: ipdata, error: iperror, request: iprequest, loading: iploading,
  } = useApi(getInProgress);

  useEffect(() => {
    iprequest();
  }, []);

  const {
    data, error, request, loading,
  } = useApi(createBuildGame);

  useEffect(() => {
    if (iperror && iperror.status !== 404) {
      toast.error(iperror.message);
    }
  }, [iperror]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const onNew = (event) => {
    event.preventDefault();
    request();
  };

  useEffect(() => {
    if (data) {
      navigate(`/build/new?bid=${data.build_id}`);
    }
  }, [data]);

  if (iploading) {
    return (
      <p>Loading...</p>
    );
  }

  if (ipdata) {
    const to = `/build/map?bid=${ipdata.build_id}`;
    return (
      <Link to={to}>Continue</Link>
    );
  }

  return (
    <Link to="/build" onClick={onNew}>
      {loading ? 'Loading...' : 'New'}
    </Link>
  );
}

export default function Build() {
  return (
    <RootTitled>
      <NavList>
        <NewOrContinue />
      </NavList>
    </RootTitled>
  );
}
