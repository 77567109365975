export default class MapGraph {
  constructor() {
    this.nodes = [];
    this.edges = [];
    this.settled = false;
  }

  addNode(node) {
    this.nodes.push(node);
  }

  addEdge(sourceId, targetId) {
    this.edges.push({ sourceId, targetId });
  }

  getNode(nodeId) {
    return this.nodes.find((node) => node.nodeId === nodeId);
  }

  getSelected() {
    return this.nodes.find((node) => node.isSelected);
  }
}
