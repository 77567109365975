/*
 * This file is autogenerated. Please use tools/client-side-card-gen to regenerate.
 */

import bandage from './cards/bandage.jpg';
import bigpunch from './cards/bigpunch.jpg';
import block from './cards/block.jpg';
import blossombloom from './cards/blossombloom.jpg';
import boost from './cards/boost.jpg';
import boulder from './cards/boulder.jpg';
import cardback from './cards/cardback.jpg';
import cleanse from './cards/cleanse.jpg';
import counterspell from './cards/counterspell.jpg';
import deluge from './cards/deluge.jpg';
import dewfall from './cards/dewfall.jpg';
import draw from './cards/draw.jpg';
import droplet from './cards/droplet.jpg';
import earthquake from './cards/earthquake.jpg';
import ember from './cards/ember.jpg';
import embrazen from './cards/embrazen.jpg';
import enrage from './cards/enrage.jpg';
import ensnare from './cards/ensnare.jpg';
import explosion from './cards/explosion.jpg';
import fireball from './cards/fireball.jpg';
import fizzle from './cards/fizzle.jpg';
import goad from './cards/goad.jpg';
import granitearmor from './cards/granitearmor.jpg';
import grow from './cards/grow.jpg';
import hellfire from './cards/hellfire.jpg';
import hydrate from './cards/hydrate.jpg';
import infernoshift from './cards/infernoshift.webp';
import leafblade from './cards/leafblade.jpg';
import lightning from './cards/lightning.jpg';
import manabattery from './cards/manabattery.jpg';
import manasteal from './cards/manasteal.jpg';
import manasurge from './cards/manasurge.jpg';
import mend from './cards/mend.jpg';
import mossheart from './cards/mossheart.jpg';
import necrotaint from './cards/necrotaint.jpg';
import nothing from './cards/nothing.jpg';
import overcharge from './cards/overcharge.jpg';
import pass from './cards/pass.jpg';
import pebble from './cards/pebble.jpg';
import petrify from './cards/petrify.jpg';
import poison from './cards/poison.jpg';
import punch from './cards/punch.jpg';
import quench from './cards/quench.jpg';
import reflect from './cards/reflect.jpg';
import replenish from './cards/replenish.jpg';
import rot from './cards/rot.jpg';
import shock from './cards/shock.jpg';
import slabshield from './cards/slabshield.jpg';
import snipe from './cards/snipe.jpg';
import spout from './cards/spout.jpg';
import stonewall from './cards/stonewall.jpg';
import syphon from './cards/syphon.jpg';
import tax from './cards/tax.jpg';
import taze from './cards/taze.jpg';
import terracottamaneuveur from './cards/terracottamaneuveur.webp';
import thorns from './cards/thorns.jpg';
import thoughtflood from './cards/thoughtflood.jpg';
import tidalwave from './cards/tidalwave.jpg';
import tideturn from './cards/tideturn.webp';
import venomspit from './cards/venomspit.jpg';
import verdantpassage from './cards/verdantpassage.webp';
import viletransference from './cards/viletransference.webp';
import volticexchange from './cards/volticexchange.webp';
import vortex from './cards/vortex.jpg';
import weaken from './cards/weaken.jpg';
import whirlpool from './cards/whirlpool.jpg';

const CardMap = {
  bandage,
  bigpunch,
  block,
  blossombloom,
  boost,
  boulder,
  cardback,
  cleanse,
  counterspell,
  deluge,
  dewfall,
  draw,
  droplet,
  earthquake,
  ember,
  embrazen,
  enrage,
  ensnare,
  explosion,
  fireball,
  fizzle,
  goad,
  granitearmor,
  grow,
  hellfire,
  hydrate,
  infernoshift,
  leafblade,
  lightning,
  manabattery,
  manasteal,
  manasurge,
  mend,
  mossheart,
  necrotaint,
  nothing,
  overcharge,
  pass,
  pebble,
  petrify,
  poison,
  punch,
  quench,
  reflect,
  replenish,
  rot,
  shock,
  slabshield,
  snipe,
  spout,
  stonewall,
  syphon,
  tax,
  taze,
  terracottamaneuveur,
  thorns,
  thoughtflood,
  tidalwave,
  tideturn,
  venomspit,
  verdantpassage,
  viletransference,
  volticexchange,
  vortex,
  weaken,
  whirlpool,
};

export default CardMap;
