/* Contains utility functions for handling errors */

const parseErrorForToast = (error) => {
  if (!error) {
    return 'Unexpected error (1)';
  }
  if (!error.data) {
    return 'Unexpected error (2)';
  }
  if (!error.data.detail) {
    return 'Unexpected error (3)';
  }
  // if detail is a string return it
  if (typeof error.data.detail === 'string') {
    return error.data.detail;
  }
  // if detail is a map get each message
  if (typeof error.data.detail === 'object') {
    return error.data.detail.map((d) => {
      if (d.msg) {
        return d.msg;
      }
      return 'Unknown error';
    }).join(',');
  }
  return 'Unexpected error (4)';
};

const noop = () => { };

export { parseErrorForToast, noop };
