import CardMap from './CardMap';
import fizzle from './cards/fizzle.jpg';

const getImageFromName = (name) => {
  const value = CardMap[name];
  if (value) {
    return value;
  }
  return fizzle;
};

class CardData {
  constructor(name, id, mpCost, description, img, color) {
    this.name = name;
    this.id = id;
    this.mpCost = mpCost;
    this.description = description;
    this.img_name = img;
    this.img = getImageFromName(img);
    this.color = color;
  }
}

function getCardListFromPlayer(player) {
  return [...player.team.active_mage.deck.hand].map((c) => new CardData(
    c.name,
    c.tiny_id,
    c.mp_cost,
    c.description,
    c.img,
    c.color,
  ));
}

function serializeToCardData(c) {
  return new CardData(
    c.name,
    c.tiny_id,
    c.mp_cost,
    c.description,
    c.img,
    c.color,
  );
}

function serializeAllToCardData(cards) {
  const cardData = [...cards].map((c) => serializeToCardData(c));
  return cardData;
}

export { CardData, getCardListFromPlayer, serializeAllToCardData };
