import styled from 'styled-components';
import { Conditions } from 'components/molecules';
import Section from './Section';

const StyledH3 = styled.h3`
  margin-bottom: 1em;
  margin-top: 1em;
`;

export default function ConditionsSection() {
  const mostConditions = [
    { tiny_id: 'Goad', name: 'Goad', description: 'test description' },
    { tiny_id: 'Enrage', name: 'Enrage', description: 'test description' },
    { tiny_id: 'Weaken', name: 'Weaken', description: 'test description' },
    { tiny_id: 'Embrazen', name: 'Embrazen', description: 'test description' },
    { tiny_id: 'GraniteArmor', name: 'GraniteArmor', description: 'test description' },
    { tiny_id: 'Petrify', name: 'Petrify', description: 'test description' },
    { tiny_id: 'Stonewall', name: 'Stonewall', description: 'test description' },
    { tiny_id: 'ManaBattery', name: 'ManaBattery', description: 'test description' },
    { tiny_id: 'Dewfall', name: 'Dewfall', description: 'test description' },
    { tiny_id: 'Ensnare', name: 'Ensnare', description: 'test description' },
    { tiny_id: 'Replenish', name: 'Replenish', description: 'test description' },
    { tiny_id: 'Thorns', name: 'Thorns', description: 'test description' },
    { tiny_id: 'Whirlpool', name: 'Whirlpool', description: 'test description' },
    { tiny_id: 'Necrotaint', name: 'Necrotaint', description: 'test description' },
    { tiny_id: 'Tax', name: 'Tax', description: 'test description' },
    { tiny_id: 'Poison', name: 'Poison', description: 'test description' },
    { tiny_id: 'Syphon', name: 'Syphon', description: 'test description' },
    { tiny_id: 'Vortex', name: 'Vortex', description: 'test description' },
  ];

  const oneCondition = [
    { tiny_id: 'one-0', name: 'Thorns', description: 'test description' },
  ];

  const longDesc = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed '
+ 'do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, '
+ 'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. '
+ 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat '
+ 'nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui '
+ 'officia deserunt mollit anim id est laborum.';

  const longDescription = [
    { tiny_id: 'one-0', name: 'Thorns', description: longDesc },
  ];

  const dupConditions = [
    { tiny_id: 'dup-0', name: 'Thorns', description: 'test description 0' },
    { tiny_id: 'dup-1', name: 'Thorns', description: 'test description 1' },
    { tiny_id: 'dup-2', name: 'Tax', description: 'test description 2' },
    { tiny_id: 'dup-3', name: 'Thorns', description: 'test description 3' },
    { tiny_id: 'dup-4', name: 'Tax', description: 'test description 4' },
    { tiny_id: 'dup-5', name: 'Stonewall', description: 'test description 5' },
  ];

  return (
    <Section title="Conditions">
      <StyledH3>Long Description</StyledH3>
      <div>
        <Conditions conditions={longDescription} />
      </div>

      <StyledH3>With Duplicates</StyledH3>
      <div>
        <Conditions conditions={dupConditions} />
      </div>

      <StyledH3>Empty State</StyledH3>
      <div>
        <Conditions />
      </div>

      <StyledH3>With Condition</StyledH3>
      <div>
        <Conditions conditions={oneCondition} />
      </div>

      <StyledH3>With Many Conditions</StyledH3>
      <div>
        <Conditions conditions={mostConditions} />
      </div>
      <StyledH3>With Conditions isRight</StyledH3>
      <div style={{ height: '50px', maxWidth: '200px', border: '1px solid' }}>
        <Conditions conditions={oneCondition} isRight />
      </div>
      <StyledH3>With Bounded Box</StyledH3>
      <div style={{ width: '200px', border: '1px solid black' }}>
        <Conditions conditions={mostConditions} />
      </div>
      <StyledH3>Labeled</StyledH3>
      <div>
        {mostConditions.map((condition) => (
          <div key={condition.tiny_id} style={{ display: 'flex' }}>
            <span style={{ marginRight: '1em' }}>{`${condition.name}:`}</span>
            <Conditions conditions={[condition]} />
          </div>
        ))}
      </div>
    </Section>
  );
}
