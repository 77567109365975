import './Title.css';

export default function Title() {
  const version = document.querySelector('meta[name="version"]').content;
  return (
    <div className="Title">
      <h1 className="Title__text">Mage Game</h1>
      <div className="Title__version">{version}</div>
    </div>
  );
}
