import { useState } from 'react';
import { Button } from 'components/atoms';
import styled from 'styled-components';

import Chat from './Chat';
import Log from './Log';

import './Sidebar.css';

function SidebarContent({ logActive, socket }) {
  const logDisplay = logActive ? 'flex' : 'none';
  const chatDisplay = logActive ? 'none' : 'flex';

  return (
    <div className="Sidebar__content">
      <div className="Sidebar__content--log" style={{ display: logDisplay }}>
        <Log socket={socket} />
      </div>
      <div className="Sidebar__content--chat" style={{ display: chatDisplay }}>
        <Chat socket={socket} />
      </div>
    </div>
  );
}

const SidebarToggles = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 30px;
  justify-content: space-evenly;
`;

function Sidebar({ socket }) {
  const [logActive, setLogActive] = useState(true);
  const [chatActive, setChatActive] = useState(false);

  const logClick = () => {
    setLogActive(true);
    setChatActive(false);
  };

  const chatClick = () => {
    setChatActive(true);
    setLogActive(false);
  };

  const logVariant = logActive ? 'secondary' : '';
  const chatVariant = chatActive ? 'secondary' : '';

  return (
    <div className="Sidebar__container">
      <SidebarToggles>
        <Button variant={logVariant} square size="xs" onClick={logClick} active={logActive}> Log </Button>
        <Button variant={chatVariant} square size="xs" onClick={chatClick} active={chatActive}> Chat </Button>
      </SidebarToggles>
      <SidebarContent logActive={logActive} socket={socket} />
    </div>
  );
}

export default Sidebar;
