import styled from 'styled-components';
import {
  TerracottaManeuveurSigil,
  InfernoShiftSigil,
  VortexSigil,
  GoadSigil,
  EnrageSigil,
  WeakenSigil,
  EmbrazenSigil,
  GraniteArmorSigil,
  PetrifySigil,
  StonewallSigil,
  ManaBatterySigil,
  DewfallSigil,
  EnsnareSigil,
  ReplenishSigil,
  ThornsSigil,
  WhirlpoolSigil,
  NecrotaintSigil,
  TaxSigil,
  PoisonSigil,
  SyphonSigil,
  VolticExchangeSigil,
  VerdantPassageSigil,
  TideTurnSigil,
  VileTransferenceSigil,
} from './ConditionSigils';

const Container = styled.div`
  display: flex;
`;

const sigilMap = {
  Dewfall: DewfallSigil,
  Embrazen: EmbrazenSigil,
  Enrage: EnrageSigil,
  Ensnare: EnsnareSigil,
  Goad: GoadSigil,
  GraniteArmor: GraniteArmorSigil,
  ManaBattery: ManaBatterySigil,
  Necrotaint: NecrotaintSigil,
  Petrify: PetrifySigil,
  Poison: PoisonSigil,
  Replenish: ReplenishSigil,
  Stonewall: StonewallSigil,
  Syphon: SyphonSigil,
  Tax: TaxSigil,
  Thorns: ThornsSigil,
  Weaken: WeakenSigil,
  Whirlpool: WhirlpoolSigil,
  Vortex: VortexSigil,
  InfernoShift: InfernoShiftSigil,
  TerracottaManeuveur: TerracottaManeuveurSigil,
  VolticExchange: VolticExchangeSigil,
  VerdantPassage: VerdantPassageSigil,
  TideTurn: TideTurnSigil,
  VileTransference: VileTransferenceSigil,
};

function Condition({ condition }) {
  if (!condition) {
    return <span />;
  }
  if (!(condition.name in sigilMap)) {
    throw new Error(`Unable to resolve condition to sigil in sigilMap: '${condition.name}'. Please new create sigil.`);
  }
  const Sigil = sigilMap[condition.name];
  return (
    <span key={condition.tiny_id} style={{ display: 'flex' }}>
      <Sigil condition={condition} />
      {condition.count === 1 ? '' : `x${condition.count}`}
    </span>
  );
}

export default function Conditions({ conditions, isRight }) {
  const float = isRight ? 'right' : '';

  if (!conditions || conditions.length === 0) {
    return (
      <div />
    );
  }

  // Create an object to count occurrences of each condition
  const conditionCounts = {};
  conditions.forEach((condition) => {
    if (conditionCounts[condition.name]) {
      conditionCounts[condition.name].count += 1;
      conditionCounts[condition.name].description += ` ${condition.description}`;
    } else {
      conditionCounts[condition.name] = { ...condition, count: 1 };
    }
  });

  const uniqueConditions = Object.values(conditionCounts);

  return (
    <Container style={{ float }}>
      {uniqueConditions.map((condition) => (
        <Condition key={condition.tiny_id} condition={condition} />
      ))}
    </Container>
  );
}
