import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Card } from 'ui-neumorphism';

export default function Tile({ children, onClick }) {
  const { backgroundColor } = useContext(ThemeContext);

  const styles = {
    backgroundColor,
  };

  if (onClick) {
    styles.cursor = 'pointer';
  }

  return (
    <div onClick={onClick}>
      <Card style={styles}>
        {children}
      </Card>
    </div>
  );
}
