import { Link } from 'components/atoms';
import { NavList } from 'components/molecules';

export default function HomeNav() {
  return (
    <NavList>
      <Link to="/play">Play</Link>
      <Link to="/decks">Decks</Link>
      <Link to="/teams">Teams</Link>
      <Link to="/cards">Cards</Link>
    </NavList>
  );
}
