import styled from 'styled-components';
import { Card, MageOption } from 'components/molecules';
import { Modal } from 'components/atoms';
import { CardData } from '../../CardData';
import MageData from '../../MageData';

const Container = styled.div`
  width: 1000px;
  margin: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Desc = styled.div``;

const Body = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 20px;
`;

export default function TargetOptionModal({ isOpen, options, callback }) {
  if (options === undefined || options == null || options.length === 0) {
    return (
      <div />
    );
  }

  const desc = `Choose a target for ${options.player}'s ${options.card.name}'s effect ${options.effect.name}`;

  const width = '100%';

  const { card } = options;
  const cardData = new CardData(
    card.name,
    card.tiny_id,
    card.mp_cost,
    card.description,
    card.img,
    card.color,
  );

  return (
    <Modal
      open={isOpen}
      minimizable
    >
      <Container style={{ width }}>
        <Header>
          <Desc>{desc}</Desc>
          <Card card={cardData} />
        </Header>
        <Body>
          {(options.options).map((option) => (
            <div key={option.tiny_id}>
              <MageOption
                mageData={new MageData(option)}
                onClick={() => callback(options.card, options.effect, option)}
                id={option.tiny_id}
              />
            </div>
          ))}
        </Body>
      </Container>
    </Modal>
  );
}
