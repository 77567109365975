import ReactModal from 'react-modal';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { Button } from 'components/atoms';

import './GameOverModal.css';

const LogsModal = styled(ReactModal)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 10px;
  top: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const LogList = styled.div`
  max-height: calc(100% - 50px);  // accounting for the close button's space
  overflow-y: auto;
  padding: 10px;
  font-size: 15px;
`;

function BattleLog({ tuple }) {
  const [turn, log] = tuple;
  const logStr = `Turn ${turn}: ${log}`;
  return (
    <div>
      {logStr}
    </div>
  );
}

function getKey(index) {
  return index;
}

export default function GameOverModal({
  isOpen, gameOverEvent, requestBattleLogs, battleLogs,
}) {
  const [logsModalOpen, setLogsModalOpen] = useState(false);

  if (!gameOverEvent) {
    return (<div />);
  }

  const txt = ` ${gameOverEvent.outcome}, ${gameOverEvent.winner} wins!`;

  const handleShowLogs = () => {
    requestBattleLogs();
    setLogsModalOpen(true);
  };
  return (
    <>
      <ReactModal
        isOpen={isOpen}
        className="GameOverModal"
        overlayClassName="GameOverModal__overlay"
        appElement={document.getElementById('root')}
      >
        <div>
          {txt}
        </div>
        <div>
          <Button size="sm" linkTo="/">Play again?</Button>
          <Button size="sm" onClick={handleShowLogs}>Show Logs</Button>
        </div>
      </ReactModal>

      <LogsModal
        isOpen={logsModalOpen}
        appElement={document.getElementById('root')}
        onRequestClose={() => setLogsModalOpen(false)}
      >
        <CloseButton onClick={() => setLogsModalOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <LogList>
          {battleLogs.map((tuple, index) => (
            <div key={getKey(index)}>
              <BattleLog tuple={tuple} />
            </div>
          ))}
        </LogList>
      </LogsModal>
    </>
  );
}
