import styled from 'styled-components';

import { Mage } from 'components/organisms';
import MageData from 'MageData';
import chromoChart from '../../images/chromo-chart.webp';
import Hand from './Hand';
import './Battlefield.css';

function PlayerDetails({ player, isActive }) {
  if (!player) {
    return (
      <div />
    );
  }

  const secondClass = isActive ? 'PlayerDetails-active' : 'PlayerDetails-nonactive';

  const pname = `${player.name} `;
  const tname = `'${player.team.name}'`;

  return (
    <div className={`PlayerDetails ${secondClass}`}>
      <span>{pname}</span>
      <span>{tname}</span>
    </div>
  );
}

function ActivePlayer({ player }) {
  // player is a gameState.p1 or gameState.p2 object
  if (!player) {
    return (<div>No active player</div>);
  }

  const nonActiveMages1 = player.team.mages.filter((mageData) => mageData.tiny_id !== player.team.active_mage.tiny_id);

  return (
    <div className="Battlefield-Player1">
      <PlayerDetails player={player} isActive />
      <Mage
        // TODO: this case switch kinda sucks `team.active_mage` really should be team.activeMage
        mageData={new MageData(player.team.active_mage)}
        key={player.team.active_mage.tiny_id}
        size="md"
        primary
      />
      {nonActiveMages1.map((mageData) => (
        <Mage
          mageData={new MageData(mageData)}
          key={mageData.tiny_id}
          size="sm"
        />
      ))}
    </div>
  );
}

function NonActivePlayer({ player }) {
  if (!player) {
    return (<div>No non-active player</div>);
  }

  const nonActiveMages2 = player.team.mages.filter((mageData) => mageData.tiny_id !== player.team.active_mage.tiny_id);

  return (
    <div className="Battlefield-Player2">
      <PlayerDetails player={player} isActive={false} />
      <div className="Mage2__container">
        <Mage
          mageData={new MageData(player.team.active_mage)}
          key={player.team.active_mage.tiny_id}
          size="md"
          right
          primary
        />
        {nonActiveMages2.map((mageData) => (
          <Mage
            mageData={new MageData(mageData)}
            key={mageData.tiny_id}
            size="sm"
            right
          />
        ))}
      </div>
    </div>
  );
}

function BattlfieldPlayerContainer({ p1, p2, player }) {
  if (player === 'p1') {
    return (
      <div className="Battlefield__player-container">
        <ActivePlayer player={p1} />
        <NonActivePlayer player={p2} />
      </div>
    );
  }
  if (player === 'p2') {
    return (
      <div className="Battlefield__player-container">
        <ActivePlayer player={p2} />
        <NonActivePlayer player={p1} />
      </div>
    );
  }
  return (<div>No Active Game</div>);
}

const StyledBattlefield = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 2px;
  background-image: url(${chromoChart});
  background-repeat: no-repeat;
  background-position: center 40%;
  background-size: contain;
  background-size: 20%;
  @media screen and (max-width: 844px) {
    background-size: 80%;
    background-position: center;
  }
`;

function Battlefield({
  player, cards, p1, p2, turn, phase, playChoiceRequired, playChoiceCallback,
}) {
  const turnPhaseText = `Turn: ${turn} Phase: ${phase} Player: ${player}`;

  // TODO:
  // if player == p1/p2 then send p1/p2 to Hand
  const getPlayer = () => {
    if (player === 'p1') {
      return p1;
    }
    return p2;
  };

  return (
    <StyledBattlefield>
      <div className="Battlefield-container">
        <BattlfieldPlayerContainer p1={p1} p2={p2} player={player} />
      </div>
      <div>
        <span className="TurnPhase">{turnPhaseText}</span>
      </div>
      <Hand
        cardList={cards}
        showTray={playChoiceRequired}
        playChoiceCallback={playChoiceCallback}
        player={getPlayer()}
      />
    </StyledBattlefield>
  );
}

export default Battlefield;
