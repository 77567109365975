import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowMinimize, faWindowMaximize } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext, createGlobalStyle, keyframes } from 'styled-components';
import { useContext, useState, useEffect } from 'react';
import ReactModal from 'react-modal';

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const GlobalStyle = createGlobalStyle`
  .ReactModal__Overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex; // added this line
    align-items: center; // added this line
    justify-content: center; // added this line
    animation: ${fadeIn} 0.2s forwards;
  }
`;

export default function Modal({
  children, open, minimizable, uncenter, transparent, wide,
}) {
  const { backgroundColor } = useContext(ThemeContext);
  const [isMinimized, setIsMinimized] = useState(false);
  const toggleModalSize = () => {
    setIsMinimized(!isMinimized);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 900);
  const updateMedia = () => {
    setIsMobile(window.innerWidth <= 900);
  };
  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  let width;
  if (isMinimized) {
    width = 'auto';
  } else if (isMobile) {
    width = '100%';
  } else {
    width = wide ? '92%' : 'auto';
  }

  const customContentSyles = {
    alignItems: uncenter ? 'left' : 'center',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'auto',
    padding: '5px',
    pointerEvents: 'auto',
    textAlign: uncenter ? 'left' : 'center',
    width,
  };

  let transparentStyle;
  if (isMinimized) {
    transparentStyle = 'transparent';
  } else if (transparent) {
    transparentStyle = 'transparent';
  } else {
    transparentStyle = 'rgba(0, 0, 0, 0.2)';
  }

  const overlayStyles = {
    overlay: {
      pointerEvents: 'none',
      backgroundColor: transparentStyle,
    },
  };

  const desktopStyles = {
    content: {
      ...customContentSyles,
      backgroundColor,
      position: 'relative', // relative to the overlay container
      left: '-5px',
      top: '-5px',
      pointerEvents: 'auto',
    },
    ...overlayStyles,
  };

  const mobileStyles = {
    content: {
      ...customContentSyles,
      backgroundColor: isMinimized ? 'rgba(0,0,0,0)' : backgroundColor,
      inset: null,
      maxHeight: '100%',
      maxWidth: '100%',
      position: 'static',
      overflow: 'auto',
    },
    ...overlayStyles,
  };

  const customStyles = isMobile ? mobileStyles : desktopStyles;

  const sharedButtonStyles = {
    border: 'none',
    cursor: 'pointer',
  };

  const minimizeButtonStylesDesktop = {
    ...sharedButtonStyles,
    position: 'absolute',
    right: 2,
    top: 1,
  };

  const minimizeButtonStylesMobile = {
    ...sharedButtonStyles,
    background: backgroundColor,
    borderRadius: '10px', // Rounded corners enhance the neumorphic effect
    padding: '5px',
    position: 'fixed',
    right: '10px',
  };

  const minimizeButtonStyles = isMobile ? minimizeButtonStylesMobile : minimizeButtonStylesDesktop;

  return (
    <>
      <GlobalStyle isMinimized={isMinimized} />
      <ReactModal
        isOpen={open}
        appElement={document.getElementById('root')}
        style={customStyles}
        overlayClassName="ReactModal__Overlay"
        contentLabel="Modal"
      >
        <div style={{ margin: '5px' }}>
          {minimizable && (
          <button
            type="button"
            onClick={toggleModalSize}
            style={minimizeButtonStyles}
          >
            <FontAwesomeIcon icon={isMinimized ? faWindowMaximize : faWindowMinimize} />
          </button>
          )}
        </div>
        {!isMinimized && children}
      </ReactModal>
    </>
  );
}
