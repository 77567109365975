import { useSearchParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { Root } from 'components/templates';
import { Socket } from 'services';
import Sidebar from 'pages/sidebar/Sidebar';
import Game from 'pages/game/Game';

import './Scene.css';

function Scene() {
  useEffect(() => {
    document.title = 'Mage Game';
  }, []);

  const [socket, setSocket] = useState(null);
  const [searchParams] = useSearchParams();
  const isCPUGame = searchParams.get('cpu') === 'true';
  const teamId = searchParams.get('tid');
  const buildId = searchParams.get('bid');
  const joinKey = searchParams.get('join');
  const player = searchParams.get('p');
  const [join, setJoin] = useState(joinKey);

  const setSearchParam = (key, value) => {
    if ('URLSearchParams' in window) {
      const sp = new URLSearchParams(window.location.search);
      sp.set(key, value);
      const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}?${sp}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  };

  const messageCallback = (event) => {
    const e = JSON.parse(event.data);
    if (e.type === 'GameJoined') {
      setJoin(e.game_key);
      setSearchParam('join', e.game_key);
      setSearchParam('p', e.player);
      localStorage.setItem(`${e.game_key}.${e.player}`, e.player_key);
    }
  };

  // TODO: pretty sure this is bad practice
  if (socket === null) {
    const s = new Socket(messageCallback);
    setSocket(s);
    s.connect(join, isCPUGame, player, teamId, buildId);
  }

  // passing this here may do some weird re-renders. not sure
  return (
    <Root joinKey={isCPUGame ? null : join} teamJoin={!!teamId}>
      <div className="Scene">
        <div className="Scene-container">
          <Game socket={socket} />
          <Sidebar socket={socket} />
        </div>
      </div>
    </Root>
  );
}

export default Scene;
