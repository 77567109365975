import { Link } from 'components/atoms';
import { RootTitled } from 'components/templates';
import { NavList } from 'components/molecules';

export default function Play() {
  return (
    <RootTitled>
      <NavList>
        <Link to="/scene?cpu=true">Vs. CPU</Link>
        <Link to="/scene">Vs. Player</Link>
        <Link to="/join">Join</Link>
      </NavList>
    </RootTitled>
  );
}
