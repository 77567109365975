import { toast } from 'react-toastify';
import { routeConf } from 'Config';
import { Button } from 'components/atoms';

export default function Logout({ callback, disabled }) {
  const logout = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
    };
    (async function callLogout() {
      try {
        const resp = await fetch(routeConf.logoutPath, requestOptions);
        if (resp.status >= 400) {
          throw new Error('Bad response from server');
        }
        localStorage.removeItem('user');
        callback();
      } catch (err) {
        toast.error(err);
      }
    }());
  };

  return (
    <Button square disabled={disabled} onClick={logout} size="xs">Logout</Button>
  );
}
