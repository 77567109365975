import { MageAvatar } from 'components/atoms';
import MageData from 'MageData';
import Section from './Section';

export default function AvatarSection() {
  const rawData = {
    name: 'Mage',
    hp: 20,
    max_hp: 20,
    regen_hp: 0,
    mp: 10,
    max_mp: 10,
    deck: {
      deck: [],
      hand: [],
      discard_pile: [],
    },
    conditions: [{ tiny_id: '0', name: 'Thorns' }],
    owner: 'owner',
    color: 'RED',
    front_img: 'red1front',
    back_img: 'red1back',
  };
  const mageData = new MageData({ ...rawData });

  return (
    <Section title="MageAvatar">
      <MageAvatar img={mageData.frontImage} />
      <MageAvatar img={mageData.frontImage} size="sm" />
      <MageAvatar img={mageData.backImage} />
      <MageAvatar img={mageData.backImage} size="sm" />
    </Section>
  );
}
