import styled from 'styled-components';
import { Root } from 'components/templates';

import { AnimationProvider } from 'contexts/AnimationContext';
import CardSection from './CardSection';
import TableSection from './TableSection';
import ModalSection from './ModalSection';
import MageSection from './MageSection';
import TargetOptionModalSection from './TargetOptionModalSection';
import ChooseMageModalSection from './ChooseMageModalSection';
import DescriptorSection from './DescriptorSection';
import EffectIconSection from './EffectIconSection';
import ConditionsSection from './ConditionsSection';
import ButtonSection from './ButtonSection';
import FontSection from './FontSection';
import ColorSection from './ColorSection';
import LinkSection from './LinkSection';
import TextFieldSection from './TextFieldSection';
import BarSection from './BarSection';
import AvatarSection from './AvatarSection';
import MageAnimationSection from './MageAnimationSection';
import MageHeaderSection from './MageHeaderSection';

const StyledDiv = styled.div`
  margin: 10px;
`;

export default function Components() {
  return (
    <Root>
      <AnimationProvider>
        <StyledDiv>
          <h1>Components</h1>
          <BarSection />
          <MageHeaderSection />
          <MageAnimationSection />
          <MageSection />
          <ConditionsSection />
          <EffectIconSection />
          <DescriptorSection />
          <ChooseMageModalSection />
          <TargetOptionModalSection />
          <ButtonSection />
          <ModalSection />
          <FontSection />
          <CardSection />
          <TableSection />
          <ColorSection />
          <LinkSection />
          <ModalSection />
          <TextFieldSection />
          <AvatarSection />
        </StyledDiv>
      </AnimationProvider>
    </Root>
  );
}
