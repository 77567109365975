import { useState, useEffect } from 'react';
import { Card } from 'components/molecules';
import { Button, Modal } from 'components/atoms';

import './Hand.css';

function CardContainer({ className, children }) {
  return (
    <div className={className}>
      {children}
    </div>
  );
}

function getTotalMPCost(cards) {
  return cards.reduce((acc, card) => card.mpCost + acc, 0);
}

function Tray({
  showTray, cards, clickCommitHandler, trayClickHandler, player,
}) {
  if (!player) {
    return (<div />);
  }
  const totalMPCost = getTotalMPCost(cards);
  const displayMPCost = player.team.active_mage.mp - totalMPCost;

  let mpColor = 'black';
  if (displayMPCost < 0) {
    mpColor = 'red';
  }

  const displayMPCostText = `${displayMPCost}/${player.team.active_mage.mp}`;

  const mpRemaining = 'MP Remaining: ';

  return (
    <Modal
      open={showTray}
      minimizable
      uncenter
      transparent
      wide
    >
      <div style={{ paddingLeft: '10px' }}>
        {mpRemaining}
        <span style={{ color: mpColor }}>{displayMPCostText}</span>
      </div>
      <CardContainer className="Tray">
        <div className="Tray__cards">
          {cards.map((card) => (
            <div className="Tray__slot" key={`tray-${card.id}`}>
              <Card card={card} onClick={trayClickHandler} />
            </div>
          ))}
        </div>
      </CardContainer>
      <div>
        <Button onClick={clickCommitHandler} size="sm">Commit Play</Button>
      </div>
    </Modal>
  );
}

// TODO: this got super bugged out after getting the cards from the game state
// no longer does in hand swapping work. Though it did work when I passed cards
// as just a const defined in the parent component
function Hand({
  cardList, showTray, playChoiceCallback, player,
}) {
  const [cards, setCards] = useState([]);
  const [trayCards, setTrayCards] = useState([]);

  useEffect(() => {
    setCards(cardList);
  }, [cardList]);

  const addCardToList = (card, list, setList) => {
    const newCards = [];
    list.map((c) => {
      newCards.push(c);
      return c;
    });
    list.push(card);
    setList(list);
  };

  const removeCardFromList = (card, list, setList) => {
    const newCards = [];
    list.map((c) => {
      if (c.id !== card.id) {
        newCards.push(c);
      }
      return c;
    });
    setList(newCards);
  };

  const clickCommitHandler = () => {
    setTrayCards([]);
    setCards([]);
    playChoiceCallback(trayCards);
  };

  const getCardById = (id, cards2) => {
    // returns the card and index from the list if the id is the given id
    let card = null;
    cards2.map((c) => {
      if (c.id === id) {
        card = c;
      }
      return c;
    });
    return card;
  };

  const getIdFromEvent = (event) => {
    let id = event.currentTarget.getElementsByClassName('Card2__hidden-id')[0].textContent;
    // removes all whitespace from id
    id = id.replace(/\s/g, '');
    return id;
  };

  const handClickHandler = (
    event,
    showTray2,
    trayCards2,
    setTrayCards2,
    cards2,
    setCards2,
  ) => {
    switch (event.detail) {
      case 1: {
        if (showTray2) {
          const cardId = getIdFromEvent(event);
          const card = getCardById(cardId, cards2);
          addCardToList(card, trayCards2, setTrayCards2);
          removeCardFromList(card, cards2, setCards2);
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  const onClickHandClosure = (event) => {
    handClickHandler(
      event,
      showTray,
      trayCards,
      setTrayCards,
      cards,
      setCards,
    );
  };

  const onClickTrayClosure = (event) => {
    handClickHandler(
      event,
      showTray,
      cards,
      setCards,
      trayCards,
      setTrayCards,
    );
  };

  return (
    <div className="HandContainer">
      <Tray
        showTray={showTray}
        cards={trayCards}
        clickCommitHandler={clickCommitHandler}
        trayClickHandler={onClickTrayClosure}
        player={player}
      />
      <CardContainer className="Hand">
        {cards.map((card) => (
          <div className="Hand__slot" key={`hand-${card.id}`}>
            <Card
              card={card}
              onClick={onClickHandClosure}
            />
          </div>
        ))}
      </CardContainer>
    </div>
  );
}

export default Hand;
