import { Card } from 'components/molecules';
import './CardIndex.css';

export default function CardIndex({ cards, cardClickCallback }) {
  if (!cards || cards.length === 0) {
    return (
      <div />
    );
  }

  return (
    <div className="CardIndex">
      <div className="CardIndexContainer">
        {cards.map((card) => (
          <span key={card.id} className="CardIndexCard">
            <Card card={card} onClick={cardClickCallback} />
          </span>
        ))}
      </div>
    </div>
  );
}
