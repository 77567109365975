import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Root } from 'components/templates';
import useApi from 'api/useApi';
import { createTeam } from 'api/team';

import TeamEditor from './TeamEditor';

export default function TeamNew() {
  const team = { name: '', description: '', cards: [] };
  const navigate = useNavigate();
  const {
    loading, error, data, request,
  } = useApi(createTeam);

  useEffect(() => {
    if (data) {
      toast.success('Team created');
      navigate(`/teams/${data.team_id}`);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      toast.error(error.message);
    }
  }, [error]);

  const onSave = (updatedTeam) => {
    request(updatedTeam);
  };

  return (
    <Root>
      <div className="TeamNew">
        <TeamEditor team={team} onSave={onSave} loading={loading} />
      </div>
    </Root>
  );
}
