import { Title } from 'components/molecules';
import styled from 'styled-components';

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center
`;

export default function Titled({ children }) {
  return (
    <StyledDiv>
      <Title />
      {children}
    </StyledDiv>
  );
}
