import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

const SectionDiv = styled.div`
  margin: 15px;
  margin-top: 40px;
`;

const Title = styled.h2`
  cursor: pointer;
  display: flex;
  align-items: center;

  &:after {
    content: '>';
    margin-left: 5px;
    transition: transform 0.3s ease;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(90deg)' : 'rotate(0)')};
  }
`;

const Content = styled.div`
  transition: max-height 0.3s ease, opacity 0.3s ease;
  max-height: ${({ isOpen }) => (isOpen ? '10000px' : '0')}; // Adjust max height accordingly
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
`;

export default function Section({ children, title }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialOpenState = searchParams.get(title) === '1';
  const [isOpen, setIsOpen] = useState(initialOpenState);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isOpen) {
      searchParams.delete(title);
    } else {
      searchParams.set(title, '1');
    }
    setSearchParams(searchParams, { replace: true });
  }, [isOpen, title, searchParams, setSearchParams]);

  return (
    <SectionDiv>
      <Title isOpen={isOpen} onClick={toggleOpen}>{title}</Title>
      <Content isOpen={isOpen}>
        {children}
      </Content>
    </SectionDiv>
  );
}
