import styled, { css, ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { Button as NeuButton } from 'ui-neumorphism';
import { useContext } from 'react';

const StyledButton = styled(NeuButton)`
  ${(props) => props.block && css`
    display: block;
    width: 100%;
  `}
  margin: 10px;
`;

export default function Button({
  children, disabled, onClick, variant, linkTo, block, square, size = 'md',
}) {
  const {
    primaryColor, secondaryColor, dangerColor, blueText, buttonText,
  } = useContext(ThemeContext);

  let bgColor = primaryColor;
  let color = buttonText;
  if (variant === 'primary') {
    color = blueText;
  } else if (variant === 'secondary') {
    bgColor = secondaryColor;
  } else if (variant === 'dangerous') {
    color = dangerColor;
  }

  let buttonStyle = {};
  let nSize = 'medium';

  if (size === 'sm') {
    nSize = 'small';
    buttonStyle = {
      margin: '5px',
      padding: '5px 10px',
      fontSize: '10px',
    };
  } else if (size === 'lg') {
    nSize = 'large';
  } else if (size === 'xs') {
    nSize = 'small';
    buttonStyle = {
      margin: '5px',
      padding: '0px 0px',
      fontSize: '10px',
      height: '20px',
      width: '20px',
    };
  }

  if (linkTo) {
    return (
      <Link to={linkTo}>
        <StyledButton
          bgColor={bgColor}
          onClick={onClick}
          disabled={disabled}
          color={color}
          size={nSize}
          block={block}
          style={buttonStyle}
          rounded={!square}
        >
          {children}
        </StyledButton>
      </Link>
    );
  }

  return (
    <StyledButton
      bgColor={bgColor}
      onClick={onClick}
      disabled={disabled}
      color={color}
      size={nSize}
      block={block}
      style={buttonStyle}
      rounded={!square}
    >
      {children}
    </StyledButton>
  );
}
