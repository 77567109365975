import backBeachSkeleton from './wizards/back-beach-skeleton.png';
import backBlackShaman from './wizards/back-black-shaman.png';
import backBlackWizard from './wizards/back-black-wizard.png';
import backBlueWizard from './wizards/back-blue-wizard.png';
import backGreenWizard from './wizards/back-green-wizard.png';
import backMetalWizard from './wizards/back-metal-wizard.png';
import backPingWizard from './wizards/back-ping-wizard.png';
import backPurpleWizard from './wizards/back-purple-wizard.png';
import backScenicShaman from './wizards/back-scenic-shaman.png';
import backSketchWarlock from './wizards/back-sketch-warlock.png';
import frontAngryWizard from './wizards/front-angry-wizard.png';
import frontAnimeWizard from './wizards/front-anime-wizard.png';
import frontBlueWizard from './wizards/front-blue-wizard.png';
import frontDudeWizard from './wizards/front-dude-wizard.png';
import frontOrbWizard from './wizards/front-orb-wizard.png';
import frontPensiveWizard from './wizards/front-pensive-wizard.png';
import frontPurpleWizard from './wizards/front-purple-wizard.png';
import frontRockGolfer from './wizards/front-rock-golfer.png';
import frontRockThrower from './wizards/front-rock-thrower.png';
import frontSadWizard from './wizards/front-sad-wizard.png';
import frontShaman from './wizards/front-shaman.png';
import frontShamette from './wizards/front-shamette.png';
import frontSkeletongShaman from './wizards/front-skeletong-shaman.png';

// xxx
import blue1back from './wizards/v1/blue-1-back.jpeg';
import blue1front from './wizards/v1/blue-1-front.jpeg';
import blue2back from './wizards/v1/blue-2-back.jpeg';
import blue2front from './wizards/v1/blue-2-front.jpeg';
import green1back from './wizards/v1/green-1-back.jpeg';
import green1front from './wizards/v1/green-1-front.jpeg';
import green2back from './wizards/v1/green-2-back.jpeg';
import green2front from './wizards/v1/green-2-front.jpeg';
import green3back from './wizards/v1/green-3-back.jpeg';
import green3front from './wizards/v1/green-3-front.jpeg';
import green4back from './wizards/v1/green-4-back.jpeg';
import green4front from './wizards/v1/green-4-front.jpeg';
import orange1back from './wizards/v1/orange-1-back.jpeg';
import orange1front from './wizards/v1/orange-1-front.jpeg';
import orange2back from './wizards/v1/orange-2-back.jpeg';
import orange2front from './wizards/v1/orange-2-front.jpeg';
import orange3back from './wizards/v1/orange-3-back.jpeg';
import orange3front from './wizards/v1/orange-3-front.jpeg';
import red1back from './wizards/v1/red-1-back.jpeg';
import red1front from './wizards/v1/red-1-front.jpeg';
import red2back from './wizards/v1/red-2-back.jpeg';
import red2front from './wizards/v1/red-2-front.jpeg';
import red3back from './wizards/v1/red-3-back.jpeg';
import red3front from './wizards/v1/red-3-front.jpeg';
import red4back from './wizards/v1/red-4-back.jpeg';
import red4front from './wizards/v1/red-4-front.jpeg';
import red5back from './wizards/v1/red-5-back.jpeg';
import red5front from './wizards/v1/red-5-front.jpeg';
import red6back from './wizards/v1/red-6-back.jpeg';
import red6front from './wizards/v1/red-6-front.jpeg';
import red7back from './wizards/v1/red-7-back.jpeg';
import red7front from './wizards/v1/red-7-front.jpeg';
import violet1back from './wizards/v1/violet-1-back.jpeg';
import violet1front from './wizards/v1/violet-1-front.jpeg';
import violet2back from './wizards/v1/violet-2-back.jpeg';
import violet2front from './wizards/v1/violet-2-front.jpeg';
import yellow1back from './wizards/v1/yellow-1-back.jpeg';
import yellow1front from './wizards/v1/yellow-1-front.jpeg';
import yellow2back from './wizards/v1/yellow-2-back.jpeg';
import yellow2front from './wizards/v1/yellow-2-front.jpeg';
import yellow3back from './wizards/v1/yellow-3-back.jpeg';
import yellow3front from './wizards/v1/yellow-3-front.jpeg';
import yellow4back from './wizards/v1/yellow-4-back.jpeg';
import yellow4front from './wizards/v1/yellow-4-front.jpeg';

const avatarBackImages = {
  blue1back,
  blue2back,
  green1back,
  green2back,
  green3back,
  green4back,
  orange1back,
  orange2back,
  orange3back,
  red1back,
  red2back,
  red3back,
  red4back,
  red5back,
  red6back,
  red7back,
  violet1back,
  violet2back,
  yellow1back,
  yellow2back,
  yellow3back,
  yellow4back,

};

const avatarFrontImages = {
  blue1front,
  blue2front,
  green1front,
  green2front,
  green3front,
  green4front,
  orange1front,
  orange2front,
  orange3front,
  red1front,
  red2front,
  red3front,
  red4front,
  red5front,
  red6front,
  red7front,
  violet1front,
  violet2front,
  yellow1front,
  yellow2front,
  yellow3front,
  yellow4front,
};

// xxx

// These images are also defined server side
const backImages = {
  backBeachSkeleton,
  backBlackShaman,
  backBlackWizard,
  backBlueWizard,
  backGreenWizard,
  backPingWizard,
  backPurpleWizard,
  backScenicShaman,
  backMetalWizard,
  backSketchWarlock,
};

const frontImages = {
  frontAnimeWizard,
  frontBlueWizard,
  frontDudeWizard,
  frontOrbWizard,
  frontPurpleWizard,
  frontRockGolfer,
  frontRockThrower,
  frontSadWizard,
  frontShaman,
  frontShamette,
  frontSkeletongShaman,
  frontAngryWizard,
  frontPensiveWizard,
};

class MageData {
  constructor(data) {
    this.data = data;
    this.name = data.name;
    this.tinyID = data.tiny_id;

    this.conditions = data.conditions;

    this.owner = data.owner;
    this.playerConstant = data.owner_constant;

    this.hp = data.hp;
    this.mp = data.mp;

    this.maxHP = data.max_hp;
    this.regenHP = data.regen_hp;

    this.maxMP = data.max_mp;

    this.color = data.color;

    // TODO: perhaps parse?
    this.deck = data.deck;

    if (data.front_img in avatarFrontImages) {
      this.frontImage = avatarFrontImages[data.front_img];
    } else {
      this.frontImage = frontImages[data.front_img];
    }

    if (data.back_img in avatarBackImages) {
      this.backImage = avatarBackImages[data.back_img];
    } else {
      this.backImage = backImages[data.back_img];
    }
  }
}

export default MageData;
