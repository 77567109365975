import { useState, useCallback } from 'react';

export default function useApi(apiFunc, transformer) {
  const [data, setData] = useState(null);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const request = async (...args) => {
    setLoading(true);
    setError('');
    try {
      const result = await apiFunc(...args);
      if (transformer) {
        setData(transformer(result));
      } else {
        setData(result);
      }
    } catch (err) {
      setError(err);
      setLoading(false);
      if (err.status >= 500) {
        throw err;
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    data,
    request: useCallback(request, []),
  };
}
