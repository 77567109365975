import { useEffect } from 'react';

import { Login } from 'components/organisms';
import useApi from 'api/useApi';
import { getCurrentUser } from 'api/user';
import Titled from './Titled';
import Root from './Root';

export default function HomeOrLogin({ children }) {
  const {
    loading, error, data, request,
  } = useApi(getCurrentUser);

  useEffect(() => {
    request();
  }, [request]);

  useEffect(() => {
    if (data) {
      const dataStr = JSON.stringify(data);
      localStorage.setItem('user', dataStr);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      localStorage.removeItem('user');
    }
  }, [error]);

  if (loading) {
    return <div />;
  }

  if (!data) {
    return (
      <Root>
        <Titled>
          <Login />
        </Titled>
      </Root>
    );
  }

  return (
    <Root>
      <Titled>
        {children}
      </Titled>
    </Root>
  );
}
