import { TextField as NeuTextField } from 'ui-neumorphism';
import { ThemeContext } from 'styled-components';
import { useContext, useRef } from 'react';

export default function TextField({
  children, type, name, label, onChange, rules, id, value, width, placeholder,
}) {
  const { backgroundColor, fontFamily } = useContext(ThemeContext);
  const ref = useRef(null);

  const inputStyles = { backgroundColor, fontFamily };
  const style = { ...inputStyles };

  if (!width) {
    inputStyles.width = '100%';
    inputStyles.display = 'block';
    style.width = '97%';
    style.display = 'block';
  }

  return (
    <NeuTextField
      type={type}
      name={name}
      id={id}
      value={value}
      inputStyles={inputStyles}
      style={style}
      label={label}
      onChange={onChange}
      rules={rules}
      width={width}
      ref={ref}
      placeholder={placeholder}
    >
      {children}
    </NeuTextField>
  );
}
