import styled from 'styled-components';

const ContentDiv = styled.div`
  margin: 20px;
`;

export default function Content({ children }) {
  return (
    <ContentDiv>
      {children}
    </ContentDiv>
  );
}
